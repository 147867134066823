import { Box } from "@mui/system"
import { memo, useContext, useState } from "react"
import { Context } from "./Context"
import { GlobalContext } from "../../../GlobalContext"
import { Chip, CircularProgress, IconButton, Tooltip } from "@mui/material"
import { deletingDayPaycode, deletingHourPaycode, delettingShift, gettingShift } from "../../../utils/Schedule"
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import WalletIcon from '@mui/icons-material/Wallet'
import DeleteIcon from '@mui/icons-material/Delete'
import moment from "moment"

const ShiftCell = memo(({shift, id, field}) => {
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    
    const { 
        periods,
        reloading,
        setDataDelete,
        setDeleteOpen,
        setType,
    } = useContext(Context)
    const { handlerAlert } = useContext(GlobalContext)

    return (
        <center>
            { JSON.parse(sessionStorage.getItem('privileges')).schedule.delete_shift ?
                <Chip
                    icon={<WorkHistoryIcon sx={{p: 0.25}}/>} 
                    label={gettingShift(shift)}
                    onDelete={() => { 
                        setDisabled(true)

                        if(JSON.parse(sessionStorage.getItem('privileges'))?.settings.deletion_confirmation){
                            setType('shift')

                            setDataDelete({
                                shift: shift,
                                person_number: id, 
                                date: field, 
                                setDisabled
                            })

                            setDeleteOpen(true)
                        }else{
                            delettingShift(
                                setLoading, 
                                id, 
                                field, 
                                shift, 
                                () => {},
                                reloading,
                                handlerAlert
                            )
                        }
                    }}
                    deleteIcon={loading ? 
                        <IconButton disabled sx={{p: 0.5}}>
                            <CircularProgress size={13.5} color='inherit'/>
                        </IconButton>
                        : 
                        <DeleteIcon />
                    }
                    disabled={moment(periods.find(period => period.person_number === id)?.end_date, 'YYYY-MM-DD').isSameOrAfter(moment(field, 'YYYY-MM-DD')) || disabled}
                />
                :
                <Chip
                    icon={<WorkHistoryIcon sx={{p: 0.25}}/>} 
                    label={gettingShift(shift)}
                    disabled={moment(periods.find(period => period.person_number === id)?.end_date, 'YYYY-MM-DD').isAfter(moment(field, 'YYYY-MM-DD'))}
                />
            }
        </center>
    )
})

const PDCell = memo(({day, field}) => {
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const { 
        periods,
        reloading, 
        setType,
        setDataPaycodeDay, 
        setDeleteOpen,
    } = useContext(Context)
    const { handlerAlert } = useContext(GlobalContext)

    return (
         <center>
            <Tooltip title={`${day?.paycode}`}>
                { JSON.parse(sessionStorage.getItem('privileges')).schedule.delete_paycode_days ? 
                    <Chip
                        color='primary'
                        sx={{width: '144.6px'}}
                        icon={<WalletIcon sx={{p: 0.25}}/>} 
                        label={<Box sx={{width: '80px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{day?.paycode}</Box>}
                        onDelete={() => {
                            setDisabled(true)

                            if(JSON.parse(sessionStorage.getItem('privileges'))?.settings.deletion_confirmation){
                                setType('PCD')

                                setDataPaycodeDay({
                                    day: day,
                                    setDisabled
                                })

                                setDeleteOpen(true)
                            }else{
                                deletingDayPaycode(
                                    setLoading, 
                                    day, 
                                    () => {}, 
                                    reloading, 
                                    handlerAlert
                                )
                            }
                        }}
                        deleteIcon={
                            loading ? 
                                <IconButton disabled sx={{p: 0.5}}>
                                    <CircularProgress size={13.5} color='inherit'/>
                                </IconButton>
                                : 
                                <DeleteIcon />
                        }
                        disabled={moment(periods.find(period => period.person_number === day.person_number)?.end_date, 'YYYY-MM-DD').isSameOrAfter(moment(field, 'YYYY-MM-DD') || disabled)}
                    />
                    :
                    <Chip
                        color='primary'
                        sx={{width: '144.6px'}}
                        icon={<WalletIcon sx={{p: 0.25}}/>} 
                        label={<Box sx={{width: '80px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{day?.paycode}</Box>}
                        disabled={moment(periods.find(period => period.person_number === day.person_number)?.end_date, 'YYYY-MM-DD').isSameOrAfter(moment(field, 'YYYY-MM-DD'))}
                    />
                }
                
            </Tooltip>
        </center>
    )
})

const PHCell = memo(({hour, field}) => {
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const { 
        periods,
        reloading,
        setType,
        setDeleteOpen,
        setDataPaycodeHour,
    } = useContext(Context)
    const { handlerAlert } = useContext(GlobalContext)
    
    return (
        <Tooltip title={`${hour?.paycode} - (${hour?.amount})`}>
            { JSON.parse(sessionStorage.getItem('privileges')).schedule.delete_paycode_hours ? 
                <Chip
                    color='secondary'
                    sx={{width: '144.6px'}}
                    icon={<WalletIcon sx={{p: 0.25}}/>} 
                    label={<Box sx={{width: '80px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{hour?.paycode}</Box>}
                    onDelete={() => { 
                        setDisabled(true)

                        if(JSON.parse(sessionStorage.getItem('privileges'))?.settings.deletion_confirmation){
                            setType('PCH')

                            setDataPaycodeHour({
                                hour: hour,
                                setDisabled
                            })

                            setDeleteOpen(true)
                        }else{
                            deletingHourPaycode(
                                setLoading, 
                                hour, 
                                () => {}, 
                                reloading, 
                                handlerAlert
                            ) 
                        }
                    }}
                    deleteIcon={loading ? 
                        <IconButton disabled sx={{p: 0.5}}>
                            <CircularProgress size={13.5} color='inherit'/>
                        </IconButton>
                        : 
                        <DeleteIcon />
                    }
                    disabled={moment(periods.find(period => period.person_number === hour.person_number)?.end_date, 'YYYY-MM-DD').isSameOrAfter(moment(field, 'YYYY-MM-DD')) || disabled}
                />
                :
                <Chip
                    color='secondary'
                    sx={{width: '144.6px'}}
                    icon={<WalletIcon sx={{p: 0.25}}/>} 
                    label={<Box sx={{width: '80px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{hour?.paycode}</Box>}
                    disabled={moment(periods.find(period => period.person_number === hour.person_number)?.end_date, 'YYYY-MM-DD').isSameOrAfter(moment(field, 'YYYY-MM-DD'))}
                />
            }
        </Tooltip>
    )
})


const renderCell = (params) => {
    return (
        <>
            <Box 
                id={`${params?.field}-cell-${params?.row?.id}`}
                sx={{
                    display: 'grid', 
                    placeItems: 'center',
                    width: '100%',
                    height: '100%',
                    gap: '10px',
                    p: 1.5
                }}
            >
                { params.value?.shifts?.map((shift, index) => 
                    <ShiftCell 
                        key={index} 
                        id={params.id} 
                        field={params.field} 
                        shift={shift}
                    />
                )}

                { params.value?.days?.map((day, index) => 
                    <PDCell 
                        key={index} 
                        field={params.field}
                        day={day}
                    />
                )}

                { params.value?.hours?.map((hour, index) => 
                    <PHCell 
                        key={index}
                        field={params.field}
                        hour={hour}
                    />
                )}
            </Box>
        </>
    )
}

export { renderCell }