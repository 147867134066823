import { createContext, useState } from "react";

const Context = createContext({});

const ContextProvider = ({ children, value }) => {
    const [selectedReports, setSelectedReports] = useState([])
    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [selectedDates, setSelectedDates] = useState([null, null])

    const [loadingExecute, setLoadingExecute] = useState(false)

    return(
        <Context.Provider value={{
            ...value,
            selectedReports,
            setSelectedReports,
            selectedEmployees,
            setSelectedEmployees,
            selectedDates,
            setSelectedDates,
            loadingExecute,
            setLoadingExecute,
        }}>
            {children}
        </Context.Provider>
    )
}

export {
    Context,
    ContextProvider,
}
