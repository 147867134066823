import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const InfoRequest = ({data, open, setOpen}) => {
    const { t } = useTranslation()

    const closing = () => {
        setOpen(false)
    }

    const columns = useMemo(() => [
        { field: 'date', headerName: t('request.table.modals.kronos_status.table.columns.date'), flex: 1 },
        { field: 'status', headerName: t('request.table.modals.kronos_status.table.columns.status'), flex: 1 },
        { field: 'paycode', headerName: t('request.table.modals.kronos_status.table.columns.paycode'), flex: 1 },
        { field: 'message', headerName: t('request.table.modals.kronos_status.table.columns.message'), flex: 2 },
    ], [t])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => closing()}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant='body3'>{ t('request.table.modals.kronos_status.title') }</Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <DataGridPro 
                        showCellVerticalBorder
                        showColumnVerticalBorder
                        //unstable_headerFilters
                        loading={false}
                        columns={columns}
                        rows={ data?.kronos_response ?
                            JSON.parse(data?.kronos_response).map(response  => ({
                                id: data.id,
                                date: `${response.schedule?.start_date} ${response.schedule?.end_date}`,
                                status: response.status,
                                paycode: response.schedule?.paycode,
                                message: response.message
                            }))
                            : 
                            []
                        }
                        sx={{minHeight: 100}}
                        hideFooter
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                        variant='contained'
                        disableElevation
                        onClick={() => closing()}
                        color='error'
                        sx={{mr: 2}}
                    >
                        { t('request.table.modals.kronos_status.buttons.close') }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export {
    InfoRequest
}