import { Alert, Avatar, Box, Collapse, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import Files from 'react-files'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { getBorder, getBorderWithLine } from "../../utils/Styles";
import DeleteIcon from '@mui/icons-material/Delete';
import AttachmentIcon from '@mui/icons-material/Attachment';

const FileField = ({files, setFiles}) => {
    const { t } = useTranslation()

    const [open, setOpen] = useState(false)
    const [text, setText] = useState({text: '', params: undefined})

    const maxFiles = 1
    //const [files, setFiles] = useState([])

    const handleChange = (newFiles) => {
        if(maxFiles === 1){
            console.log(newFiles)

            if(newFiles.length === 1){
                setFiles(newFiles)
            }else{
                setOpen(true)

                setText({
                    text: 'utils.file.alerts.maximum_number_of_files',
                    params: { amount: maxFiles }
                })

                setFiles([])
            }           
        }else{
            setFiles(prevFiles => [...prevFiles, ...newFiles])
        }
    }

    const handleFileRemove = (fileId) => {
        setFiles(prevFiles => prevFiles.filter(prevFile => prevFile.id !== fileId))
    }

    return (
        <>
            <Collapse 
                sx={{p: 0, mb: 3}}
                in={open}
            >
                <Alert
                    severity="error"
                    onClose={() => {
                        setOpen(false)
                    }}
                >
                    {t(text.text, text.params)}
                </Alert>
            </Collapse>
            
            <Box
                sx={{
                    mt: -2,
                    width: '100%',
                    borderRadius: '5px',
                    background: theme => theme.palette.background.paper,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                { files.length > 0 &&
                    <Box
                        sx={{
                            p: 2,
                            width: '100%',
                            borderLeft: theme => getBorderWithLine(theme.palette.mode, 'solid'),
                            borderRight: theme => getBorderWithLine(theme.palette.mode, 'solid'),
                            borderTop: theme => getBorderWithLine(theme.palette.mode, 'solid'),
                            borderTopLeftRadius: '5px',
                            borderTopRightRadius: '5px',
                        }}
                    >
                        
                        <List
                            sx={{
                                width: '100%',
                                border: theme => getBorder(theme.palette.mode),
                                borderRadius: '10px',
                            }}
                        >
                            { files.map((file, index) => 
                                <>
                                    <ListItem 
                                        key={`${index}-${file.name}`}
                                        secondaryAction={
                                            <IconButton
                                                color="error" 
                                                edge="end" 
                                                aria-label="delete"
                                                onClick={() => { 
                                                    handleFileRemove(file.id)
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemAvatar>
                                            { file.preview.type === 'image' &&
                                                <Avatar variant="rounded" src={file.preview.url}></Avatar>
                                                
                                            }
                                            
                                            { file.preview.type === 'file' &&
                                                <Avatar variant="rounded">
                                                    <AttachmentIcon />
                                                </Avatar>
                                            }
                                        </ListItemAvatar>

                                        <ListItemText 
                                            primary={
                                                <Box 
                                                    sx={{
                                                        overflowX: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {file.name}
                                                </Box>
                                            } 
                                            secondary={`${file.size / 1000} MB`}
                                        />
                                    </ListItem>
                                
                                    { index < files.length - 1 && <Divider /> }
                                </>
                            )}
                        </List> 
                    </Box>
                }   
                
                <Box
                    sx={{
                        p: 2,
                        width: '100%',
                        border: theme => getBorderWithLine(theme.palette.mode, 'dashed'),
                        borderTopLeftRadius: files.length > 0 ? '0px' : '5px',
                        borderTopRightRadius: files.length > 0 ? '0px' : '5px',
                        borderBottomLeftRadius: '5px',
                        borderBottomRightRadius: '5px',
                        '&:hover':{
                            backgroundColor: theme => theme.palette.action.hover,
                        }
                    }}
                >
                    <Files
                        onChange={handleChange}
                        onError={(error) => { 
                            if(error.code === 1){
                                setOpen(true)
                                setText({
                                    text: 'utils.file.alerts.unsupported_format',
                                    params: undefined
                                })
                            }else if(error.code === 2){
                                setOpen(true)
                                setText({
                                    text: 'utils.file.alerts.file_exceeds_limit',
                                    params: { amount: process.env.REACT_APP_MAX_FILE_SIZE / 1000 }
                                })
                            }else if(error.code === 4){
                                setOpen(true)
                                setText({
                                    text: 'utils.file.alerts.maximum_number_of_files',
                                    params: { amount: maxFiles }
                                })
                            }else{
                                console.log(error)
                            }
                        }}
                        accepts={[
                            'image/*', 
                            '.pdf', 
                            '.xls',
                        ]}
                        multiple={true}
                        maxFileSize={process.env.REACT_APP_MAX_FILE_SIZE}
                        minFileSize={0}
                        clickable
                        style={{
                            padding: 0,
                            minHeight: '100px',
                            height: 'auto',
                            borderRadius: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            hover: {
                                backgroundColor: '#f9f9f9'
                            }
                        }}
                    >
                        <FileUploadIcon sx={{fontSize: '2rem', mb: 2}}/>

                        <Typography variant='body3'>
                            { files.length === 0 
                                ? t('utils.file.dropdown')
                                : t('utils.file.uploaded_files', { amount: files.length})
                            }
                        </Typography> 
                    </Files>
                </Box>

                
            </Box>          
        </>
    )
}

export {
    FileField
}
