import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const NotFound = () => {
	const { t } = useTranslation()

	return (
		<>
			<Box 
				sx={{
					width: '100vw',
					height: '100vh',
					overflow: 'hidden',
					display: 'grid',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Typography variant='h3'>
					<center>{t('not_found.title')}</center>

					<Typography variant='h6' sx={{mt: 1}}>
						<center>
							{t('not_found.message')} <Link underline="none" to={'/'}>{t('not_found.here')}</Link>.
						</center>
					</Typography>
				</Typography>
			</Box>
		</>
	)
}

export { NotFound }