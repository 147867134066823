import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { getBorder } from "../../utils/Styles"
import { DateRangePicker, SingleInputDateRangeField } from "@mui/x-date-pickers-pro"
import { shortcuts } from "../../utils/Dates"
import { useTranslation } from "react-i18next"
import { useContext, useState } from "react"
import { LoadingButton } from "@mui/lab"
import { Context as TimeContext } from '../table/timecard/Context'
import { Context as EmplContext } from '../table/employees/Context'
import { Context as ScheContext } from '../table/schedule/Context'
import { Context as OverContext } from '../table/overtime/Context'
import { Context as RequContext } from '../table/requests/Context'
import { gettingAudit } from "../../utils/Audits"

const Context = {
    employees: EmplContext,
    schedule: ScheContext,
    timecard: TimeContext,
    overtime: OverContext,
    request: RequContext,
}

// Audit
const FilterA = ({view}) => {
    const { t } = useTranslation()

    const [type, setType] = useState(view)

    const {
        range2,
        setRange2,
        search,
        setSearch,
        setAudit,
        loadingGettingAudit,
        setLoadingGettingAudit,
    } = useContext(Context[view])

    return (
        <>
            <Box
                sx={{
                    display: 'flex', 
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 2,
                    p: 1.5,
                    borderBottom: theme => getBorder(theme.palette.mode)
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <DateRangePicker
                            label={t('employees.employee_table.filter.range_of_days')}
                            slots={{ field: SingleInputDateRangeField }}
                            slotProps={{
                                shortcuts: {
                                    items: shortcuts(t),
                                },
                            }}
                            format={JSON.parse(sessionStorage.getItem('config')).date_format}
                            calendars={1}
                            fullwith
                            onChange={(value) => { setRange2(value) }}
                            value={range2}
                            sx={{
                                width: '100%'
                            }}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="lsb">{t('audits.table.filter.search_by')}</InputLabel>
                            <Select
                                labelId="lsb"
                                id="sb"
                                value={search}
                                label={t('audits.table.filter.search_by')}
                                onChange={(event) => { setSearch(event.target.value) }}
                            >
                                <MenuItem value={'audit_date'}>{t('audits.table.filter.audit_date')}</MenuItem>
                                <MenuItem value={'event_date'}>{t('audits.table.filter.event_date')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="lv">{t('audits.table.filter.view')}</InputLabel>
                            <Select
                                labelId="lv"
                                id="v"
                                value={type}
                                label={t('audits.table.filter.view')}
                                onChange={(event) => { setType(event.target.value) }}
                            >
                                <MenuItem value={'employees'}>{t('employees.title')}</MenuItem>
                                <MenuItem value={'timecard'}>{t('timecard.title')}</MenuItem>
                                <MenuItem value={'request'}>{t('request.title')}</MenuItem>
                                <MenuItem value={'schedule'}>{t('schedule.title')}</MenuItem>
                                <MenuItem value={'overtime'}>{t('overtime.title')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                        <LoadingButton
                            sx={{position: 'absolute', right: '15px', height: '56px'}} 
                            loading={loadingGettingAudit}
                            variant='contained'
                            onClick={() => {
                                gettingAudit(
                                    setLoadingGettingAudit,
                                    JSON.parse(sessionStorage.getItem('account'))?.id,
                                    range2,
                                    search,
                                    type,
                                    setAudit,
                                )
                            }}
                        >
                            {t('audits.table.filter.search')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export { FilterA }