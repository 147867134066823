import { Avatar, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material"
import { useState } from "react";
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import PersonIcon from '@mui/icons-material/Person';
import { signingOut } from "../../utils/Login";
import { useMsal } from "@azure/msal-react";
import { Config } from "../dialog/config/Config";
import SettingsIcon from '@mui/icons-material/Settings';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from "react-i18next";

const Settings = ({ openLeft }) => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    const [anchorEl, setAnchorEl] = useState(null);
    // eslint-disable-next-line
    const [openConfig, setOpenConfig] = useState(false);
    //const [value, setValue] = useState('1');

    return (
        <>
            <List sx={{position: 'absolute', bottom: 0, width: '100%'}}>
                <Divider />

                <ListItem
                    secondaryAction={
                        openLeft &&
                            <IconButton 
                                edge="end" 
                                aria-label="delete"
                                onClick={(event) => { 
                                    setAnchorEl(event.currentTarget)
                                }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                    }
                >
                    <ListItemAvatar
                        sx={{cursor: 'pointer'}}
                        onClick={(event) => { 
                            setAnchorEl(event.currentTarget)
                        }}
                    >
                        <Avatar>
                            <PersonIcon />
                        </Avatar>
                    </ListItemAvatar>

                    <ListItemText 
                        primary={JSON.parse(sessionStorage.getItem('account'))?.username} 
                        secondary={`${JSON.parse(sessionStorage.getItem('account'))?.name} ${JSON.parse(sessionStorage.getItem('account'))?.lastname}`}
                    />
                </ListItem>
            </List>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                onClick={() => setAnchorEl(null)}
            >
                <MenuItem 
                    onClick={() => {
                        setAnchorEl(null);
                        setOpenConfig(true); 
                    }}
                >
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>

                    <ListItemText primary={t('settings.menu.settings')} />
                </MenuItem>
                

                <MenuItem
                    onClick={() => {
                        switch(JSON.parse(sessionStorage.getItem('account'))?.signin){
                            case 'MS_SSO':
                                signingOut(instance)
                                break
                            default:
                                console.log('signing out')
                                sessionStorage.clear()
                                localStorage.clear()
                                window.location.replace('/signin')
                        }
                    }}
                >
                    <ListItemIcon>
                        <ExitToAppIcon color='error'/>
                    </ListItemIcon>

                    <ListItemText primary={<Typography variant='p' color='error'> {t('settings.menu.logout')} </Typography>} />
                </MenuItem>
            </Menu>

            <Config 
                open={openConfig} 
                setOpen={setOpenConfig}
            />
        </>
    )
}

export { 
    Settings
}