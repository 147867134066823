import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Typography } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { applyingOvertime } from '../../../utils/Overtime'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import CachedIcon from '@mui/icons-material/Cached';

const MassiveApprove = ({data, open, setOpen, checked, reloading}) => {
    const { t } = useTranslation()

    const closing = () => {
        setOpen(false)
        reloading()
    }

    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            field: 'full_name',
            headerName: t('overtime.table.modals.results.table.columns.full_name'),
            flex: 2,
        },
        {
            field: 'person_number',
            headerName: t('overtime.table.modals.results.table.columns.person_number'),
            flex: 2,
        },
        {
            field: 'date',
            headerName: t('overtime.table.modals.results.table.columns.date'),
            flex: 2,
        },
        {
            field: 'result',
            headerName: t('overtime.table.modals.results.table.columns.result'),
            flex: 2,
            renderCell: (params) => {
                return(
                    <>
                        <Box 
                            sx={{
                                display: 'grid', 
                                placeItems: 'center',
                                width: '100%',
                                height: '100%',
                                gap: '10px',
                            }}
                        >
                            { params?.value === 'success' ? <DoneIcon color='success'/> : <CloseIcon color='error'/> }
                        </Box>
                    </>
                )
            }
        },
        {
            field: 'retry',
            headerName: t('overtime.table.modals.results.table.columns.retry'),
            flex: 2,
            renderCell: (params) => {
                return(
                    <>
                        <Box 
                            sx={{
                                display: 'grid', 
                                placeItems: 'center',
                                width: '100%',
                                height: '100%',
                                gap: '10px',
                            }}
                        >
                            <IconButton 
                                disabled={params?.value === false || params?.row?.result === 'success'}
                                onClick={() => {
                                    applyingOvertime(setLoading, checked.filter(c => c.id = `${params?.row?.person_number}-${params?.row?.date}`), setRows, true)
                                }}
                            >
                                <CachedIcon />
                            </IconButton>
                        </Box>
                    </>
                )
            }
        },
    ]

    useEffect(() => {
        if(open){
            setRows(
                checked.map(row => ({
                    id: row.id,
                    person_number: row.person_number,
                    full_name: row.full_name,
                    date: row.date,
                    result: undefined,
                    retry: false,
                }))
            )

            applyingOvertime(setLoading, checked, setRows, true)
        }

    }, [checked, open])

    return (
        <>
            <Dialog
                fullWidth
                open={open} 
                onClose={() => { closing() }}
            >
                <DialogTitle>
                    <Typography variant='body3'>{ t('overtime.table.modals.results.title') }</Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <DataGridPro 
                        columns={columns}
                        rows={rows}
                        loading={loading}
                        slots={{
                            loadingOverlay: LinearProgress,
                        }}
                        hideFooter
                        sx={{ minHeight: '150px'}}
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                        sx={{ mt: 0.5, mr: 2 }}
                        disableElevation
                        color='error'
                        variant='contained'
                        onClick={() => { closing() }}
                    >
                        { t('overtime.table.modals.results.buttons.close') }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export { 
    MassiveApprove
}