import moment from "moment"
import { daysBetween } from "./Dates"
import { instance } from "./session/axios"

// Funciones utiles.
const calculateOffset = (index, first, segment) => {
    let offset = {start: null, end: null}
    let mfirst = moment.duration(moment(first.start).format('HH:mm')).asHours()
    let mstart = moment.duration(moment(segment.start).format('HH:mm')).asHours()
    let mend   = moment.duration(moment(segment.end).format('HH:mm')).asHours()
    
    offset.start = index === 0 ? 1 : mfirst < mstart ? 1 : 2
    offset.end   = mfirst < mend ? 1 : 2

    return offset
}

const gettingShift = (shift) => {
    let firts = shift[0]
    let last  = shift[shift.length -1]
    
    return moment(firts.start).format('HH:mm') + ' - ' + moment(last.end).format('HH:mm')
}

const gettingSchedule = async (
    setLoading, 
    range, 
    employees,
    setRows,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).schedule.get_schedule){
        setLoading(true)

        const body = {
            start_date: range[0].format('YYYY-MM-DD'),
            end_date: range[1].format('YYYY-MM-DD'),
            employees: [...new Set(employees.map(employee => employee.id))]
        }

        await instance.post('schedule/multi_read', body)
        .then((response) => {
            let person_numbers = Object.keys(response.data)
            let rows = []

            for(const person_number of person_numbers){
                let row = {
                    id: person_number,
                    person_number: person_number,
                    full_name: employees.find(employee => employee.id === person_number)?.full_name,
                    planned_hours: [],
                }

                for(const day of  daysBetween(range[0], range[1])){
                    row[day] = {
                        shifts: response.data[person_number]['shifts'][day],
                        hours: response.data[person_number]['hours'][day],
                        days: response.data[person_number]['days'][day]
                    }

                    if(response?.data?.[person_number]?.['shifts']?.[day]){
                        const total = response.data[person_number]['shifts'][day]
                        .reduce((total, segments) => {
                            const subtotal = segments.reduce((subtotal, segment) => {
                                const decimal = Math.abs(segment.amount)
                                const hours = Math.floor(decimal)
                                const minutes = Math.round((decimal - hours) * 60)
                                
                                const duration = moment.duration({hours, minutes})
                                
                                subtotal.add(duration)

                                return subtotal

                            }, moment.duration({hours: 0, minutes: 0}))
                            
                            total.add(subtotal)

                            return total

                        }, moment.duration({hours: 0, minutes: 0}))
                        

                        row.planned_hours.push(total)
                    }
                }

                const final_total = row.planned_hours.reduce((total, duration) => total.add(duration), moment.duration({hours: 0, minutes: 0}))

                row.planned_hours = `${Math.floor(final_total.asHours()).toString()}:${final_total.minutes().toString().padStart(2, '0')}`

                rows.push(row)
            }

            setRows(rows)
        })
        .catch((error) => { 
            console.error(error.message)
            handlerAlert(
                'schedule.table.alerts.error_get_schedule.title', 
                'schedule.table.alerts.error_get_schedule.message',
                'error',
            )
        })
        .finally(() => { 
            const data = JSON.parse(sessionStorage.getItem('schedule'))

            if(data){
                sessionStorage.setItem('schedule', JSON.stringify({ 
                    environment: data?.environment ? data.environment : null,
                    redirect: null
                }))
            }

            setLoading(false)
        })
    }else{
        handlerAlert(
            'schedule.table.alerts.get_schedule.title', 
            'schedule.table.alerts.get_schedule.message',
            'error',
        )
    }
}

const gettingPaycodes = async (
    setLoading, 
    setPaycodes,
    handlerAlert
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).schedule.get_paycodes){
        setLoading(true)

        await instance.get('paycode')
        .then((response) => { 
            const keys = Object.keys(response.data)
            const paycodes = []

            for (const key of keys){
                paycodes.push({
                    name: response.data[key].name,
                    is_visible: response.data[key].is_visible,
                    excused_absence: response.data[key].excused_absence,
                    is_days: response.data[key].is_days,
                })
            }

            setPaycodes(paycodes)
        })
        .catch(() => { 
            handlerAlert(
                'schedule.table.alerts.error_get_paycodes.title', 
                'schedule.table.alerts.error_get_paycodes.message',
                'error',
            )
        })
        .finally(() => { setLoading(false) })
    }else{
        handlerAlert(
            'schedule.table.alerts.get_paycodes.title', 
            'schedule.table.alerts.get_paycodes.message',
            'error',
        )
    }
}

// Acciones
const deletingHourPaycode = async (
    setLoading,
    paycode,
    closing,
    reloading,
    handlerAlert
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).schedule.delete_paycode_hours){
        setLoading(true)

        const body = {
            person_number: paycode.person_number,
            paycode: paycode.paycode,
            date: paycode.date,
            start_time: paycode.start_time,
            amount: paycode.amount,
        }

        await instance.post('schedule/paycodes/hours/delete', body)
        .then(() => { 
            closing()
            reloading()
        })
        .catch(() => { 
            handlerAlert(
                'schedule.table.alerts.error_delete_paycode_hours.title', 
                'schedule.table.alerts.error_delete_paycode_hours.message',
                'error',
            )
        })
        .finally(() => { setLoading(false) })
    }else{
        handlerAlert(
            'schedule.table.alerts.delete_paycode_hours.title', 
            'schedule.table.alerts.delete_paycode_hours.message',
            'error',
        )
    }
}

const deletingDayPaycode = async (
    setLoading,
    paycode,
    closing,
    reloading,
    handlerAlert
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).schedule.delete_paycode_days){
        setLoading(true)

        const body = {
            person_number: paycode.person_number,
            paycode: paycode.paycode,
            start_date: paycode.date,
            end_date: paycode.date,
        }

        await instance.post('schedule/paycodes/days/delete', body)
        .then(() => { 
            closing()
            reloading()
        })
        .catch(() => { 
            handlerAlert(
                'schedule.table.alerts.error_delete_paycode_days.title', 
                'schedule.table.alerts.error_delete_paycode_days.message',
                'error',
            )
         })
        .finally(() => { setLoading(false) })
    }else{
        handlerAlert(
            'schedule.table.alerts.delete_paycode_days.title', 
            'schedule.table.alerts.delete_paycode_days.message',
            'error',
        )
    }
}

const addingHourPaycode = async (
    setLoading,
    employees,
    range,
    paycode,
    start_time,
    amount,
    override,
    files,
    periods,
    closing,
    reloading,
    handlerAlert
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).schedule.add_paycode_hours){
        setLoading(true)

        const promises = employees.map((employee) => {
            if(moment(periods.find(period => period.person_number === employee.id)?.end_date, 'YYYY-MM-DD').isSameOrAfter(range[0])){
                return Promise.resolve()
            }
            
            let data = new FormData()
            
            for(const [index, date] of daysBetween(range[0], range[1]).entries()){
                data.append(`paycodes[${index}][paycode]`, paycode)
                data.append(`paycodes[${index}][person_number]`, employee.id)
                data.append(`paycodes[${index}][start_time]`, start_time.format('HH:mm'))
                data.append(`paycodes[${index}][date]`, date)
                data.append(`paycodes[${index}][amount]`, amount.hours() + (amount.minutes() / 60))
                data.append(`paycodes[${index}][override]`, override)
                for(const file of files){
                    data.append(`paycodes[${index}][file]`, new Blob([file], { type: file.type }), file.name || 'file')
                }
            }

            return instance.post('schedule/paycodes/hours', data)
        })

        await Promise.all(promises)
        .then(() => {
            reloading()
            closing()
        })
        .catch(() => { 
            handlerAlert(
                'schedule.table.alerts.error_add_paycode_hours.title', 
                'schedule.table.alerts.error_add_paycode_hours.message',
                'error',
            )
        })
        .finally(() => { setLoading(false) })
    }else{
        handlerAlert(
            'schedule.table.alerts.add_paycode_hours.title', 
            'schedule.table.alerts.add_paycode_hours.message',
            'error',
        )
    }
}

const addingDayPaycode = async (
    setLoading,
    employees,
    range,
    paycode,
    override,
    files,
    periods,
    closing,
    reloading,
    handlerAlert
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).schedule.add_paycode_days){
        setLoading(true)

        const promises = employees.map((employee) => {
            if(moment(periods.find(period => period.person_number === employee.id)?.end_date, 'YYYY-MM-DD').isSameOrAfter(range[0])){
                return Promise.resolve()
            }

            let data = new FormData()

            for(const [index, date] of daysBetween(range[0], range[1]).entries()){
                data.append(`paycodes[${index}][paycode]`, paycode)
                data.append(`paycodes[${index}][person_number]`, employee.id)
                data.append(`paycodes[${index}][start_date]`, date)
                data.append(`paycodes[${index}][end_date]`, date)
                data.append(`paycodes[${index}][override]`, override) 
                for(const file of files){
                    data.append(`paycodes[${index}][file]`, new Blob([file], { type: file.type }), file.name || 'file')
                }
            }

            return instance.post('schedule/paycodes/days', data)
        })

        await Promise.all(promises)
        .then(() => {
            reloading()
            closing()
        })
        .catch(() => { 
            handlerAlert(
                'schedule.table.alerts.error_add_paycode_days.title', 
                'schedule.table.alerts.error_add_paycode_days.message',
                'error',
            )
        })
        .finally(() => { setLoading(false) })
    }else{
        handlerAlert(
            'schedule.table.alerts.add_paycode_days.title', 
            'schedule.table.alerts.add_paycode_days.message',
            'error',
        )
    }
}

const delettingShift = async (
    setLoading, 
    person_number, 
    date, 
    shift,
    closing,
    reloading,
    handlerAlert
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).schedule.delete_shift){
        setLoading(true)

        const segments = shift.map((segment, index) => {
            const offset = calculateOffset(index, shift[0], segment)

            return {
                type: segment.type,
                start_time: moment(segment.start).format('HH:mm'),
                end_time: moment(segment.end).format('HH:mm'),
                offset_start_day: offset.start,
                offset_end_day: offset.end
            }
        })

        const body = {
            person_number: person_number,
            date: date,
            segments: segments
        }

        await instance.post('schedule/shifts/delete', body)
        .then((response) => { 
            closing()
            reloading()
        })
        .catch(() => { 
            handlerAlert(
                'schedule.table.alerts.error_delete_shift.title', 
                'schedule.table.alerts.error_delete_shift.message',
                'error',
            )
        })
        .finally(() => { setLoading(false) })
    }else{
        handlerAlert(
            'schedule.table.alerts.delete_shift.title', 
            'schedule.table.alerts.delete_shift.message',
            'error',
        )
    }

}

const addingShift = async (
    setLoading,
    employees,
    range,
    segments,
    periods,
    closing,
    reloading,
    handlerAlert
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).schedule.add_shift){
        setLoading(true)
        
        const promises = employees.map((employee) => {
            let shifts = []

            for(const date of daysBetween(range[0], range[1])){
                if(moment(periods.find(period => period.person_number === employee.id)?.end_date, 'YYYY-MM-DD').isSameOrAfter(moment(date, 'YYYY-MM-DD'))){
                    continue
                }

                shifts.push({
                    person_number: employee.id,
                    date: date,
                    override: false,
                    override_paycode: false,
                    segments: segments.map((segment, index) => ({
                        type: segment.type,
                        start_time: moment(segment.start).format('HH:mm'),
                        end_time: moment(segment.end).format('HH:mm'),
                        offset_start_day: index === 0 ? 1 : moment.duration(segments[0].start).asHours() < moment.duration(segments[index].start).asHours() ? 1 : 2,
                        offset_end_day: moment.duration(segments[0].start).asHours() < moment.duration(segments[index].end).asHours() ? 1 : 2,
                    }))
                })
            }
            
            if(shifts.length === 0){
                return Promise.resolve()
            }

            return instance.post('schedule/shifts', { shifts })
        })

        await Promise.all(promises)
        .then(() => {
            reloading()
            closing()
        })
        .catch(() => { 
            handlerAlert(
                'schedule.table.alerts.error_add_shift.title', 
                'schedule.table.alerts.error_add_shift.message',
                'error',
            ) 
        })
        .finally(() => { 
            setLoading(false) 
        })
    }else{
        handlerAlert(
            'schedule.table.alerts.add_shift.title', 
            'schedule.table.alerts.add_shift.message',
            'error',
        )
    }
    
}

const bulkLoading = async (
    setLoading,
    shifts,
    reloading,
    closing,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).schedule.add_shift){
        setLoading(true)

        await instance.post('schedule/shifts', { shifts })
        .then(response => {
            reloading()
            closing()
        })
        .catch(() => {
            handlerAlert(
                'schedule.table.alerts.error_add_shift.title', 
                'schedule.table.alerts.error_add_shift.message',
                'error',
            )
        })
        .finally(() => { setLoading(false) })
    }else{
        handlerAlert(
            'schedule.table.alerts.add_shift.title', 
            'schedule.table.alerts.add_shift.message',
            'error',
        )
    }
}

const cleaningSchedule = async (
    setLoading,
    employees,
    range,
    periods,
    closing,
    reloading,
    handlerAlert
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).schedule.clean_schedule){
        setLoading(true)

        let promises = employees.map((employee) => {
            const min_date = moment(periods.find(period => period.person_number === employee)?.end_date, 'YYYY-MM-DD').add(1, 'days')
            
            if(min_date.isBetween(range[0], range[1], undefined, '[]')){
                return instance.delete(`schedule/${employee}/${min_date.format('YYYY-MM-DD')}/${range[1].format('YYYY-MM-DD')}`)
            }
            else if(min_date.isBefore(range[0])){
                return instance.delete(`schedule/${employee}/${range[0].format('YYYY-MM-DD')}/${range[1].format('YYYY-MM-DD')}`)
            }
            else{
                return Promise.resolve()
            }
        })


        await Promise.all(promises)
        .then(() => {
            reloading()
            closing()
        })
        .catch(() => { 
            handlerAlert(
                'schedule.table.alerts.error_clean_schedule.title', 
                'schedule.table.alerts.error_clean_schedule.message',
                'error',
            )
        })
        .finally(() => { setLoading(false) })
    }else{
        handlerAlert(
            'schedule.table.alerts.clean_schedule.title', 
            'schedule.table.alerts.clean_schedule.message',
            'error',
        )
    }
}

export {
    gettingShift,
    gettingSchedule,
    gettingPaycodes,
    delettingShift,
    deletingHourPaycode,
    deletingDayPaycode,
    addingHourPaycode,
    addingDayPaycode,
    addingShift,
    bulkLoading,
    cleaningSchedule,
}