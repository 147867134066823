import { Box, Divider, Fade, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const ContextMenu = ({
    contextMenu, 
    setContextMenu, 
    options, 
    checked
}) => {
    const { t } = useTranslation()

    return (
        <>
            <Menu
                open={ contextMenu !== null }
                onClose={() => setContextMenu(null)}
                anchorReference='anchorPosition'
                anchorPosition={
                    contextMenu !== null ?
                        { top: contextMenu.mouseY, left: contextMenu.mouseX } 
                        : 
                        undefined
                }
                TransitionComponent={Fade}
                slotProps={{
                    paper: {
                        style:{borderRadius: 5, boxShadow: 'none', opacity: 0.95, border: '1px solid rgba(0,0,0,0.1)'}
                    }
                }}
                sx={{cursor: 'pointer',}}
            >
                { options.filter(option => option.privilege).map((option, index) =>
                    <Box key={index}>
                        { option.divider ? <Divider /> : null }

                        <MenuItem key={index} onClick={option.click} disabled={option.disabled}>
                            <ListItemIcon>
                                { option.icon }
                            </ListItemIcon>

                            <ListItemText>{ option.name }</ListItemText>

                            { option.seleted ? 
                                <Typography sx={{pl: 3}} variant="body2" color={option.color}> 
                                    { t('employees.employee_table.menu.selected') }
                                    : 
                                    { checked.length }
                                </Typography>
                                : 
                                null 
                            }
                        </MenuItem>
                    </Box>
                )}
            </Menu>

            { options.filter(option => option.privilege).map((option, index) =>
                <Box key={index}>
                    { option.dialog }
                </Box>
            )}
        </>
    )
}

export {
    ContextMenu
}