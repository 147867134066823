import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { getWeek } from "../../../utils/Dates";
import { gettingMultiRead } from "../../../utils/Employee";
import { GlobalContext } from "../../../GlobalContext";

const Context = createContext({});

const ContextProvider = ({ children, value }) => {
    const { handlerAlert } = useContext(GlobalContext)

    const [range1, setRange1] = useState([
        moment(getWeek()[0]), 
        moment(getWeek()[1])
    ]);

    const [range2, setRange2] = useState([
        moment(getWeek()[0]), 
        moment(getWeek()[1])
    ]);

    const [smartgroups, setSmartgroups] = useState([]);
    const [smartgroup, setSmartgroup] = useState([]);
    const [filter1, setFilter1] = useState('');

    const [employees, setEmployees] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [filter2, setFilter2] = useState('');

    const [rows, setRows] = useState([]);
    const [audit, setAudit] = useState([]);
    const [search, setSearch] = useState('audit_date');

    const [ready, setReady] = useState(false)
    const [renders, setRenders] = useState(0)

    const [loadingGettingSmartgroups, setLoadingGettingSmartgroups] = useState(false);
    const [loadingGettingEmployees, setLoadingGettingEmployees] = useState(false);
    const [loadingGettingInfoEmployees, setLoadingGettingInfoEmployees] = useState(false);
    const [loadingGettingAudit, setLoadingGettingAudit] = useState(false);

    const searching = async () => {
        await gettingMultiRead(
            setLoadingGettingInfoEmployees,
            range1,
            [...new Set(employee.map(employee => employee.id))],
            [...new Set(smartgroup.map(smartgroup => smartgroup.id))],
            setRows,
            handlerAlert
        )
    }

    const reloading = async () => {
        const data = JSON.parse(sessionStorage.getItem('employees'))

        await gettingMultiRead(
            setLoadingGettingInfoEmployees,
            [moment(data?.reloading?.start, 'YYYY-MM-DD'), moment(data?.reloading?.end, 'YYYY-MM-DD')],
            data?.reloading?.employees,
            data?.reloading?.smartgroups,
            setRows,
            handlerAlert
        )
    }

    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem('employees'))

        if(data?.redirect){
            setRange1([moment(data.redirect.start, 'YYYY-MM-DD'), moment(data.redirect.end, 'YYYY-MM-DD')])
            setSmartgroup(data.redirect.smartgroups)
            setEmployee(data.redirect.employees)
        }

        /* 
            else{
                if(data?.environment){
                    setRange1([moment(data.environment.start, 'YYYY-MM-DD'), moment(data.environment.end, 'YYYY-MM-DD')])
                    setSmartgroup(data.environment.smartgroups)
                    setEmployee(data.environment.employees)
                }
            } 
        */
    }, [])
    
    return(
        <Context.Provider 
            value={{
                rows, 
                setRows,
                audit, 
                setAudit,
                range1, 
                setRange1,
                range2, 
                setRange2,
                smartgroups, 
                setSmartgroups,
                smartgroup, 
                setSmartgroup,
                filter1, 
                setFilter1,
                employees, 
                setEmployees,
                employee, 
                setEmployee,
                filter2, 
                setFilter2,
                loadingGettingSmartgroups, setLoadingGettingSmartgroups,
                loadingGettingEmployees, setLoadingGettingEmployees,
                loadingGettingInfoEmployees, setLoadingGettingInfoEmployees,
                loadingGettingAudit, setLoadingGettingAudit,
                ready, setReady,
                renders, setRenders,
                search, setSearch,
                searching,
                reloading,
                ...value,
            }}
        >
            { children }
        </Context.Provider>
    )
}

export {
    Context,
    ContextProvider,
}
