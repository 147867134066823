import { useTranslation } from 'react-i18next'
import Navbar from '../../components/navigation/Navbar'
import { ContextProvider } from '../../components/table/schedule/Context'
import { Table } from '../../components/table/schedule/Table'

const Schedule = () => {
    const { t } = useTranslation()

    return (
        <>
            <Navbar 
                view={{
                    title: t('schedule.title'),
                    component: 
                        <ContextProvider>
                            <Table />
                        </ContextProvider>
                }}
                right={{
                    title: 'Right',
                    component: <> componente </>
                }}
            />
        </>
    )
}

export { Schedule } 