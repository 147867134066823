import { createContext, useContext, useState } from "react";
import { GlobalContext } from "../../../GlobalContext";
import { gettingReports } from "../../../utils/Reports";

const Context = createContext({});

const ContextProvider = ({ children, value }) => {
    const { handlerAlert } = useContext(GlobalContext)

    const [rows, setRows] = useState([]);
    const [loadingGettingResults, setLoadingGettingResults] = useState(false);

    const reloading = async () => {
        setRows([]);
        
        await gettingReports(
            setLoadingGettingResults,
            setRows,
            handlerAlert,
        )
    }

    return (
        <Context.Provider value={{
            rows, setRows,
            loadingGettingResults, setLoadingGettingResults,
            reloading,
        }}>
            {children}
        </Context.Provider>
    );
}

export {
    Context,
    ContextProvider
}