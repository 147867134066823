import { useTranslation } from "react-i18next"
import Navbar from "../../components/navigation/Navbar"
import { Table } from "../../components/table/requests/Table"
import { ContextProvider } from "../../components/table/requests/Context"


const Requests = () => {
    const { t } = useTranslation()

    return (
        <>
            <Navbar 
                view={{
                    title: t('request.title'),
                    component:
                        <ContextProvider>
                            <Table />
                        </ContextProvider>
                }}
                right={{
                    title: 'Right',
                    component: <> componente </>
                }}
            />
        </>
    )
}

export { 
    Requests
}