// REACT
import { useState, useContext, useEffect } from "react";

// MUI
import { Box, Divider, Skeleton, TextField, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { grey } from "@mui/material/colors";

// MUI ICONS
import GoogleIcon from '@mui/icons-material/Google';
import WindowIcon from '@mui/icons-material/Window';

// LIBRARIES
import { useTranslation } from "react-i18next";

// Files
import { gettingCompanyLogo, siginingIn, validatePassword, validateUsername } from "../../utils/Login";
import { GlobalContext } from "../../GlobalContext";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../utils/session/authConfig";
import { useGoogleLogin } from "@react-oauth/google";


export const Login = ({redirect}) => {
	const { t } = useTranslation()
	const { instance } = useMsal()

	const { setAuthenticated, handlerAlert } = useContext(GlobalContext)
	
	const [username, setUsername] = useState({
		value: '', 
		error: false, 
		helperText: ''
	})

	const [password, setPassword] = useState({
		value: '', 
		error: false, 
		helperText: ''
	})

	const [loading, setLoading] = useState(false)
	const [loadingLogo, setLoadingLogo] = useState(true)
	
	const handleLoginRedirect = () => {
        instance
		.loginRedirect({
			...loginRequest,
			prompt: 'create',
			redirectStartPage: process.env.REACT_APP_MS_REDIRECTURI,
		})
		.catch(error =>  console.error(error))
    }

	const signing = useGoogleLogin({
		onSuccess: tokenResponse => { 
			console.log(tokenResponse) 
			sessionStorage.setItem('access_Token', JSON.stringify(tokenResponse));
		},
		flow: 'auth-code',
		ux_mode: 'redirect',
		redirect_uri: 'http://localhost:3000',
		onError: error => console.error(error),
	}); 

	useEffect(() => {
		gettingCompanyLogo(setLoadingLogo)

		if(sessionStorage.getItem('403')){
			handlerAlert(
				t('sign_in.alerts.403.title'), 
				t('sign_in.alerts.403.message'), 
				'error'
			)

			sessionStorage.removeItem('403')
		}

	}, [handlerAlert, t]);

    
	return (
		<>
			<Box 
				sx={{
					width: 400, 
					padding: 7, 
					borderRadius: 5, 
					border: (theme) => theme.palette.mode === 'dark' ? '1px solid rgba(255, 255, 255, 0.12)' : '1px solid rgba(0, 0, 0, 0.12)',
					background: (theme) => theme.palette.mode === 'dark' ? grey[900] : grey[50],
				}}
			>
				<form 
					onSubmit={(event) => {
						siginingIn(
							event, 
							setLoading, 
							username, 
							password,  
							setAuthenticated,
							setUsername,
							setPassword,
							t('sign_in.form.invalid_credentials'),
							redirect
						)
					}}
				>
					<center>
						{ loadingLogo ? 
							<Skeleton 
								sx={{
									marginTop: '20px', 
									marginBottom: '20px',
									borderRadius: 1
								}} 
								variant="rectangular" 
								height={100}
								width={100}
							/>
							:
							<img
								src = {JSON.parse(sessionStorage.getItem('images'))?.logo_login}
								alt='logo-app'
								height={100}
								width={100}
								style={{
									marginTop: 20, 
									marginBottom: 20,
								}}
							/>
						}
					</center>

					<TextField
						id="username"
						label={t('sign_in.form.username')}
						type="text"
						variant="outlined"
						fullWidth
						autoComplete="current-password"
						value={username.value}
						onChange={(event) => {
							if(validateUsername(event.target.value)){
								setUsername({
									value: event.target.value, 
									error: false, 
									helperText: ''
								})
							}else{
								setUsername({
									value: event.target.value, 
									error: true, 
									helperText: event.target.value ? t('sign_in.form.invalid_username') : t('sign_in.form.empty_username')
								})
							}
						}}
						error={username.error}
						helperText={username.helperText}
					/>
					
					<TextField
						sx={{mt: 2}}
						id="password"
						type="password"
						label={t('sign_in.form.password')}
						variant="outlined"
						fullWidth
						autoComplete="current-password"
						value={password.value}
						onChange={(event) => {
							if(validatePassword(event.target.value)){
								setPassword({
									value: event.target.value, 
									error: false, 
									helperText: ''
								})

							}else{
								setPassword({
									value: event.target.value, 
									error: true, 
									helperText: event.target.value ? t('sign_in.form.invalid_password') : t('sign_in.form.empty_password')
								})
							}
						}}
						error={password.error}
						helperText={password.helperText}
					/>

					<LoadingButton
						sx={{mt: 3, mb: 3, height: 50}}
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						loading={loading}
						disableElevation
						disabled={username.error || password.error || username.value === '' || password.value === '' }
					>
						{ t('sign_in.form.sign_in') }
					</LoadingButton>
					
					{ window.location.hostname.split('.')[0] !== 'fp' &&
						<>
							<Divider> 
								<Typography>{ t('sign_in.form.or') }</Typography> 
							</Divider>

							<LoadingButton
								sx={{mt: 3, height: 50}}
								fullWidth
								variant="contained"
								color="primary"
								loading={false}
								disableElevation
								endIcon={<GoogleIcon />}
								onClick={() => signing()}
								disabled={true}
							>
								{ t('sign_in.form.sign_in_with') }
							</LoadingButton>

							<LoadingButton
								sx={{mt: 3, height: 50}}
								fullWidth
								variant="contained"
								color="primary"
								loading={false}
								disableElevation
								endIcon={<WindowIcon />}
								onClick={()=> handleLoginRedirect()}
							>

								{ t('sign_in.form.sign_in_with') }
							</LoadingButton>
						</>
					}
					

					<Typography sx={{ mt: 4, textAlign: 'center'}}>{ t('sign_in.form.version') }: 0.0.31b</Typography>
				</form>
			</Box>
		</>
	)
}