import moment from "moment"
import { instance } from "./session/axios"

const gettingAudit = async (
    setLoading,
    user_id,
    range,
    search_by,
    type,
    setRows,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).audits.get_audits){
        setLoading(true)

        const params = {
            user_id,
            start_date: range[0].format('YYYY-MM-DD'),
            end_date: range[1].format('YYYY-MM-DD'),
            search_by,
            type
        }

        await instance.get('audits', { params })
        .then(response => {
            const rows = []

            for(let key of Object.keys(response.data)){
                const row = {
                    id: key,
                    date: moment.utc(response.data[key]?.timestamp, 'YYYY-MM-DD HH:mm:ss').local().format('YYYY-MM-DD'),
                    hour: moment.utc(response.data[key]?.timestamp, 'YYYY-MM-DD HH:mm:ss').local().format('HH:mm:ss'),
                    register: response.data[key]?.register,
                    action: response.data[key]?.action,
                    status: response.data[key]?.status,
                    message: response.data[key]?.message,
                    user: response.data[key]?.user_name,
                    employee: response.data[key]?.employee,
                    params: response.data[key]?.params,
                    archive: response.data[key]?.file,
                }

                rows.push(row)
            }

            setRows(rows.reverse())
        })
        .catch(() => { 
            handlerAlert(
                'audits.table.alerts.get_audits.title', 
                'audits.table.alerts.get_audits.message', 
                'error',
            )
        })
        .finally(() => {
            setLoading(false)
        })
    }else{
        handlerAlert(
            'audits.table.alerts.error_get_audits.title', 
            'audits.table.alerts.error_get_audits.message', 
            'error',
        )
    }
}

export {
    gettingAudit
}