import axios from "axios"

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_DOMAIN}/`,
    timeout: 1000 * 60,
    headers: {
        appkey: process.env.REACT_APP_API_APPKEY,
        Authorization: sessionStorage.getItem('token'),
    },
});

instance.interceptors.request.use(
    (config) => {
        config.headers.Authorization = sessionStorage.getItem('token');
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


export { instance }


