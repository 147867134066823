import { beetwenDates } from "./Dates"
import { instance } from "./session/axios"
import { saveReload } from "./SessionStorage"

const gettingMultiRead = async (
    setLoading, 
    range,
    employees,
    smartgroups,
    setRows,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).employees.get_info_employees){
        setLoading(true)

        const body = {
            start_date: range[0].format('YYYY-MM-DD'),
            end_date: range[1].format('YYYY-MM-DD'),
            employees
        }
    
        await instance.post('person/multi_read', body)
        .then(response => {
            const rows = []

            for(const person_number of Object.keys(response.data)){
                const labor = response.data[person_number].labor_accounts.find(labor => beetwenDates([range[0].format('YYYY-MM-DD'), range[1].format('YYYY-MM-DD')], [labor.effective_date, labor.expiration_date]))

                if(rows.find((row) => row.id === response.data[person_number].person_data.person_number) === undefined){
                    rows.push({
                        id: response.data[person_number].person_data.person_number, 
                        person_number: response.data[person_number].person_data.person_number,
                        full_name: response.data[person_number].person_data.full_name,
                        hire_date: response.data[person_number].person_data.hire_date,
                        closed_period: response.data[person_number].person_data.mgr_sign_off_thru_datetime,
                        org_path: labor.labor_account_description
                    })
                }
            }

            setRows(rows)

            saveReload(
                'employees', 
                range[0].format('YYYY-MM-DD'), 
                range[1].format('YYYY-MM-DD'), 
                smartgroups,
                employees, 
            )
        })
        .catch(() => { 
            handlerAlert(
                'employees.employee_table.alerts.error_get_info_employees.title', 
                'employees.employee_table.alerts.error_get_info_employees.message',
                'error',
            )
        })
        .finally(() => { 
            /* const data = JSON.parse(sessionStorage.getItem('employees'))

            if(data){
                sessionStorage.setItem('employees', JSON.stringify({ 
                    environment: data?.environment ? data.environment : null,
                    redirect: null
                }))
            } */
            
            setLoading(false)
        })
    }else{
        handlerAlert(
            'employees.employee_table.alerts.get_info_employees.title', 
            'employees.employee_table.alerts.get_info_employees.message',
            'error',
        )
    }
}

export {
    gettingMultiRead
}