import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const Options = ({options}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <>
            <IconButton 
                edge="end"
                onClick={(event) => {
                    setAnchorEl(event.currentTarget)
                }}
            >
                <MoreVertIcon />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                onClick={() => setAnchorEl(null)}
            >
                { options.map((option, index) => 
                    <MenuItem 
                        onClick={() => { 
                            setAnchorEl(null) 
                            option.click();
                        }}
                        color="error"
                    >
                        <ListItemIcon >
                            {option.icon}
                        </ListItemIcon>

                        <ListItemText primary={option.text} />
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}

export {
    Options
}