import { Box, Checkbox, Divider, FormControl, Grid, InputBase, InputLabel, ListItemText, ListSubheader, MenuItem, OutlinedInput, Select } from '@mui/material'
import { DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { shortcuts } from '../../utils/Dates'
import { getBorder } from '../../utils/Styles'
import { LoadingButton } from '@mui/lab'
import { GlobalContext } from '../../GlobalContext'
import { gettingEmployees, gettingSmartgroups } from '../../utils/Filter'

import SearchIcon from '@mui/icons-material/Search';
import { Context } from '../table/requests/Context'



// Common
const FilterR = ({view}) => {
    const { t } = useTranslation()

    const { 
        handlerAlert
    } = useContext(GlobalContext)

    const {
        range1,
        setRange1,
        smartgroups,
        setSmartgroups,
        smartgroup,
        setSmartgroup,
        filter1,
        setFilter1,
        employees,
        setEmployees,
        employee,
        setEmployee,
        filter2,
        setFilter2,
        loadingGettingSmartgroups,
        setLoadingGettingSmartgroups,
        loadingGettingEmployees,
        setLoadingGettingEmployees,
        loadingGettingRequests, //setLoadingGettingRequests,
        type, setType,
        ready, setReady,
        renders, setRenders,
        reloading,
    } = useContext(Context)

    useEffect(() => {
        gettingSmartgroups(
            setLoadingGettingSmartgroups,
            setSmartgroups,
            setSmartgroup,
            view,
            handlerAlert
        )

    }, [
        handlerAlert,
        setLoadingGettingSmartgroups, 
        setSmartgroup, 
        setSmartgroups,
        view
    ])

    
    useEffect(() => {
        if(smartgroup.length !== 0 && !loadingGettingSmartgroups){
            gettingEmployees(
                setLoadingGettingEmployees,
                smartgroups,
                smartgroup,
                setEmployees,
                setEmployee,
                view,
                handlerAlert,
            )            
            setReady(true)
            setEmployee([])
        }else{
            setEmployees([])
            setEmployee([])
        }

    }, [smartgroup, smartgroups, setEmployee, setEmployees, setLoadingGettingEmployees, handlerAlert, view, setReady, loadingGettingSmartgroups])

    
    useEffect(() => {
        if(
            employee.length !== 0 &&
            smartgroup.length !== 0 &&
            ready && renders === 0 &&
            !loadingGettingSmartgroups &&
            !loadingGettingEmployees
        ){
            reloading()
            setReady(false)
            setRenders(1)
        }
    }, [
        employee, 
        smartgroup, 
        ready,
        renders,
        reloading,
        view,
        setReady,
        setRenders,
        loadingGettingSmartgroups,
        loadingGettingEmployees
    ])

    return (
        <>
            <Box
                sx={{
                    display: 'flex', 
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 2,
                    p: 1.5,
                    borderBottom: theme => getBorder(theme.palette.mode)
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <DateRangePicker
                            label={t('request.table.filter.range_of_days')}
                            slots={{ field: SingleInputDateRangeField }}
                            slotProps={{
                                shortcuts: {
                                    items: shortcuts(t),
                                },
                            }}
                            format={JSON.parse(sessionStorage.getItem('config')).date_format}
                            calendars={1}
                            fullwith
                            onChange={(value) => { setRange1(value) }}
                            value={range1}
                            sx={{
                                width: '100%'
                            }}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <FormControl fullWidth required error={smartgroup.length === 0 && !loadingGettingSmartgroups}>    
                            <InputLabel id="lsg">{t('request.table.filter.smartgroups')}</InputLabel>

                            <Select
                                labelId="lsg"
                                id="sg"
                                multiple
                                value={smartgroup.map(sg => sg.name)}
                                input={<OutlinedInput label={t('request.table.filter.smartgroups')} />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={{
                                    style:{ 
                                        maxHeight: '400px',
                                    }
                                }}
                            >
                                { (smartgroups.length !== 0 && !loadingGettingSmartgroups) &&
                                    <Box>
                                        <Checkbox 
                                            sx={{ml: 2}} 
                                            checked={
                                                filter1 === '' ?
                                                    smartgroups.filter(s => smartgroup.map(s => s.name).includes(s.name)).length === smartgroups.length
                                                    :
                                                    smartgroups.filter(sg => sg.name.toLowerCase().includes(filter1.toLocaleLowerCase())).every(sg => smartgroup.map(s => s.name).includes(sg.name))
                                            } 
                                            indeterminate={
                                                filter1 === '' ?
                                                    smartgroups.filter(s => smartgroup.map(s => s.name).includes(s.name)).length !== smartgroups.length && smartgroup.length > 0
                                                    :
                                                    !smartgroups.filter(sg => sg.name.toLowerCase().includes(filter1.toLocaleLowerCase())).every(sg => smartgroup.map(s => s.name).includes(sg.name)) && 
                                                    !smartgroups.filter(sg => sg.name.toLowerCase().includes(filter1.toLocaleLowerCase())).every(sg => !smartgroup.map(s => s.name).includes(sg.name)) &&
                                                    smartgroups.filter(sg => sg.name.toLowerCase().includes(filter1.toLocaleLowerCase())).length !== 0 
                                            }
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSmartgroup(smartgroups.filter(sg => sg.name.toLowerCase().includes(filter1.toLocaleLowerCase())).map(sg => sg))
                                                }else{
                                                    setSmartgroup(smartgroup.filter(s => !smartgroups.filter(sg => sg.name.toLowerCase().includes(filter1.toLocaleLowerCase())).map(sg => sg.name).includes(s.name)))
                                                }
                                            }}
                                        />

                                        <InputBase 
                                            sx={{
                                                width: 'calc(100% - 80px)',
                                                ml: 1,
                                                mb: 1.5
                                            }}
                                            placeholder='search...'
                                            endAdornment={<SearchIcon sx={{mr: 1}} />}
                                            value={filter1}
                                            onChange={(e) => setFilter1(e.target.value)}
                                        />

                                        <Divider />

                                        { smartgroups.filter(sg => sg.name.toLowerCase().includes(filter1.toLocaleLowerCase())).map((sg) => (
                                            <MenuItem 
                                                key={sg.id} 
                                                onClick={() => {
                                                    if (smartgroup.filter(s => s.name === sg.name).length > 0) {
                                                        setSmartgroup(smartgroup.filter(s => s.name !== sg.name))
                                                    } else {
                                                        setSmartgroup([...smartgroup, sg])
                                                    }
                                                }}
                                            >
                                                <Checkbox checked={smartgroup.filter(s => s.name === sg.name).length > 0} />
                                                <ListItemText primary={sg.name} />
                                            </MenuItem>
                                        ))}
                                    </Box>
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl fullWidth error={employee.length === 0 && !loadingGettingEmployees}>
                            <InputLabel id="lsg">{t('request.table.filter.employees')}</InputLabel>

                            <Select
                                labelId="lsg"
                                id="sg"
                                multiple
                                value={employee.map(e => e.full_name)}
                                input={<OutlinedInput label={t('request.table.filter.employees')} />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={{
                                    style:{ 
                                        maxHeight: '400px',
                                    }
                                }}
                            >
                                { (employees.length !== 0 && !loadingGettingEmployees) &&
                                    <Box>
                                        <Checkbox 
                                            sx={{ml: 2}} 
                                            checked={
                                                filter2 === '' ?
                                                    employees.filter(e => employee.map(e => e.full_name).includes(e.full_name)).length === employees.length
                                                    :
                                                    employees.filter(e => e.full_name.toLowerCase().includes(filter2.toLocaleLowerCase())).every(em => employee.map(e => e.full_name).includes(em.full_name))
                                            } 
                                            indeterminate={
                                                filter2 === '' ?
                                                    employees.filter(e => employee.map(e => e.full_name).includes(e.full_name)).length !== employees.length && employee.length > 0
                                                    :
                                                    !employees.filter(em => em.full_name.toLowerCase().includes(filter2.toLocaleLowerCase())).every(em => employee.map(e => e.full_name).includes(em.full_name)) && 
                                                    !employees.filter(em => em.full_name.toLowerCase().includes(filter2.toLocaleLowerCase())).every(em => !employee.map(e => e.full_name).includes(em.full_name)) &&
                                                    employees.filter(em => em.full_name.toLowerCase().includes(filter2.toLocaleLowerCase())).length !== 0 
                                            }
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setEmployee(employees.filter(em => em.full_name.toLowerCase().includes(filter2.toLocaleLowerCase())).map(em => em))
                                                }else{
                                                    setEmployee(employee.filter(e => !employees.filter(em => em.full_name.toLowerCase().includes(filter2.toLocaleLowerCase())).map(em => em.full_name).includes(e.full_name)))
                                                }
                                            }}
                                        />

                                        <InputBase 
                                            sx={{
                                                width: 'calc(100% - 80px)',
                                                ml: 1,
                                                mb: 1.5
                                            }}
                                            placeholder='search...'
                                            endAdornment={<SearchIcon sx={{mr: 1}} />}
                                            value={filter2}
                                            onChange={(e) => setFilter2(e.target.value)}
                                        />

                                        <Divider />

                                        { smartgroups.filter(sg => smartgroup.map(sg => sg.name).includes(sg.name)).map(sg => 
                                            <Box key={sg.id}>
                                                <ListSubheader>{sg.name}</ListSubheader>

                                                { employees.filter(e => e.smartgroup === sg.name).filter(e => e.full_name.toLowerCase().includes(filter2.toLocaleLowerCase())).map((e) => (
                                                    <MenuItem 
                                                        key={`${e.id}-${e.smartgroup}`} value={e.full_name}
                                                        onClick={() => {
                                                            if (employee.filter(emp => emp.full_name === e.full_name).length > 0) {
                                                                setEmployee(employee.filter(emp => emp.full_name !== e.full_name))
                                                            } else {
                                                                setEmployee([...employee, e])
                                                            }
                                                        }}
                                                    >
                                                        <Checkbox checked={employee.filter(emp => emp.full_name === e.full_name).length > 0}/>
                                                        <ListItemText primary={e.full_name} />
                                                    </MenuItem>
                                                ))}
                                            </Box>
                                        )}
                                    </Box>
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="l-type">{t('request.table.filter.type')}</InputLabel>
                            
                            <Select
                                labelId="l-type"
                                id="type"
                                multiple
                                label={t('request.table.filter.type')}
                                value={type}
                                onChange={(event) => {
                                    if(type.find(t => t.name === event.target.value[event.target.value.length -1].name)){
                                        setType(type.filter(t => t.name !== event.target.value[event.target.value.length -1].name))
                                    }else{
                                        setType([...type, event.target.value[event.target.value.length -1]])
                                    }
                                }}
                                input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.map(s => s.name).join(', ')}
                                MenuProps={{
                                    style:{ 
                                        maxHeight: '400px',
                                    }
                                }}
                            >
                                <MenuItem 
                                    value={{
                                        id: 1,
                                        name: t('request.table.filter.pending'), 
                                        value: 1
                                    }}
                                >
                                    <Checkbox checked={type.map(t => t.value).includes(1)} />
                                    <ListItemText primary={t('request.table.filter.pending')} />
                                </MenuItem>

                                <MenuItem 
                                    value={{
                                        id: 2,
                                        name: t('request.table.filter.cancel'), 
                                        value: 2
                                    }}
                                >
                                    <Checkbox checked={type.map(t => t.value).includes(2)} />
                                    <ListItemText primary={t('request.table.filter.cancel')} />
                                </MenuItem>

                                <MenuItem 
                                    value={{
                                        id: 3,
                                        name: t('request.table.filter.approved'), 
                                        value: 3
                                    }}
                                >
                                    <Checkbox checked={type.map(t => t.value).includes(3)} />
                                    <ListItemText primary={t('request.table.filter.approved')} />
                                </MenuItem>

                                <MenuItem 
                                    value={{
                                        id: 7,
                                        name: t('request.table.filter.approved_with_errors'), 
                                        value: 7
                                    }}
                                >
                                    <Checkbox checked={type.map(t => t.value).includes(7)} />
                                    <ListItemText primary={t('request.table.filter.approved_with_errors')} />
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                        <LoadingButton
                            sx={{position: 'absolute', right: '15px', height: '56px'}} 
                            loading={loadingGettingSmartgroups || loadingGettingEmployees || loadingGettingRequests}
                            variant='contained'
                            disabled={range1[0] === null || range1[1] === null || smartgroup.length === 0 || employee.length === 0 || type.length === 0}
                            onClick={reloading}
                        >
                            {t('request.table.filter.search')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export { 
    FilterR
}