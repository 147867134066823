// REACT
import { useContext } from 'react'

// Libraries
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'

// Utils
import { GlobalContext } from '../GlobalContext'

// General
import { SignIn } from '../pages/SignIn'
import { Forbidden } from '../pages/Forbidden'

// Web
//import { Timecard as TimecardWeb } from '../pages/web/Timecard'

// Mobile
import { Employees as EmployeesMobile } from '../pages/mobile/Employees'
import { MSSO } from './MSS0'
import { ESSO } from './ESSO'


/* 
import { Reports } from '../pages/Reports'
import { Overtime } from '../pages/Overtime'
import { Schedule } from '../pages/Schedule'
import { Timecard } from '../pages/Timecard'
*/

const WebPaths = () => {

    return (
        <>
            <MSSO />
            <ESSO />
        </>
    )
}

const MobilePaths = () => {
    const { authenticated } = useContext(GlobalContext)

    return(
        <>
            <Router>
                <Routes>
                    <Route
                        path='/'
                        element={ authenticated ? 
                            <Navigate to={JSON.parse(sessionStorage.getItem('privileges')).employees.show_page ? '/employees' : '/forbidden'}/>
                            : 
                            <Navigate to={'/signin'}/> 
                        }
                        exact
                    />

                    <Route
                        path='/signin'
                        element={ authenticated ? <Navigate to={'/'}/> : <SignIn /> }
                        exact
                    />

                    <Route
                        path='/employees'
                        element={ authenticated ? <EmployeesMobile /> : <Navigate to={'/signin'}/> }
                        exact
                    />

                    <Route 
                        path='/forbidden' 
                        element={ authenticated ? <Forbidden /> : <Navigate to={'/signin'}/> }
                    />
                </Routes>
            </Router>
        </>
    )
}


export { 
    WebPaths,
    MobilePaths
}