import { instance } from "./session/axios"

const gettingRequests = async (
    setLoading,
    range,
    status_request_id,
    employees,
    setRows,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).requests.get_requests){
        setLoading(true)

        const body = {
            start_date: range[0].format('YYYY-MM-DD'),
            end_date: range[1].format('YYYY-MM-DD'),
            status_request_id: status_request_id.map(r => r.id), // pendiente = 1, cancelado = 2, aprobado = 3, aprobado con errores = 7
            employees: [...new Set(employees.map(employee => employee.id))], // ['18.986.316-0']
        }

        await instance.post('requests/multi_read', body)
        .then(response => {
            let rows = response?.data?.map(request => ({
                id: request.id,
                full_name: employees.find(e => e.id === request.person_number).full_name, // cambiar
                person_number: request.person_number,
                paycode: request.type_request.name,
                created_at: request.created_at,
                created_by: request.created_by?.name,
                start_date: request.start_date,
                end_date: request.end_date,
                employee_comment: request.employee_comment,
                manager_comment: request.manager_comment,
                manager: JSON.parse(sessionStorage.getItem('account'))?.username, // cambiar
                status: request.status_request.name,
                kronos_response: request.kronos_response,
            }))

            setRows(rows)
        })
        .catch(error => {
            console.log('error', error.message)
        })
        .finally(() => {
            setLoading(false)
        })
    }else{
        handlerAlert(
            'request.table.alerts.get_requests.title', 
            'request.table.alerts.get_requests.message',
            'error',
        )
    }
}

const approveRequest = async (
    setLoading,
    request_id,
    handlerAlert,
    closing,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).requests.approve_request){
        setLoading(true)

        const body = {
            status_request_id: 3,
        }

        await instance.patch(`requests/${request_id}`, body)
        .then(response => {
            closing()
        })
        .catch(error => {
            console.log('error', error.message)
        })
        .finally(() => {
            setLoading(false)
        })
    }else{
        handlerAlert(
            'request.table.alerts.approve_request.title', 
            'request.table.alerts.approve_request.message',
            'error',
        )
    }
}

const rejectRequest = async (
    setLoading,
    request_id,
    handlerAlert,
    closing,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).requests.reject_request){
        setLoading(true)

        const body = {
            status_request_id: 2,
        }

        await instance.patch(`requests/${request_id}`, body)
        .then(response => {
            closing()
        })
        .catch(error => {
            console.log('error', error.message)
        })
        .finally(() => {
            setLoading(false)
        })
    }else{
        handlerAlert(
            'request.table.alerts.reject_request.title', 
            'request.table.alerts.reject_request.message',
            'error',
        )
    }
}

const addRequest = async (
    setLoading,
    person_number,
    type_request_id,
    range,
    comment,
    //file,
    handlerAlert,
    reloading,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).requests.create_request){
        setLoading(true)
        const data = new FormData();

        data.append('object_add[person_number]', person_number)
        data.append('object_add[start_date]', range[0].format('YYYY-MM-DD'),)
        data.append('object_add[end_date]', range[1].format('YYYY-MM-DD'),)
        data.append('object_add[status_request_id]', 1)
        data.append('object_add[manager_comment]', comment)
        data.append('object_add[type_request_id]', type_request_id)
        //data.append('files[0]', null)


        await instance.post('requests', data)
        .then(response => {
            reloading()
        })
        .catch(error => {
            console.log('error', error.message)
        })
        .finally(() => {
            setLoading(false)
        })
    }else{
        handlerAlert(
            'request.table.alerts.create_request.title', 
            'request.table.alerts.create_request.message',
            'error',
        )
    }
}

const gettingPaycodes = async (
    setLoading,
    setPaycodes,
    handlerAlert
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).requests.get_types_request){
        setLoading(true)

        await instance.get('types_requests')
        .then(response => {
            setPaycodes(response?.data?.map(paycode => ({id: paycode.id, name: paycode.name})))
        })
        .catch(error => {
            console.log('error', error.message)
        })
        .finally(() => {
            setLoading(false)
        })
    }else{
        handlerAlert(
            'request.table.alerts.get_types_request.title', 
            'request.table.alerts.get_types_request.message',
            'error',
        )
    }
}

export {
    gettingPaycodes,
    gettingRequests,
    addRequest,
    approveRequest,
    rejectRequest,
}