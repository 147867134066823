import moment from "moment"
import { instance } from "./session/axios"

const gettingTimecard = async (
    setLoading,
    range,
    employees,
    setRows,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).overtime.get_timecard){
        setLoading(true)

        const body = {
            start_date: range[0].format('YYYY-MM-DD'),
            end_date: range[1].format('YYYY-MM-DD'),
            employees: [...new Set(employees.map(employee => employee.id))],
        }

        instance.post('timecard/multi_read', body)
        .then(response => {
            const rows = []

            for(let person_number of Object.keys(response.data.daily_totals)){
                for(let date of Object.keys(response.data.daily_totals[person_number])){
                    if(response.data?.daily_totals?.[person_number]?.[date]?.totals?.['HORAS POR APROBAR']){
                        rows.push({
                            id: `${person_number}-${date}`,
                            person_number: person_number,
                            full_name: employees.find(employee => employee.id === person_number)?.full_name,
                            date: date,
                            shifts: [],
                            punches: response.data?.punches?.[person_number]?.[date] ? response.data?.punches?.[person_number]?.[date] : [],
                            action: '',
                            comment: undefined,
                            notes: '',
                            partial_amount: '00:00',
                            amount: moment(response.data?.daily_totals?.[person_number]?.[date]?.totals?.['HORAS POR APROBAR']?.amount_in_time, ['HH:mm', 'H:mm']).format('HH:mm'),
                        })
                    }
                }
            }
            
            setRows(rows)
        })
        .catch(error => { 
            console.error(error.message)
            return []
        })
        .finally(() => { setLoading(false) })
    }else{
        handlerAlert(
            'overtime.table.alerts.get_schedule.title', 
            'overtime.table.alerts.get_schedule.message',
            'error',
        )
    }
}

const gettingSchedule = async (
    setLoading,
    range,
    employees,
    setRows,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).overtime.get_schedule){
        setLoading(true)

        const body = {
            start_date: range[0].format('YYYY-MM-DD'),
            end_date: range[1].format('YYYY-MM-DD'),
            employees: [...new Set(employees.map(employee => employee.id))],
        }

        await instance.post('schedule/multi_read', body)
        .then(response => {
            setRows(prev => {
                for(const row of prev){
                    row.shifts = response.data?.[row.person_number]?.['shifts']?.[row.date] ? response.data?.[row.person_number]?.['shifts']?.[row.date] : []
                }
                
                return prev
            })
            
        })
        .catch(error => { 
            console.error(error.message) 
        })
        .finally(() => { setLoading(false) })
    }else{
        handlerAlert(
            'overtime.table.alerts.get_schedule.title', 
            'overtime.table.alerts.get_schedule.message',
            'error',
        )
    }
}

const applyingOvertime = async (
    setLoading,
    overtimes,
    setRows,
    retry,
) => {
    setLoading(true)
    
    await instance.post('overtime/approve', {
        overtimes: overtimes.map(overtime => ({
            person_number: overtime.person_number,
            date: overtime.date,
            action: overtime.action,
            amount:  moment.duration(overtime.partial_amount).asHours(),
            comment: undefined,
            notes: undefined,
        }))
    })
    .then(response => {
        let rows = []

        for(let key of Object.keys(response.data)){
            rows.push({
                id: `${response?.data[key]?.overtime?.person_number}-${response?.data[key]?.overtime?.date}`,
                person_number: response?.data[key]?.overtime?.person_number,
                full_name: overtimes.find(overtime => overtime.person_number === response?.data[key]?.overtime?.person_number)?.full_name,
                date: response?.data[key]?.overtime?.date,
                result: response?.data[key]?.message,
                retry,
            })
        }

        setRows(rows)
    })
    .catch(error => {
        console.error(error.message) 
    })
    .finally(() => {
        setLoading(false)
    })
}

export {
    gettingTimecard,
    gettingSchedule,
    applyingOvertime,
}