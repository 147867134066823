import { Box, Collapse, IconButton, LinearProgress } from "@mui/material";
import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";
//import { FilterC } from "../../filter/FilterC";
import { memo, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterC } from "../../filter/FilterComon";
import { Context } from "./Context";
import RefreshIcon from '@mui/icons-material/Refresh';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ContextMenu } from "../../context_menu/ContextMenu";
import { getBorder } from "../../../utils/Styles";
import { Table as Audit } from "../audit/Table"
import moment from "moment";
import { EditPunchCell, PunchCell } from "./PunchCell";
import { ShiftCell } from "./ShiftCell";
import { ConfirmAddPunch } from "../../dialog/timecard/ConfirmAddPunch";
import { ExceptionCell } from "./ExceptionCell";
import { PaycodeCell, PaycodeEditCell } from "./PaycodeCell";
import { OvertimeCell } from "./OvertimeCell";
import { WorkedHoursCell } from "./WorkedHoursCell";
import { AddPaycodeHour } from "../../dialog/timecard/AddPaycodeHour";

import 'moment/locale/es';
import { CheckCell } from "./CheckCell";
import { CheckHeader } from "./CheckHeader";


const GroupingCol = (props) => {
    const { id, field, rowNode } = props;
    const { 
        apiRef, 
        periods,
        range1,
    } = useContext(Context)

    const handleClick = (event) => {
        if (rowNode.type !== 'group') {
            return;
        }

        let row_expanded = []

        if(rowNode.childrenExpanded){
            row_expanded = JSON.parse(sessionStorage.getItem('timecard'))?.cache?.row_expanded.filter(row => row !== rowNode.id)
        }else{
            row_expanded = [
                ...JSON.parse(sessionStorage.getItem('timecard'))?.cache?.row_expanded ? JSON.parse(sessionStorage.getItem('timecard'))?.cache?.row_expanded : [],
                rowNode.id
            ]
        }

        sessionStorage.setItem('timecard', JSON.stringify({
            environment:  JSON.parse(sessionStorage.getItem('timecard'))?.environment ? JSON.parse(sessionStorage.getItem('timecard')).environment : null,
            redirect:  JSON.parse(sessionStorage.getItem('timecard'))?.redirect ? JSON.parse(sessionStorage.getItem('timecard')).redirect : null,
            cache: {
                row_expanded,
            }
        }))
        
        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };

    const gettingOpacity = () => {
        const period = periods.find(period => period.full_name === props?.value)

        if(period){
            if(moment(period.end_date, 'YYYY-MM-DD').isSameOrAfter(moment(range1[1], 'YYYY-MM-DD'))){
                return 0.5
            }else{
                return 1
            }
        }else{
            return 1
        }
    }

    return (
        <Box 
            sx={{
                p: 0, 
                m: 0, 
                width: '100%'
            }}
        >
            <div>
                { rowNode.type === 'group' ?
                    <Box 
                        sx={{
                            ml: rowNode.depth * 4,
                            opacity: () =>  gettingOpacity(),
                        }}
                    >
                        <IconButton onClick={handleClick}>
                            { rowNode.childrenExpanded ?
                                <ExpandLessIcon />
                                :
                                <ExpandMoreIcon />
                            }
                        </IconButton>

                        { rowNode.groupingKey }
                    </Box>
                :
                    <center>
                        <Box
                            sx={{
                                opacity: props?.row?.open_period?.open === false ? 0.5 : 1,
                            }}
                        >                            
                            { moment(rowNode.groupingKey, 'YYYY-MM-DD')
                                .local(
                                    sessionStorage.getItem('language') ? 
                                        sessionStorage.getItem('language') 
                                        : 
                                        undefined
                                ).format('ddd DD-MM-YYYY')
                            }
                        </Box>
                    </center>
                }
            </div>
        </Box>
    );
}

const Dgrid = memo(({
    groupingColDef, 
    columns, 
    rows,
    checked, 
    setChecked, 
    open, 
    apiRef,
    loadingGettingTimecard,
    loadingGettingSchedule,
    loadingGettingAudit,
    loadingGettingOpenPeriod,
    reloading,
}) => {
    const {
        row, setRow,
        openConfirmAddPunch, setOpenConfirmAddPunch,
        openAddPaycode, setOpenAddPaycode
    } = useContext(Context)

    return(
        <>
            <ConfirmAddPunch 
                row={row}
                open={openConfirmAddPunch}
                setOpen={setOpenConfirmAddPunch}
                reloading={reloading}
            />

            <AddPaycodeHour 
                open={openAddPaycode}
                setOpen={setOpenAddPaycode}
            />
        
            <DataGridPro 
                treeData
                getTreeDataPath={(row) => row.path}
                groupingColDef={groupingColDef}
                showCellVerticalBorder
                showColumnVerticalBorder
                disableColumnMenu
                disableRowSelectionOnClick
                checkboxSelection
                rowSelection
                //unstable_headerFilters
                onCellEditStop={(params) => {
                    console.log('stop', params)

                    if(params.field === 'punch_in' || params.field === 'punch_out'){
                        if(params.row.new_punch){
                            setRow(params.row)
                            setOpenConfirmAddPunch(true)
                        }
                    }

                    if(params.field === 'paycodes'){
                        console.log('stop', params)
                    }
                }}
                loading={loadingGettingTimecard || loadingGettingSchedule || loadingGettingAudit || loadingGettingOpenPeriod}
                columns={columns}
                rows={useMemo(() => rows, [rows])}
                slots={{
                    //baseCheckbox: (props) => <CheckCell {...props} />,
                    toolbar: useCallback(() => <FilterC view={'timecard'} />, []),
                    loadingOverlay: LinearProgress,
                }}
                rowSelectionModel={checked}
                onRowSelectionModelChange={(rowsSelected) => {
                    setChecked(rowsSelected);
                    console.log('rows selcted: ', rowsSelected)
                }}
                sx={{
                    height: `calc(100vh - ${open ? '55vh' : '120px'})`,
                }}
                hideFooter
                apiRef={apiRef}
                getRowHeight={params => { 
                    const { model } = params

                    let length = 64
                

                    for(const key of Object.keys(model).filter(k => Array.isArray(model[k]))){
                        if(key !== 'path'){
                            let counter = 0

                            if(model[key].length > 0){
                                counter = counter + model[key].length * 47
                            }

                            if(counter > length){
                                length = counter
                            }
                        }
                    }

                    return length
                }}
                isGroupExpandedByDefault={useCallback((row) => {
                    if(loadingGettingTimecard || loadingGettingSchedule || loadingGettingAudit){
                        return false
                    }

                    if(JSON.parse(sessionStorage.getItem('timecard'))?.cache?.row_expanded){
                        return JSON.parse(sessionStorage.getItem('timecard'))?.cache?.row_expanded.find(row_expanded => row_expanded === row.id)
                    }

                    return false
                // eslint-disable-next-line
                }, [])}
                isCellEditable={(params) => params?.row?.open_period?.open}
            />
        </>
    )
})

const Table = () => {
    const { t } = useTranslation()

    const {
        /* loadingGettingSchedule,
        range1,
        
        smartgroup,
        employee, */
        checked,
        setChecked,
        loadingGettingTimecard,
        loadingGettingSchedule,
        loadingGettingAudit,
        loadingGettingOpenPeriod,
        apiRef,
        rows,
        reloading,
    } = useContext(Context)

    // eslint-disable-next-line
    
    const [contextMenu, setContextMenu] = useState(null)

    const [open, setOpen] = useState(false)

    const columns = useMemo(() => [
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            renderCell: (params) => <CheckCell params={params}/>,
            renderHeader: (params) => <CheckHeader params={params}/>,
        },
        { 
            field: 'overtime', 
            headerName: t('timecard.table.columns.overtimes'),
            flex: 1,
            minWidth: 120,
            editable: false,
            renderCell: (params) => <OvertimeCell params={params} />
        },
        { 
            field: 'shifts', 
            headerName: t('timecard.table.columns.shifts'),
            flex: 1,
            minWidth: 165,
            editable: false,
            renderCell: (params) => <ShiftCell params={params} />
        },
        { 
            field: 'punch_in', 
            headerName: t('timecard.table.columns.in_punches'),
            flex: 1,
            minWidth: 205,
            width: 205,
            editable: true,
            renderCell: (params) => <PunchCell params={params} />,
            renderEditCell: (params) => <EditPunchCell params={params} />,
        },
        { 
            field: 'punch_out', 
            headerName: t('timecard.table.columns.out_punches'),
            flex: 1,
            minWidth: 205,
            width: 205,
            editable: true,
            renderCell: (params) => <PunchCell params={params} />,
            renderEditCell: (params) => <EditPunchCell params={params} />,
        },
        { 
            field: 'exceptions', 
            headerName: t('timecard.table.columns.exceptions'),
            flex: 1,
            minWidth: 250,
            editable: false,
            renderCell: (params) => <ExceptionCell params={params} />,
        },
        { 
            field: 'paycodes', 
            headerName: t('timecard.table.columns.paycodes'),
            flex: 1,
            minWidth: 205,
            width: 205,
            editable: true,
            renderCell: (params) => <PaycodeCell params={params} />,
            renderEditCell: (params) => <PaycodeEditCell params={params} />,
        },
        { 
            field: 'worked_hours', 
            headerName: t('timecard.table.columns.worked_hours'),
            flex: 1,
            minWidth: 80,
            align: 'center',
            editable: false,
            renderCell: (params) => <WorkedHoursCell params={params} />,
        },
    ], [t])

    const groupingColDef = useMemo(() => (
        {
            headerName: t('timecard.table.columns.employees'),
            width: 355,
            renderCell: (params) => <GroupingCol {...params} {...apiRef} />,
        }
    ), [t, apiRef]);

    const options = useMemo(() => [
        {
            privilege: true,
            name: t(open ? 'timecard.table.menu.close_audit' : 'timecard.table.menu.open_audit'),
            click: () => {
                setContextMenu(null)
                setOpen(!open)
            },
            icon: <PendingActionsIcon />,
            divider: false,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: true,
            name: t('timecard.table.menu.reload'),
            click: () => {
                setContextMenu(null)
                reloading()
            },
            icon: <RefreshIcon />,
            divider: true,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
    ], [open, reloading, t])

    /* useEffect(() => {
        apiRef.current.setColumnIndex('check', 0)
        apiRef.current.setColumnIndex('__tree_data_group__', 1)
    }, [apiRef]) */

    return (
        <>
            <div
                onContextMenu={(event) => {
                    event.preventDefault();
                    setContextMenu(
                        contextMenu === null ? 
                            { 
                                mouseX: event.clientX + 2, 
                                mouseY: event.clientY - 6 
                            } 
                            : 
                            null
                    )
                }}
            >

                <Dgrid 
                    groupingColDef={groupingColDef}
                    columns={columns}
                    rows={rows}
                    open={open}
                    checked={checked}
                    setChecked={setChecked}
                    apiRef={apiRef}
                    loadingGettingTimecard={loadingGettingTimecard}
                    loadingGettingSchedule={loadingGettingSchedule}
                    loadingGettingAudit={loadingGettingAudit}
                    loadingGettingOpenPeriod={loadingGettingOpenPeriod}
                    editMode="row"
                    reloading={reloading}
                />

                <ContextMenu
                    contextMenu={contextMenu}
                    setContextMenu={setContextMenu}
                    options={options}
                    checked={checked}
                />
            </div>

            <Collapse 
                sx={{
                    height: '55vh',
                    pt: 5,
                    borderTop: (theme) => getBorder(theme.palette.mode),
                }} 
                in={open} 
                timeout="auto" 
                unmountOnExit
            >   
                <Audit view={'timecard'} />
            </Collapse>
        </>
    )
}

export { 
    Table
}