import { Box, CircularProgress, Grid, IconButton, Tooltip } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import FileOpenIcon from '@mui/icons-material/FileOpen';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import GridOnIcon from '@mui/icons-material/GridOn';
import { checkingStatus } from "../../../utils/Reports";
import { Context } from "./Context";
import { useTranslation } from "react-i18next";

const Cell = ({params}) => {
    const { t } = useTranslation()

    const { setRows } = useContext(Context)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(params?.value === false && loading === false) {
            checkingStatus(setLoading, params?.row?.id, setRows)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            { params?.value ?
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Tooltip title={t('reports.table.tooltips.watch_online')}>
                                <IconButton onClick={() => { window.open(params?.row?.url, '_blank') }}>
                                    <FileOpenIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        <Grid item xs={4}>
                            <Tooltip title={t('reports.table.tooltips.download_pdf')}>
                                <IconButton 
                                    onClick={() => { 
                                        const url = new URL(params?.row?.url)
                                        url.searchParams.set('format', 'pdf')
                                        
                                        window.open(url, '_blank') 
                                    }}
                                >
                                    <InsertDriveFileIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        <Grid item xs={4}>
                            <Tooltip title={t('reports.table.tooltips.download_xls')}>
                                <IconButton 
                                    onClick={() => { 
                                        const url = new URL(params?.row?.url)
                                        url.searchParams.set('format', 'xls')
                                        
                                        window.open(url, '_blank') 
                                    }}
                                >
                                    <GridOnIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </>
                :
                <IconButton disabled>
                    <CircularProgress size={20}/>
                </IconButton>
            }
        </>
    )
}

const renderCell = (params) => {
    return (
        <Box 
            sx={{
                width: '100%',
                display: 'grid',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
    
            <Cell params={params}/>
        </Box>
    )
}

export {
    renderCell
}