const saveReload  = (name, start, end, smartgroups, employees) => {
    const view = JSON.parse(sessionStorage.getItem(name))
    
    sessionStorage.setItem(name, JSON.stringify({
        reloading: {
            start,
            end,
            smartgroups,
            employees,
        },
        environment: view?.environment ? view.environment : null,
        redirect: view?.redirect ? view.redirect : null,
    }))
}

export {
    saveReload
}