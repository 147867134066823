import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Steps } from "../../steps/reports/Steps"
import { useContext, useEffect, useState } from "react"
import { gettingCategories, getttingSmartgroups } from "../../../utils/Reports"
import { GlobalContext } from "../../../GlobalContext"
import { ContextProvider } from "../../steps/reports/Context"


const AddReport = ({open, setOpen, reloading}) => {
    const { t } = useTranslation()
    const { handlerAlert } = useContext(GlobalContext)

    const [categories, setCategories] = useState([])
    const [reports, setReports] = useState([])
    const [employees, setEmployees] = useState([])

    const [loadingReports, setLoadingReports] = useState(false)
    const [loadingCategories, setLoadingCategories] = useState(false)
    const [loadingEmployees, setLoadingEmployees] = useState(false)
    const [loadingSmartgroups, setLoadingSmartgroups] = useState(false)

    useEffect(() => {
        if(open){
            gettingCategories(
                setLoadingCategories,
                setLoadingReports,
                setCategories,
                setReports,
                handlerAlert,
            )

            getttingSmartgroups(
                setLoadingSmartgroups,
                setLoadingEmployees,
                setEmployees,
                handlerAlert,
            )
        }
    // eslint-disable-next-line
    }, [open])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth='md'
                fullWidth
            >
                <DialogTitle
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Typography variant='body3'>{ t('reports.table.modals.add_report.title') }</Typography>
                </DialogTitle>

                <DialogContent 
                    dividers
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <ContextProvider>
                        <Steps 
                            categories={categories} 
                            reports={reports}
                            employees={employees}
                            loadingReports={loadingReports}
                            loadingCategories={loadingCategories}
                            loadingEmployees={loadingEmployees}
                            loadingSmartgroups={loadingSmartgroups}
                            reloading={reloading}
                            setOpen={setOpen}
                        />
                    </ContextProvider>
                </DialogContent>
            </Dialog>
        </>
    )
}

export { 
    AddReport
}