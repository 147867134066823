import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Autocomplete, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { addingDayPaycode, addingHourPaycode, gettingPaycodes } from '../../../utils/Schedule'
import { TimePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { SingleInputDateRangeField, StaticDateRangePicker } from '@mui/x-date-pickers-pro'
import { shortcuts } from '../../../utils/Dates'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { GlobalContext } from '../../../GlobalContext'
import { FileField } from '../../input/FileField'
import { Context } from '../../table/schedule/Context'

const AddPaycode = ({data, open, setOpen, checked, cell, setCell, reloading}) => {
    const { t } = useTranslation()

    const { 
        periods, 
    } = useContext(Context)

    const {
        handlerAlert,
    } = useContext(GlobalContext)
    
    const [loadingGettingPaycodes, setLoadingGettingPaycodes] = useState(false)
    const [paycodes, setPaycodes] = useState([])
    const [paycode, setPaycode] = useState(null)

    const [names, setNames] = useState([])
    const [start, setStart] = useState(moment(new Date().setHours(0,0,0,0)))
    const [amount, setAmount] = useState(moment(new Date().setHours(0,1,0,0)))
    const [type, setType] = useState('PCD')
    const [overwrite, setOverwrite] = useState(false)

    const [loadingAddingPaycode, setLoadingAddingPaycode] = useState(false)
    const [range, setRange] = useState([null, null])

    const [files, setFiles] = useState([])

    const closing = () => {
        setOpen(false)
        setLoadingGettingPaycodes(false)
        setLoadingAddingPaycode(false)
        setStart(moment(new Date().setHours(0,0,0,0)))
        setAmount(moment(new Date().setHours(0,1,0,0)))
        setPaycodes([])
        setPaycode(null)
        setRange([null, null])
        setFiles([])
        setOverwrite(false)
        setType('PCD')
        setCell({column: null, row: null})
    }

    const disableForPeriod = () => {
        if(moment(range[0]).isValid()){
            const employees = periods.filter(p => data.filter(d => names.includes(d.name)).map(d => d.id).includes(p.person_number))

            for(const employee of employees){
                if(moment(employee?.end_date, 'YYYY-MM-DD').add(1, 'days').isSameOrBefore(range[0])){
                    return false
                }
            }

            return true
        }else{
            return true
        }
    }

    useEffect(() => {
        if(open){
            gettingPaycodes(setLoadingGettingPaycodes, setPaycodes, handlerAlert)
            
            if(checked.length !== 0){
                setNames(checked.map(employee => employee.name))
            }else{
                setNames(cell?.row ? data.filter(employee => `${employee.id}` === cell?.row).map(employee => employee?.name) : [])
            }

            if(cell?.column){
                //console.log('cell', cell, 'moment', moment(cell?.column, 'YYYY-MM-DD'))
                setRange([moment(cell?.column, 'YYYY-MM-DD'), moment(cell?.column, 'YYYY-MM-DD')])
            }
        }
    }, [open, checked, handlerAlert, cell?.column, cell?.row, data])

    return (
        <>
            <Dialog
                fullWidth
                open={open} 
                onClose={() => { closing() }}
            >
                <DialogTitle
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Typography variant='body3'>{ t('schedule.table.modals.add_paycode.title') }</Typography>
                </DialogTitle>

                { periods.filter(p => data.filter(d => names.includes(d.name)).map(d => d.id).includes(p.person_number)).some(p => moment(p?.end_date, 'YYYY-MM-DD').isSameOrAfter(range[0])) &&//names.includes(period.full_name.slice(0, -15))).some(period => moment(period?.end_date, 'YYYY-MM-DD').isSameOrAfter(range[0])) && 
                    <DialogContent
                        sx={{
                            backgroundColor: (theme) => theme.palette.background.default,
                            borderBottom: 'none'
                        }}
                        dividers
                    >
                        <Grid container spacing={2}>
                            <Grid 
                                item 
                                xs={12}
                                sx={{
                                    mb: 2
                                }}
                            >
                                <Alert
                                    severity="warning"
                                    sx={{
                                        width: '100%',
                                        opacity: 1,
                                    }}
                                    variant="filled"
                                >
                                    <AlertTitle>{t('schedule.table.modals.add_paycode.alerts.title')}</AlertTitle>
                                    {t('schedule.table.modals.add_paycode.alerts.message')}
                                </Alert>
                            </Grid>
                        </Grid>
                    </DialogContent>
                }

                <DialogContent 
                    dividers
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                limitTags={5}
                                options={useMemo(() => data.map(employee => employee.name), [data])}
                                value={names}
                                onChange={(event, newValue) => { 
                                    setNames(newValue) 
                                    console.log(newValue)
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        { option }
                                    </li>
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            key={index}
                                            label={option}
                                            color={moment(periods.find(p => p?.person_number === data.find(d => d.name === option)?.id)?.end_date, 'YYYY-MM-DD').isSameOrAfter(range[0]) ? 'warning' : undefined} // Utiliza el color personalizado
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        label={t('schedule.table.modals.add_paycode.employees')}
                                    />
                                }
                                fullWidth
                            />
                        </Grid>
                        
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="l-type">{t('schedule.table.modals.add_paycode.type_of_paycode')}</InputLabel>

                                <Select
                                    labelId="l-type"
                                    id="type"
                                    value={type}
                                    label={t('schedule.table.modals.add_paycode.type_of_paycode')}
                                    onChange={(e) => { 
                                        setType(e.target.value)
                                        setPaycode(null)
                                        setAmount(moment(new Date().setHours(0,1,0,0)))
                                    }}
                                >
                                    { JSON.parse(sessionStorage.getItem('privileges')).schedule.add_paycode_hours && paycodes.filter(paycode => !paycode.is_days).length > 0 &&
                                        <MenuItem value={'PCH'}>{t('schedule.table.modals.add_paycode.paycode_of_hour')}</MenuItem>
                                    }

                                    { JSON.parse(sessionStorage.getItem('privileges')).schedule.add_paycode_days && paycodes.filter(paycode => paycode.is_days).length > 0 &&
                                        <MenuItem value={'PCD'}>{t('schedule.table.modals.add_paycode.paycode_of_day')}</MenuItem>
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <Autocomplete
                                loading={loadingGettingPaycodes}
                                disabled={loadingGettingPaycodes}
                                options={
                                    useMemo(() => type === 'PCD' 
                                        ? paycodes.filter(paycode => paycode.is_days).map(paycode => paycode.name) 
                                        : paycodes.filter(paycode => !paycode.is_days).map(paycode => paycode.name), 
                                        [paycodes, type]
                                    )
                                }
                                value={paycode}
                                onChange={(event, newValue) => { setPaycode(newValue) }}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        label={t('schedule.table.modals.add_paycode.paycode')} 
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loadingGettingPaycodes ? <CircularProgress color="inherit" size={20} /> : null}
                                                    { params.InputProps.endAdornment }
                                                </>
                                            ),
                                        }}
                                    />
                                }
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="l-override">{t('schedule.table.modals.add_paycode.overwrite')}</InputLabel>

                                <Select
                                    labelId="l-override"
                                    id="type"
                                    value={overwrite}
                                    label={t('schedule.table.modals.add_paycode.overwrite')}
                                    onChange={(e) => { 
                                        setOverwrite(e.target.value)
                                    }}
                                >
                                    <MenuItem value={false}>{t('schedule.table.modals.add_paycode.add_to_existing_ones')}</MenuItem>
                                    <MenuItem value={true}>{t('schedule.table.modals.add_paycode.overwrite_all')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <TimePicker
                                disabled={type === 'PCD'}
                                label={t('schedule.table.modals.add_paycode.amount')}
                                value={amount}
                                onChange={(nv) => {
                                    setAmount(nv)
                                }}
                                ampm={false}
                                minTime={moment(new Date().setHours(0,1,0,0))}
                                sx={{width: '100%'}}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TimePicker
                                label={t('schedule.table.modals.add_paycode.starts_at')}
                                value={start}
                                onChange={(nv) => setStart(nv)}
                                ampm={false}
                                sx={{width: '100%'}}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <StaticDateRangePicker
                                slots={{ field: SingleInputDateRangeField }}
                                slotProps={{
                                    shortcuts: {
                                        items: shortcuts(t),
                                    },
                                    actionBar: { actions: [] },
                                }}
                                calendars={1}
                                value={range}
                                onChange={(nv) => setRange(nv)}
                                sx={{
                                    border: '1px solid rgba(0,0,0,0.23)',
                                    borderRadius: 1,
                                }}
                                minDate={periods.filter(p => data.filter(d => names.includes(d.name)).map(d => d.id).includes(p.person_number)).map(p => moment(p.end_date, 'YYYY-MM-DD')).length > 0 ? periods.filter(p => data.filter(d => names.includes(d.name)).map(d => d.id).includes(p.person_number)).map(p => moment(p.end_date, 'YYYY-MM-DD')).reduce((earliest, current) => current.isSameOrBefore(earliest) ? current : earliest ).add(1, 'days') : undefined }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FileField 
                                files={files}
                                setFiles={setFiles}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Tooltip 
                        title={
                            (
                                names.length < 1 || 
                                paycode === null || 
                                amount.format('HH:mm') === '00:00' ||
                                range[0] === null || 
                                range[1] === null ||
                                disableForPeriod()
                            ) ||
                            ( JSON.parse(sessionStorage.getItem('privileges'))?.schedule?.paycode_requieres_file && files.length < 1 )
                            ? 
                                <>
                                    <List dense={true}>
                                        { names.length < 1 &&
                                            <ListItem>
                                                <ListItemText primary={t('schedule.table.modals.buttons.add.missing_employees')}/>
                                            </ListItem>
                                        }
                                        
                                        { paycode === null &&
                                            <ListItem>
                                                <ListItemText primary={t('schedule.table.modals.buttons.add.missing_paycode')}/>
                                            </ListItem>
                                        }
                                        
                                        { amount.format('HH:mm') === '00:00' &&
                                            <ListItem>
                                                <ListItemText primary={t('schedule.table.modals.buttons.add.missing_amount')}/>
                                            </ListItem>
                                        }

                                        { (range[0] === null || range[1] === null) &&
                                            <ListItem>
                                                <ListItemText primary={t('schedule.table.modals.buttons.add.missing_dates')}/>
                                            </ListItem>
                                        }

                                        { disableForPeriod() &&
                                            <ListItem>
                                                <ListItemText primary={t('schedule.table.modals.buttons.add.paycodes_outside_the_open_period')}/>
                                            </ListItem>
                                        }

                                        { JSON.parse(sessionStorage.getItem('privileges'))?.schedule?.paycode_requieres_file && files.length < 1 &&
                                            <ListItem>
                                                <ListItemText primary={t('schedule.table.modals.buttons.add.missing_files')}/>
                                            </ListItem>
                                        }
                                    </List>
                                </>
                            : ''
                        }
                    >
                        <span>
                            <LoadingButton 
                                variant='contained'
                                disableElevation 
                                onClick={() => {
                                    if(type === 'PCH'){
                                        addingHourPaycode(
                                            setLoadingAddingPaycode,
                                            data.filter(d => names.includes(d.name)),
                                            range,
                                            paycode,
                                            start,
                                            amount,
                                            overwrite,
                                            files,
                                            periods,
                                            closing,
                                            reloading,
                                            handlerAlert
                                        )
                                    }else{
                                        addingDayPaycode(
                                            setLoadingAddingPaycode,
                                            data.filter(d => names.includes(d.name)),
                                            range,
                                            paycode,
                                            overwrite,
                                            files,
                                            periods,
                                            closing,
                                            reloading,
                                            handlerAlert
                                        )
                                    }
                                        
                                }} 
                                loading={loadingAddingPaycode}
                                disabled={
                                    (
                                        names.length < 1 || 
                                        paycode === null || 
                                        amount.format('HH:mm') === '00:00' ||
                                        range[0] === null || 
                                        range[1] === null ||
                                        disableForPeriod()
                                    ) ||
                                    ( JSON.parse(sessionStorage.getItem('privileges'))?.schedule?.paycode_requieres_file && files.length < 1 )
                                }
                            >
                                {t('schedule.table.modals.buttons.add.title')}
                            </LoadingButton>
                        </span>
                    </Tooltip>

                    <Button 
                        variant='contained' 
                        disableElevation 
                        color='error' 
                        onClick={() => { closing() }}
                        sx={{mr: 1.5}}
                    >
                        {t('schedule.table.modals.buttons.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export { 
    AddPaycode
}