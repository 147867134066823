import { Avatar, Box, Collapse, LinearProgress } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContextMenu } from "../../context_menu/ContextMenu";
import { FilterR } from "../../filter/FilterRequest";
import { Context } from "./Context";
import { getBorder, stringToColor } from "../../../utils/Styles";
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import DescriptionIcon from '@mui/icons-material/Description';
import { ApproveRequest } from "../../dialog/request/ApproveRequest";
import { RejectRequest } from "../../dialog/request/RejectRequest";
import { AddRequest } from "../../dialog/request/AddRequest";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { Table as Audit } from "../audit/Table"
import { InfoRequest } from "../../dialog/request/InfoRequest";
import { DetailRequest } from "../../dialog/request/DetailRequest";

const Table = () => {
    const { t } = useTranslation()

    const { 
        rows,
        employee,
        loadingGettingRequests,
        reloading,
        paycodes,
    } = useContext(Context)

    const [checked, setChecked] = useState([])
    const [contextMenu, setContextMenu] = useState(null)
    const [openApprove, setOpenApprove] = useState(false)
    const [openReject, setOpenReject] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [openInfo, setOpenInfo] = useState(false)
    const [openDetail, setOpenDetail] = useState(false)
    const [open, setOpen] = useState(false)

    const columns = useMemo(() => [
        {
            field: 'avatar',
            headerName: t('request.table.columns.avatar'),
            width: 60,
            minWidth: 60,
            renderCell: (params) => {
                return (
                    <Box sx={{pt: 1, pb: 1}}>
                        <Avatar sx={{ bgcolor: stringToColor(params.row.full_name) }}>
                            {params.row.full_name.charAt(0)}
                        </Avatar>
                    </Box>
                )
            }
        },
        {
            field: 'full_name',
            headerName: t('request.table.columns.full_name'),
            flex: 2,
        },
        {
            field: 'person_number',
            headerName: t('request.table.columns.person_number'),
            flex: 1,
        },
        {
            field: 'paycode',
            headerName: t('request.table.columns.paycode'),
            flex: 1,
        },
        {
            field: 'created_at',
            headerName: t('request.table.columns.created_at'),
            flex: 1,
        },
        {
            field: 'start_date',
            headerName: t('request.table.columns.start_date'),
            flex: 1,
        },
        {
            field: 'end_date',
            headerName: t('request.table.columns.end_date'),
            flex: 1,
        },
        {
            field: 'manager',
            headerName: t('request.table.columns.manager'),
            flex: 1,
        },
        {
            field: 'status',
            headerName: t('request.table.columns.status'),
            flex: 1,
        },
    ], [t])

    const options = useMemo(() => [
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).requests.create_request,
            name: t('request.table.menu.add'),
            click: () => {
                setContextMenu(null)
                setOpenAdd(true)
            },
            icon: <AddIcon />,
            divider: false,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: 
                <AddRequest 
                    reloading={reloading} 
                    data={employee.map(e => ({ id: e.id, name: e.full_name }))}
                    paycodes={paycodes}
                    checked={checked} 
                    open={openAdd} 
                    setOpen={setOpenAdd}
                />,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).requests.approve_request,
            name: t('request.table.menu.approve'),
            click: () => {
                setContextMenu(null)
                setOpenApprove(true)
            },
            icon: <CheckCircleOutlineIcon />,
            divider: false,
            disabled: checked.length === 0,
            seleted: true,
            onlyOne: false,
            dialog: <ApproveRequest reloading={reloading} checked={checked} open={openApprove} setOpen={setOpenApprove} />,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).requests.reject_request,
            name: t('request.table.menu.reject'),
            click: () => {
                setContextMenu(null)
                setOpenReject(true)
            },
            icon: <RemoveCircleOutlineIcon />,
            divider: false,
            disabled: checked.length === 0,
            seleted: true,
            onlyOne: false,
            dialog: <RejectRequest reloading={reloading} checked={checked} open={openReject} setOpen={setOpenReject} />,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).requests.get_info_kronos_status,
            name: t('request.table.menu.info'),
            click: () => {
                setContextMenu(null)
                setOpenInfo(true)
            },
            icon: <InfoIcon />,
            divider: false,
            disabled: checked.length !== 1 || rows[checked[0]]?.kronos_response === null,
            seleted: false,
            onlyOne: true,
            dialog: 
                <InfoRequest 
                    data={rows.find(row => row.id === checked[0])} 
                    open={openInfo} 
                    setOpen={setOpenInfo} 
                />,
            },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).requests.get_info_kronos_status,
            name: t('request.table.menu.detail'),
            click: () => {
                setContextMenu(null)
                setOpenDetail(true)
            },
            icon: <DescriptionIcon />,
            divider: false,
            disabled: checked.length !== 1,
            seleted: false,
            onlyOne: true,
            dialog: 
                <DetailRequest 
                    data={rows.find(row => row.id === checked[0])}
                    paycodes={paycodes}
                    open={openDetail} 
                    setOpen={setOpenDetail}
                />,
        },
        {
            privilege: true,
            name: t(open ? 'employees.employee_table.menu.close_audit' : 'employees.employee_table.menu.open_audit'),
            click: () => {
                setContextMenu(null)
                setOpen(!open)
            },
            icon: <PendingActionsIcon />,
            divider: 
                JSON.parse(sessionStorage.getItem('privileges')).requests.create_request ||
                JSON.parse(sessionStorage.getItem('privileges')).requests.approve_request || 
                JSON.parse(sessionStorage.getItem('privileges')).requests.reject_request ||
                JSON.parse(sessionStorage.getItem('privileges')).requests.get_info_kronos_status ||
                JSON.parse(sessionStorage.getItem('privileges')).requests.get_request,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: true,
            name: t('request.table.menu.reload'),
            click: () => {
                setContextMenu(null)
                reloading()
            },
            icon: <RefreshIcon />,
            divider: JSON.parse(sessionStorage.getItem('privileges')).reports.execute_report,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
    ], [checked, employee, open, openAdd, openApprove, openDetail, openInfo, openReject, paycodes, reloading, rows, t])
    

    return (
        <>
            <div
                onContextMenu={(event) => {
                    event.preventDefault();
                    setContextMenu(
                        contextMenu === null ? 
                            { 
                                mouseX: event.clientX + 2, 
                                mouseY: event.clientY - 6 
                            } 
                            : 
                            null
                    )
                }}
            >
                <DataGridPro 
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    //unstable_headerFilters
                    checkboxSelection
                    loading={loadingGettingRequests}
                    columns={columns}
                    rows={rows}
                    slots={{
                        toolbar: useCallback(() => <FilterR view={'request'} />, []),
                        loadingOverlay: LinearProgress,
                    }}
                    rowSelectionModel={checked}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setChecked(newRowSelectionModel);
                    }}
                    sx={{
                        height: `calc(100vh - ${open ? '55vh' : '120px'})`,
                    }}
                    hideFooter
                />
                
                <ContextMenu
                    contextMenu={contextMenu}
                    setContextMenu={setContextMenu}
                    options={options}
                    checked={checked}
                />
            </div>

            <Collapse 
                sx={{
                    height: '55vh', 
                    mt: 2.5,
                    ml: -2.5, 
                    mr: -2.5,
                    p: 2.5,
                    borderTop: (theme) => getBorder(theme.palette.mode),
                }} 
                in={open} 
                timeout="auto" 
                unmountOnExit
            >   
                <Audit view={'request'} />
            </Collapse>
        </>
    )
}

export { Table }