import { instance } from "./session/axios"

const gettingReports = async (
    setLoading,
    setRows,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).reports.get_results){
        setLoading(true)

        await instance.get('reports/results')
        .then(response => {
            let rows = response?.data?.map(report => ({
                id: report.id,
                report: report.name,
                created_at: report.created_at,
                url: report.url,
                preview: false
            }))

            setRows(rows)
        })
        .catch(error => {
            console.log('error', error.message)
        })
        .finally(() => {
            setLoading(false)
        })
    }else{
        handlerAlert(
            'reports.table.alerts.get_results.title', 
            'reports.table.alerts.get_results.message',
            'error',
        )
    }
}

const checkingStatus = async (
    setLoading,
    report_id,
    setRows,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).reports.get_check_status_report){
        setLoading(true)

        await instance.get(`reports/status/${report_id}`)
        .then(response => {
            if(response.data?.status === "Complete"){
                setRows(prev => {
                    let rows = prev.map(row => {
                        if(row.id === report_id){
                            return {
                                ...row,
                                preview: true,
                            }
                        }else{
                            return row
                        }
                    })
    
                    return rows
                })
            }else{
                checkingStatus(setLoading, report_id, setRows, handlerAlert)
            }
        })
        .catch(error => {
            console.log('error', error.message)
        })
        .finally(() => {
            setLoading(false)
        })
    }else{
        handlerAlert(
            'reports.table.alerts.get_status_report.title', 
            'reports.table.alerts.get_status_report.message',
            'error',
        )
    }
}

const gettingCategories = async (
    setLoadingCategories,
    setLoadingReports,
    setCategories,
    setReports,
    handlerAlert
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).reports.get_category_reports){
        setLoadingCategories(true)

        await instance.get('reports/category')
        .then(response => {
            setCategories(response?.data)

            for(const category of response?.data){
                gettingCategory(setReports, category?.id, handlerAlert)
            }

            setLoadingReports(false)
        })
        .catch(error => {
            console.log('error', error.message)
        })
        .finally(() => {
            setLoadingCategories(false)
        })
    }else{
        handlerAlert(
            'reports.table.alerts.get_category_reports.title', 
            'reports.table.alerts.get_category_reports.message',
            'error',
        )
    }
}

const gettingCategory = async (
    setReports,
    category_id,
    handlerAlert
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).reports.get_reports){
        await instance.get(`reports?category_id=${category_id}`)
        .then(response => {
            setReports(prev => prev.concat(response.data).filter((obj, index, self) => index === self.findIndex(o => o.slug === obj.slug)))
        })
        .catch(error => {
            console.log('error', error.message)
        })
    }else{
        handlerAlert(
            'reports.table.alerts.get_reports_by_category.title', 
            'reports.table.alerts.get_reports_by_category.message',
            'error',
        )
    }
}
const getttingSmartgroups = async (
    setLoadingSmartgroups,
    setLoadingEmployees,
    setEmployees,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).reports.get_smart_groups){
        await instance.get('smart_groups')
        .then(response => { 
            const smartgroups = response.data.map((sg) => ({ id: sg.id, name: sg.name }))
            
            gettingEmployees(setLoadingEmployees, smartgroups, setEmployees, handlerAlert);
        })
        .catch(error => { 
            console.error(error.message) 
        })
        .finally(() => { setLoadingSmartgroups(false) })
    }else{
         handlerAlert(
            'reports.table.alerts.get_smart_groups.title', 
            'reports.table.alerts.get_smart_groups.message',
            'error',
        )
    }
}

const gettingEmployees = async (
    setLoadingEmployees,
    smartgroups,
    setEmployees,
    handlerAlert
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).reports.get_employees){
        const promises = smartgroups.map(sg => instance.get(`smart_groups/${sg.id}`))

        await Promise.all(promises)
        .then(responses => {
            let employees = []
            
            for(const [index, response] of responses.entries()){
                if(typeof response.data === 'object'){
                    for (const person_number of Object.keys(response.data)){
                        const exists = employees.find(employee => employee.id === person_number)
                        
                        if(exists){
                            exists.smartgroups.push(smartgroups[index].name)
                            
                        }else{
                            const orgpaths = response.data[person_number].labor_accounts;

                            employees.push({
                                id: person_number,
                                person_number,
                                full_name: response.data[person_number].person_data.full_name,
                                orgpath: orgpaths.length > 0 ? orgpaths[orgpaths.length - 1].labor_account_description : null,
                                smartgroups: [smartgroups[index].name],
                            })
                        }
                    }
                }
            }

            console.log('employees', employees)
            setEmployees(employees)
        })
        .catch(error => { 
            console.error(error.message)
        })
        .finally(() => { setLoadingEmployees(false) })
    }else{
        handlerAlert(
            'reports.table.alerts.get_employees.title', 
            'reports.table.alerts.get_employees.message',
            'error',
        )
    }
}

const execiteReport = async (
    setLoading,
    employees,
    reports,
    range,
    reloading,
    setOpen,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).reports.execute_report){
        setLoading(true)

        const promises = reports.map(report => {
            const body = {
                slug_report: report,
                employees: employees,
                start_date: range[0].format('YYYY-MM-DD'),
                end_date: range[1].format('YYYY-MM-DD'),
            }

            return instance.post('reports/execute', body)
        })

        await Promise.all(promises)
        .then(() => {
            reloading()
            setOpen(false)
        })
        .catch(error => {
            console.error(error.message)
        })
        .finally(() => {
            setLoading(false)
        })
    }else{
        handlerAlert(
            'reports.table.alerts.execute_report.title', 
            'reports.table.alerts.execute_report.message',
            'error',
        )
    }
}

export {
    gettingReports,
    checkingStatus,
    gettingCategories,
    gettingCategory,
    getttingSmartgroups,
    gettingEmployees,
    execiteReport,
}