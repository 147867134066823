import { Alert, AlertTitle, Autocomplete, Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material"
import { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import moment from "moment";
import { TimePicker } from "@mui/x-date-pickers";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { getBorder } from "../../../utils/Styles";
import { LoadingButton} from "@mui/lab";
import { SingleInputDateRangeField, StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { shortcuts } from "../../../utils/Dates";
import { addingShift } from "../../../utils/Schedule";
import { GlobalContext } from "../../../GlobalContext";
import { Context } from "../../table/schedule/Context";

const Toolbar = (props) => {
    const { t } = useTranslation()
    const { rows, setRows } = props

    return (
        <GridToolbarContainer sx={{borderBottom: (theme) => getBorder(theme.palette.mode)}}>
            <Button
                color="primary" 
                startIcon={<AddIcon />}
                variant="contained"
                disableElevation
                sx={{m: 1}}
                onClick={() => { 
                    setRows(
                        [...rows, { 
                            id: moment(new Date()).valueOf(), 
                            type: 'Regular',
                            start: moment('00:00', 'HH:mm'),
                            end: moment('00:00', 'HH:mm')
                        }]
                    )
                }}
            >
                { t('schedule.table.modals.add_shift.table.columns.new_row') }
            </Button>
        </GridToolbarContainer>
    )
}

const SelectField = ({params, sx}) => {
    const { t } = useTranslation()

    const [type, setType] = useState(params.value)

    return(
        <>
            <FormControl sx={sx} fullWidth>
                <InputLabel id={'l-type'}>{t('schedule.table.modals.add_shift.table.columns.type')}</InputLabel>

                <Select
                    labelId="l-type"
                    id="type"
                    value={type}
                    label={t('schedule.table.modals.add_shift.table.columns.type')}
                    onChange={(event) => setType(event.target.value)}
                >
                    <MenuItem value={'Regular'}>Regular</MenuItem>
                    <MenuItem value={'Break'}>Break</MenuItem>
                </Select>
            </FormControl>
        </>
    )
}

const TimeField = ({params, rows, setRows, sx}) => {
    const { t } = useTranslation()
    const [time, setTime] = useState(params.value)

    return (
        <>
            <TimePicker
                label={t('schedule.table.modals.add_shift.table.columns.hour')}
                value={time}
                onChange={(nv) => {
                    setTime(nv)
                    setRows(rows.map(row => row.id === params.row.id ? {...row, [params.field]: nv} : row))
                }}
                ampm={false}
                sx={sx}
                fullWidth
            />
        </>
    )
}

const ActionField = ({
    params, 
    sx, 
    rows, 
    setRows
}) => {

    return (
        <>
            <Box 
                display={'grid'} 
                sx={{
                    width: '100%', 
                    placeItems: 'center'
                }}
            >
                <IconButton 
                    color="error" 
                    onClick={() => {
                        setRows(rows.filter(row => row.id !== params.row.id))
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            </Box>
        </>
    )
}

const AddShift = ({data, open, setOpen, checked, cell, setCell, reloading}) => {
    const { t } = useTranslation()

    const { 
        handlerAlert 
    } = useContext(GlobalContext)

    const {
        periods
    } = useContext(Context)


    const [names, setNames] = useState([])
    const [range, setRange] = useState([null, null])
    const [loadingAddingShift, setLoadingAddingShift] = useState(false)

    const closing = () => {
        setOpen(false)
        setNames([])
        setRange([null, null])
        setLoadingAddingShift(false)
        setCell({row: null, column: null})
        setRows([
            {
                id: moment(new Date()).valueOf(),
                type: 'Regular',
                start: moment('00:00', 'HH:mm'),
                end: moment('00:00', 'HH:mm')
            },
        ])
    }

    const disableForPeriod = () => {
        if(moment(range[0]).isValid()){
            const employees = periods.filter(p => data.filter(d => names.includes(d.name)).map(d => d.id).includes(p.person_number))

            for(const employee of employees){
                if(moment(employee?.end_date, 'YYYY-MM-DD').add(1, 'days').isSameOrBefore(range[0])){
                    return false
                }
            }

            return true
        }else{
            return true
        }
    }

    const columns = [
        {
            field: 'start',
            headerName: t('schedule.table.modals.add_shift.table.columns.start'),
            flex: 1,
            sortable: false,
            renderCell: (params) => <TimeField params={params} rows={rows} setRows={setRows} sx={{ ml: 1, mr: 0.25, mt: 2, mb: 2, width: '100%'}}/>
        },
        {
            field: 'end',
            headerName: t('schedule.table.modals.add_shift.table.columns.end'),
            flex: 1,
            sortable: false,
            renderCell: (params) => <TimeField params={params} rows={rows} setRows={setRows} sx={{ ml: 0.25, mr: 0.25, mt: 2, mb: 2, width: '100%'}}/>
        },
        {
            field: 'type',
            headerName: t('schedule.table.modals.add_shift.table.columns.type'),
            flex: 1,
            sortable: false,
            renderCell: (params) => <SelectField params={params} sx={{ ml: 0.25, mr: 1, mt: 2, mb: 2, width: '100%'}}/>
        },
        {
            field: 'actions',
            headerName: t('schedule.table.modals.add_shift.table.columns.action'),
            flex: 0.5,
            sortable: false,
            renderCell: (params) => <ActionField params={params} rows={rows} setRows={setRows}/>
        }
    ]

    const [rows, setRows] = useState([
        {
            id: moment(new Date()).valueOf(),
            type: 'Regular',
            start: moment('00:00', 'HH:mm'),
            end: moment('00:00', 'HH:mm')
        },
    ])

    useEffect(() => {
        if(open){
            if(checked.length !== 0){
                setNames(checked.map(employee => employee.name))
            }else{
                setNames(cell?.row ? data.filter(employee => `${employee.id}` === cell?.row).map(employee => employee?.name) : [])
            }

            if(cell?.column){
                setRange([moment(cell?.column, 'YYYY-MM-DD'), moment(cell?.column, 'YYYY-MM-DD')])
            }
        }
    }, [cell?.column, cell?.row, checked, data, open])

    return (
        <>
            <Dialog
                fullWidth
                open={open}
                onClose={() => { closing() }}
            >
                <DialogTitle
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Typography variant='body3'>{ t('schedule.table.modals.add_shift.title') }</Typography>
                </DialogTitle>
                
                { periods.filter(p => data.filter(d => names.includes(d.name)).map(d => d.id).includes(p.person_number)).some(p => moment(p?.end_date, 'YYYY-MM-DD').isSameOrAfter(range[0])) &&//names.includes(period.full_name.slice(0, -15))).some(period => moment(period?.end_date, 'YYYY-MM-DD').isSameOrAfter(range[0])) && 
                    <DialogContent
                        sx={{
                            backgroundColor: (theme) => theme.palette.background.default,
                            borderBottom: 'none'
                        }}
                        dividers
                    >
                        <Grid container spacing={2}>
                            <Grid 
                                item 
                                xs={12}
                                sx={{
                                    mb: 2
                                }}
                            >
                                <Alert
                                    severity="warning"
                                    sx={{
                                        width: '100%',
                                        opacity: 1,
                                    }}
                                    variant="filled"
                                >
                                    <AlertTitle>{t('schedule.table.modals.add_shift.alerts.title')}</AlertTitle>
                                    {t('schedule.table.modals.add_shift.alerts.message')}
                                </Alert>
                            </Grid>
                        </Grid>
                    </DialogContent>
                }

                <DialogContent 
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }} 
                    dividers
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                limitTags={5}
                                options={useMemo(() => data.map(employee => employee.name), [data])}
                                value={names}
                                onChange={(event, newValue) => { 
                                    setNames(newValue)
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        { option }
                                    </li>
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            key={index}
                                            label={option}
                                            color={moment(periods.find(p => p?.person_number === data.find(d => d.name === option)?.id)?.end_date, 'YYYY-MM-DD').isSameOrAfter(range[0]) ? 'warning' : undefined} // Utiliza el color personalizado
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        label={t('schedule.table.modals.add_shift.employees')}
                                    />
                                }
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <StaticDateRangePicker
                                slots={{ field: SingleInputDateRangeField }}
                                slotProps={{
                                    shortcuts: {
                                        items: shortcuts(t),
                                    },
                                    actionBar: { actions: [] },
                                }}
                                calendars={1}
                                value={range}
                                onChange={(nv) => setRange(nv)}
                                sx={{
                                    border: '1px solid rgba(0,0,0,0.23)',
                                    borderRadius: 1,
                                    width: '100%',
                                }}
                                minDate={periods.filter(p => data.filter(d => names.includes(d.name)).map(d => d.id).includes(p.person_number)).map(p => moment(p.end_date, 'YYYY-MM-DD')).length > 0 ? periods.filter(p => data.filter(d => names.includes(d.name)).map(d => d.id).includes(p.person_number)).map(p => moment(p.end_date, 'YYYY-MM-DD')).reduce((earliest, current) => current.isSameOrBefore(earliest) ? current : earliest ).add(1, 'days') : undefined }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DataGrid
                                rows={rows}
                                columns={JSON.parse(sessionStorage.getItem('privileges'))?.schedule?.one_segement ? columns.filter(column => column.field !== 'actions' ) : columns}
                                getRowHeight={() => 'auto'}
                                showCellVerticalBorder
                                showColumnVerticalBorder
                                disableColumnSorting
                                disableColumnMenu
                                slots={{
                                    toolbar: JSON.parse(sessionStorage.getItem('privileges'))?.schedule?.one_segement ? () => <></> : Toolbar,
                                    footer: () => { return(<></>) }
                                }}
                                slotProps={{
                                    toolbar: { rows, setRows }
                                }}
                                sx={{
                                    width: '100%',
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Tooltip title={
                        names.length < 1 ||
                        rows.length < 1 ||
                        range[0] === null || 
                        range[1] === null ||
                        disableForPeriod()
                        ?
                            <>
                                <List dense={true}>
                                    { names.length < 1 &&
                                        <ListItem>
                                            <ListItemText primary={t('schedule.table.modals.buttons.add.missing_employees')}/>
                                        </ListItem>
                                    }

                                    { (range[0] === null || range[1] === null) &&
                                        <ListItem>
                                            <ListItemText primary={t('schedule.table.modals.buttons.add.missing_dates')}/>
                                        </ListItem>
                                    }

                                    { rows.length < 1 &&
                                        <ListItem>
                                            <ListItemText primary={t('schedule.table.modals.buttons.add.missing_rows')}/>
                                        </ListItem>
                                    }

                                    { disableForPeriod() &&
                                        <ListItem>
                                            <ListItemText primary={t('schedule.table.modals.buttons.add.shifts_outside_the_open_period')}/>
                                        </ListItem>
                                    }
                                </List>
                            </>
                            : 
                            ''
                    }>
                        <span>
                            <LoadingButton 
                                variant='contained'
                                disableElevation 
                                onClick={() => {
                                    addingShift(
                                        setLoadingAddingShift,
                                        data.filter(d => names.includes(d.name)),
                                        range,
                                        rows,
                                        periods,
                                        closing,
                                        reloading,
                                        handlerAlert
                                    )
                                }} 
                                loading={loadingAddingShift}
                                disabled={
                                    names.length < 1 ||
                                    rows.length < 1 ||
                                    range[0] === null || 
                                    range[1] === null ||
                                    disableForPeriod()
                                }
                            >
                                {t('schedule.table.modals.buttons.add.title')}
                            </LoadingButton>
                        </span>
                    </Tooltip>

                    <Button 
                        variant='contained' 
                        disableElevation 
                        color='error' 
                        onClick={() => { closing() }}
                        sx={{mr: 1.5}}
                    >
                        {t('schedule.table.modals.buttons.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export { 
    AddShift 
}