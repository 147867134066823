import React, { useContext } from 'react'
import { Drawer, DrawerHeaderLeft } from './Appbar'
import { Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { GlobalContext } from '../../GlobalContext'
import GroupsIcon from '@mui/icons-material/Groups'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Settings } from './Settings'
import DescriptionIcon from '@mui/icons-material/Description';
import AllInboxIcon from '@mui/icons-material/AllInbox';


const Left = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const { 
        openLeft,
        handlerSideLeft,
    } = useContext(GlobalContext)

    return (
        <Drawer 
            variant="permanent" 
            open={openLeft}
            //onMouseEnter={() => handlerSideLeft(true)}
            //onMouseLeave={() => handlerSideLeft(false)}
        >
            <DrawerHeaderLeft>
                { openLeft ? 
                    <>
                        <img
                            src={JSON.parse(sessionStorage.getItem('images'))?.logo}
                            alt='logo-app'
                            style={{
                                position: 'absolute', 
                                left: 10, 
                                margin: 10, 
                                width: "100px"
                            }}
                        />

                        <IconButton onClick={() => handlerSideLeft(!openLeft)}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </>
                    :
                    null
                }
            </DrawerHeaderLeft>

            <Divider />

            <List>
                { JSON.parse(sessionStorage.getItem('privileges'))?.employees?.show_page &&
                    <ListItemButton
                        sx={{ pl: 2.5 }}
                        selected={location.pathname === '/'} 
                        onClick={() =>{ navigate('/employees');}}
                    >
                        <ListItemIcon>
                            <GroupsIcon />
                        </ListItemIcon>

                        <ListItemText primary={t('employees.title')} />
                    </ListItemButton>
                }

                { JSON.parse(sessionStorage.getItem('privileges'))?.schedule?.show_page &&
                    <ListItemButton
                        sx={{ pl: 2.5 }}
                        selected={location.pathname === '/schedule'} 
                        onClick={() =>navigate('/schedule')}
                    >
                        <ListItemIcon>
                            <CalendarMonthIcon />
                        </ListItemIcon>

                        <ListItemText primary={t('schedule.title')} />
                    </ListItemButton>
                }

                { JSON.parse(sessionStorage.getItem('privileges'))?.timecard?.show_page &&
                    <ListItemButton
                        sx={{ pl: 2.5 }}
                        selected={location.pathname === '/timecard'} 
                        onClick={() =>navigate('/timecard')}
                    >
                        <ListItemIcon>
                            <PunchClockIcon />
                        </ListItemIcon>

                        <ListItemText primary={t('timecard.title')} />
                    </ListItemButton>
                }

                { JSON.parse(sessionStorage.getItem('privileges'))?.overtime?.show_page &&
                    <ListItemButton
                        sx={{ pl: 2.5 }}
                        selected={location.pathname === '/overtime'} 
                        onClick={() =>navigate('/overtime')}
                    >
                        <ListItemIcon>
                            <MoreTimeIcon />
                        </ListItemIcon>

                        <ListItemText primary={t('overtime.title')} />
                    </ListItemButton>
                }

                { JSON.parse(sessionStorage.getItem('privileges'))?.requests?.show_page &&
                    <ListItemButton
                        sx={{ pl: 2.5 }}
                        selected={location.pathname === '/requests'} 
                        onClick={() =>navigate('/requests')}
                    >
                        <ListItemIcon>
                            <AllInboxIcon />
                        </ListItemIcon>

                        <ListItemText primary={t('request.title')} />
                    </ListItemButton>
                }

                { JSON.parse(sessionStorage.getItem('privileges'))?.reports?.show_page &&
                    <ListItemButton
                        sx={{ pl: 2.5 }}
                        selected={location.pathname === '/reports'} 
                        onClick={() =>navigate('/reports')}
                    >
                        <ListItemIcon>
                            <DescriptionIcon />
                        </ListItemIcon>

                        <ListItemText primary={t('reports.title')} />
                    </ListItemButton>
                }
            </List>

            <Settings openLeft={openLeft} />   
        </Drawer>
    )
}

export {
    Left
}