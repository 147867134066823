import { Box, Button, LinearProgress, Step, StepContent, StepLabel, Stepper, Tooltip } from "@mui/material"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { SingleInputDateRangeField, StaticDateRangePicker } from "@mui/x-date-pickers-pro"
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view"
import { memo, useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { shortcuts } from "../../../utils/Dates"
import { Context } from "./Context"
import { LoadingButton } from "@mui/lab"
import { execiteReport } from "../../../utils/Reports"
import { GlobalContext } from "../../../GlobalContext"


const Categories = memo(({setActiveStep, categories, reports}) => {
    const { t } = useTranslation()

    const {
        selectedReports, 
        setSelectedReports,
    } = useContext(Context)
    
    return (
        <>
            <Box sx={{width: '100%'}}>
                <SimpleTreeView 
                    sx={{ height: 'auto', flexGrow: 1, width: '100%', overflowX: 'hidden', overflowY: 'auto' }}
                    selectedItems={selectedReports}
                    onSelectedItemsChange={(event, ids) => {
                        // si selecciona una categoria, selecciona todos los reportes de esa categoria
                       /*  if(ids.some(id => id.includes('category-'))){
                            const category = categories.find(category => `category-${category.id}` === ids[0])

                            setSelected([
                                ...ids,
                                ...reports.map(report => report.slug)
                            ])
                        }else{
                            setSelected(ids.filter(id => !id.includes('category-')))
                        } */
                        setSelectedReports(ids.filter(id => !id.includes('category-')))
                        console.log(ids.filter(id => !id.includes('category-')))
                    }}
                    onItemSelectionToggle={(event, itemId, isSelected) =>  { console.log('onItemSelectionToggle', itemId, isSelected) }}
                    checkboxSelection
                    multiSelect
                >
                    { categories.map((category) =>
                        <TreeItem key={`category-${category?.id}`} itemId={`category-${category?.id}`} label={category?.name}>
                            { reports.map((report) => 
                                <Tooltip key={`tooltip-report-${report?.slug}`} title={report?.description}>
                                    <TreeItem key={`report-${report?.slug}`} itemId={report?.slug} label={report?.name} />
                                </Tooltip>
                            )}
                        </TreeItem>
                    )}
                </SimpleTreeView>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                    disableElevation
                    disabled={selectedReports.length === 0}
                    variant="contained"
                    onClick={() => {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }}
                    sx={{ mt: 1, mr: 1 }}
                >
                    {t('reports.table.modals.add_report.steps.buttons.next')} 
                </Button>
            </Box>
        </>
    )
})

const Employees = memo(({setActiveStep, employees, loadingEmployees}) => {
    const { t } = useTranslation()

    const {
        selectedEmployees, 
        setSelectedEmployees,
    } = useContext(Context)

    const columns = useMemo(() => [
        {
            field: 'person_number',
            headerName: t('Número personal'),
            flex: 2.5,
        },
        {
            field: 'full_name',
            headerName: t('Nombre completo'),
            flex: 2.5,
        },
        {
            field: 'orgpath',
            headerName: t('Ruta organizacional'),
            flex: 2.5,
        },
        {
            field: 'smartgroups',
            headerName: t('Grupos inteligentes'),
            flex: 2.5,
        }
    ], [t])

    return(
        <>
            <Box>
                <DataGridPro 
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    unstable_headerFilters
                    loading={loadingEmployees}
                    columns={columns}
                    rows={employees}
                    checkboxSelection
                    rowSelectionModel={selectedEmployees}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setSelectedEmployees(newRowSelectionModel);
                    }}
                    slots={{
                        loadingOverlay: LinearProgress,
                    }}
                    sx={{
                        height: `400px`,
                    }}
                    hideFooter
                />
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                    disableElevation
                    disabled={false}
                    onClick={() => {
                        setActiveStep((prevActiveStep) => prevActiveStep - 1);
                    }}
                    sx={{ mt: 1, mr: 1 }}
                >
                    {t('reports.table.modals.add_report.steps.buttons.back')} 
                </Button>

                <Button
                    disableElevation
                    disabled={selectedEmployees.length === 0}
                    variant="contained"
                    onClick={() => {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }}
                    sx={{ mt: 1, mr: 1 }}
                >
                    {t('reports.table.modals.add_report.steps.buttons.next')} 
                </Button>
            </Box>
        </>
    )
})

const Range = memo(({setActiveStep}) => {
    const { t } = useTranslation()

    const {
        selectedDates, 
        setSelectedDates
    } = useContext(Context)

    return(
        <>
            <Box>
                <StaticDateRangePicker
                    slots={{ field: SingleInputDateRangeField }}
                    slotProps={{
                        shortcuts: {
                            items: shortcuts(t),
                        },
                        actionBar: { actions: [] },
                    }}
                    calendars={2}
                    value={selectedDates}
                    onChange={(nv) => setSelectedDates(nv)}
                    sx={{
                        border: '1px solid rgba(0,0,0,0.23)',
                        borderRadius: 1,
                    }}
                />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                    disableElevation
                    disabled={false}
                    onClick={() => {
                        setActiveStep((prevActiveStep) => prevActiveStep - 1);
                    }}
                    sx={{ mt: 1, mr: 1 }}
                >
                    {t('reports.table.modals.add_report.steps.buttons.back')} 
                </Button>

                <Button
                    disableElevation
                    disabled={selectedDates[0] === null || selectedDates[1] === null}
                    variant="contained"
                    onClick={() => {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }}
                    sx={{ mt: 1, mr: 1 }}
                >
                    {t('reports.table.modals.add_report.steps.buttons.next')} 
                </Button>
            </Box>
        </>
    )
})

const Resume = memo(({setActiveStep, setOpen, reports, employees, reloading}) => {
    const { t } = useTranslation()

    const { handlerAlert } = useContext(GlobalContext)

    const {
        selectedReports, 
        selectedEmployees,
        selectedDates,
        loadingExecute,
        setLoadingExecute,
    } = useContext(Context)

    const columns = useMemo(() => [
        {
            field: 'person_number',
            headerName: t('Número personal'),
            flex: 1,
        },
        {
            field: 'full_name',
            headerName: t('Nombre completo'),
            flex: 1,
        },
        {
            field: 'reports',
            headerName: t('Reportes'),
            flex: 1,
        },
        {
            field: 'dates',
            headerName: t('Rango de fechas'),
            flex: 1,
        }
    ], [t])

    return(
        <>
            <Box>
                <DataGridPro 
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    unstable_headerFilters
                    columns={columns}
                    rows={selectedEmployees.map(employee => ({
                        id: employee,
                        person_number: employee,
                        full_name: employees.find(e => e.person_number === employee)?.full_name,
                        reports: reports.map(report => selectedReports.includes(report.slug) ? report.name : null).filter(report => report !== null).join(', '),
                        dates: `${selectedDates[0]?.format('YYYY-MM-DD')} - ${selectedDates[1]?.format('YYYY-MM-DD')}`
                    }))}
                    slots={{
                        loadingOverlay: LinearProgress,
                    }}
                    sx={{
                        maxHeight: '300px',
                    }}
                    hideFooter
                />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                    disableElevation
                    disabled={false}
                    onClick={() => {
                        setActiveStep((prevActiveStep) => prevActiveStep - 1);
                    }}
                    sx={{ mt: 1, mr: 1 }}
                >
                    {t('reports.table.modals.add_report.steps.buttons.back')} 
                </Button>

                <LoadingButton 
                        variant='contained'
                        disableElevation 
                        onClick={() => {
                            execiteReport(
                                setLoadingExecute,
                                selectedEmployees,
                                selectedReports,
                                selectedDates,
                                reloading,
                                setOpen,
                                handlerAlert,
                            )
                        }} 
                        loading={loadingExecute}
                        disabled={false}
                    >
                        {t('reports.table.modals.add_report.steps.buttons.create')}
                    </LoadingButton>
            </Box>
        </>
    )
})

const Steps = memo(({setOpen, categories, reports, employees, reloading}) => {
    const { t } = useTranslation()

    const [activeStep, setActiveStep] = useState(0)

    const steps = [
        {
            title: t('reports.table.modals.add_report.steps.step_1.title'),
            content: <Categories setActiveStep={setActiveStep} categories={categories} reports={reports}/>                
        },
        {
            title: t('reports.table.modals.add_report.steps.step_2.title'),
            content: <Employees setActiveStep={setActiveStep} employees={employees} loadingEmployees={false}/>
        },
        {
            title:  t('reports.table.modals.add_report.steps.step_3.title'),
            content: <Range setActiveStep={setActiveStep}/>
        },
        {
            title:  t('reports.table.modals.add_report.steps.step_4.title'),
            content: <Resume setActiveStep={setActiveStep} reports={reports} employees={employees} setOpen={setOpen} reloading={reloading}/>
        }
    ]

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    display: 'grid',
                    alignItems: 'left',
                    justifyContent: 'left',
                }}
            >
                <Stepper 
                    //sx={{width: 450}} 
                    activeStep={activeStep} 
                    orientation="vertical"
                >
                    {steps.map((step, index) => 
                        <Step key={index}>
                            <StepLabel>{step.title}</StepLabel>

                            <StepContent>
                                <Box sx={{width: '810px'}}>
                                    {step.content}
                                </Box>
                            </StepContent>
                        </Step>
                    )}
                </Stepper>
            </Box>
        </>
    )
})

export {
    Steps
}