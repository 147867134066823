import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { Avatar, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemIcon, ListItemText, Tab, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import KeyIcon from '@mui/icons-material/Key';
import WindowIcon from '@mui/icons-material/Window';
import GoogleIcon from '@mui/icons-material/Google';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Options } from '../../menu/settings/Options';
import { getBorder } from '../../../utils/Styles';
import { changingPassword } from '../../../utils/Settings';
import { GlobalContext } from '../../../GlobalContext';

const Config = ({open, setOpen}) => {
    const { handlerAlert } = useContext(GlobalContext)

    const { t } = useTranslation()
    
    const [value, setValue] = useState('1');

    const [openChangePassword, setOpenChangePassword] = useState(true)
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [loadingChangePassword, setLoadingChangePassword] = useState(false)
    const [errorChangePassword, setErrorChangePassword] = useState(false)

    const options = useMemo(() => [
        {
            id: 1,
            text: openChangePassword ? t('settings.tab.tab1.list.list1.options.hide_password_change') : t('settings.tab.tab1.list.list1.options.password_change'),
            icon: <KeyIcon />,
            click: () => {
                setOpenChangePassword(!openChangePassword)

                if(openChangePassword === false){
                    setPassword('')
                    setNewPassword('')
                    setConfirmPassword('')
                }
            },
            disable: JSON.parse(sessionStorage.getItem('privileges')).settings.change_password
        },
        {
            id: 2,
            text: t('settings.tab.tab1.list.list2.options.unlink'),
            icon: <DeleteIcon />,
            click: () => {},
            disable: false
        },
        {
            id: 3,
            text: t('settings.tab.tab1.list.list2.options.update'),
            icon: <EditIcon />,
            click: () => {},
            disable: false
        }
    ], [openChangePassword, t])

    const closing = () => {
        setOpen(false)
        setPassword('')
        setNewPassword('')
        setConfirmPassword('')
        setLoadingChangePassword(false)
        setErrorChangePassword(false)
        setOpenChangePassword(true)
    }

    useEffect(() => {
    }, [])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => closing()}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant='body3'>{ t('settings.title') }</Typography>
                </DialogTitle>

                <DialogContent sx={{pl: 0, pr: 0, pt: 0}}>
                    <Box 
                        sx={{ 
                            width: '100%', 
                            typography: 'body1',
                        }}
                    >
                        <TabContext sx={{width: '100%'}} value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={(event, nv) => setValue(nv)}>
                                    <Tab label={t('settings.tab.tab1.title')} value="1" />
                                    {/* <Tab label={t('settings.tab.tab2.title')} value="2" />
                                    <Tab label={t('settings.tab.tab3.title')} value="3" /> */}
                                </TabList>
                            </Box>

                            <TabPanel sx={{height: '500px'}} value="1">
                                <List>
                                    { JSON.parse(sessionStorage.getItem('privileges'))?.settings?.change_password &&
                                        <>
                                            <ListItem secondaryAction={options.filter(opt => opt.disable === false).length > 0 ? <Options options={options.filter(opt => opt.id === 1)} /> : <></>}>
                                                <ListItemIcon sx={{ml: -0.5}}>
                                                    <Avatar>
                                                        <KeyIcon />
                                                    </Avatar>
                                                </ListItemIcon>

                                                <ListItemText 
                                                    primary={t('settings.tab.tab1.list.list1.title')} 
                                                    secondary={
                                                        t('settings.tab.tab1.list.list1.subtitle', {
                                                            username: JSON.parse(sessionStorage.getItem('account'))?.username, 
                                                            password: '* * * * * *'
                                                        })
                                                    }
                                                />
                                            </ListItem>

                                            <Collapse in={openChangePassword} timeout="auto" unmountOnExit>
                                                <Box 
                                                    sx={{
                                                        m: 1,
                                                        border: theme => getBorder(theme.palette.mode),
                                                        borderRadius: 2,
                                                        p: 2.5
                                                    }}
                                                >
                                                    <Typography variant='body3'>
                                                        <b>{ t('settings.tab.tab1.list.list1.form.title') }</b>
                                                    </Typography>

                                                    <Grid container>
                                                        <Grid item xs={12} sx={{mb: 2.5, mt: 3.5}}>
                                                            <TextField
                                                                label={t('settings.tab.tab1.list.list1.form.password')}
                                                                variant="outlined" 
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                placeholder='* * * * * * * *'
                                                                fullWidth
                                                                type='password'
                                                                error={errorChangePassword}
                                                                helperText={errorChangePassword ? 'La contraseña es incorrecta' : ''}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} sx={{mb: 2.5}}>
                                                            <TextField
                                                                label={t('settings.tab.tab1.list.list1.form.new_password')}
                                                                variant="outlined" 
                                                                value={newPassword}
                                                                onChange={(e) => setNewPassword(e.target.value)}
                                                                placeholder='* * * * * * * *'
                                                                fullWidth
                                                                type='password'
                                                                error={newPassword !== confirmPassword}
                                                                helperText={newPassword !== confirmPassword ? 'La contraseña no coincide' : ''}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} sx={{mb: 2.5}}>
                                                            <TextField
                                                                label={t('settings.tab.tab1.list.list1.form.confirm_password')}
                                                                variant="outlined" 
                                                                value={confirmPassword}
                                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                                placeholder='* * * * * * * *'
                                                                fullWidth
                                                                type='password'
                                                                error={newPassword !== confirmPassword}
                                                                helperText={newPassword !== confirmPassword ? 'La contraseña no coincide' : ''}
                                                            />
                                                        </Grid>

                                                        <Grid container direction={'row'} sx={{justifyContent: 'flex-end'}}>
                                                            <Button 
                                                                variant='contained'
                                                                color='error'
                                                                disableElevation
                                                                sx={{mr: 1.5}}
                                                                onClick={() => {
                                                                    setOpenChangePassword(false)
                                                                    setPassword('')
                                                                    setNewPassword('')
                                                                    setConfirmPassword('')
                                                                }}
                                                            >
                                                                {t('settings.tab.tab1.list.list1.form.buttons.cancel')}
                                                            </Button>

                                                            <LoadingButton
                                                                variant='contained'
                                                                disableElevation 
                                                                onClick={() => {
                                                                    changingPassword(setLoadingChangePassword, setErrorChangePassword, password, newPassword, handlerAlert)
                                                                }}
                                                                color='primary'
                                                                loading={loadingChangePassword}
                                                                disabled={
                                                                    password === '' || 
                                                                    newPassword === '' || 
                                                                    confirmPassword === '' || 
                                                                    newPassword !== confirmPassword
                                                                }
                                                            >
                                                                {t('settings.tab.tab1.list.list1.form.buttons.change')}
                                                            </LoadingButton>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Collapse>
                                        </>
                                    }

                                    { JSON.parse(sessionStorage.getItem('privileges'))?.settings?.sso_microsoft &&
                                        <ListItem secondaryAction={options.filter(opt => opt.disable === false).length > 0 ? <Options options={options.filter(opt => opt.id !== 1)}/> : <></>}>
                                            <ListItemIcon sx={{ml: -0.5}}>
                                                <Avatar>
                                                    <WindowIcon />
                                                </Avatar>
                                            </ListItemIcon>

                                            <ListItemText 
                                                primary={t('settings.tab.tab1.list.list2.title')} 
                                                secondary={JSON.parse(sessionStorage.getItem('account'))?.email}
                                            />
                                        </ListItem>
                                    }
                                    
                                    { JSON.parse(sessionStorage.getItem('privileges'))?.settings?.sso_google &&
                                        <ListItem secondaryAction={options.filter(opt => opt.disable === false).length > 0 ? <Options options={options.filter(opt => opt.id !== 1)}/> : <></>}>
                                            <ListItemIcon sx={{ml: -0.5}}>
                                                <Avatar>
                                                    <GoogleIcon />
                                                </Avatar>
                                            </ListItemIcon>

                                            <ListItemText 
                                                primary={t('settings.tab.tab1.list.list3.title')}
                                                secondary={'nicolas.rivera@scmlatam.com'}
                                            />
                                        </ListItem>
                                    }
                                </List>
                            </TabPanel>

                            <TabPanel sx={{height: '500px'}} value="2">

                            </TabPanel>
                            
                            <TabPanel sx={{height: '500px'}} value="3">

                            </TabPanel>
                        </TabContext>
                    </Box>
                </DialogContent>

                <DialogActions>
                    
                </DialogActions>
            </Dialog>
        </>
    )
}

export { 
    Config 
}