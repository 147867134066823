const stringToColor = (string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
}

const getBorder = (theme) => {
	const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

	switch(theme){
		case 'light':
			return isMobile ? 'none' : '1px solid rgba(0, 0, 0, 0.12)'
		case 'dark':
			return isMobile ? 'none' : '1px solid rgba(255, 255, 255, 0.12)'
		default:
			return 'none'
	}
}

const getBorderWithLine = (theme, line) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

	switch(theme){
		case 'light':
			return isMobile ? 'none' : `1px ${line} rgba(0, 0, 0, 0.12)`
		case 'dark':
			return isMobile ? 'none' : `1px ${line} rgba(255, 255, 255, 0.12)`
		default:
			return 'none'
	}
}

export { 
    getBorder,
    getBorderWithLine,
    stringToColor,
}