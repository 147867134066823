// MUI
import { IconButton, InputAdornment, TextField } from "@mui/material";

// Libraries
import moment from "moment";

//Icons
import WatchLaterIcon from '@mui/icons-material/WatchLater';

const calculateTime = (time_in_string) => {
    const regex_0_to_23 = /^([0-1]?[0-9]|2[0-3])$/;
    const regex_0_to_59 = /^(?:[0-5]?[0-9])$/

    console.log('time_in_string', time_in_string)
    console.log('0 al 23', regex_0_to_23.test(`${time_in_string[0]}${time_in_string[1]}`))
    console.log('0 al 59', regex_0_to_59.test(`${time_in_string[2]}${time_in_string[3]}`))

    if(regex_0_to_23.test(`${time_in_string[0]}${time_in_string[1]}`) && regex_0_to_59.test(`${time_in_string[2]}${time_in_string[3]}`)){
        const hours = parseInt(`${time_in_string[0]}${time_in_string[1]}`);
        const minutes = parseInt(`${time_in_string[2]}${time_in_string[3]}`);

        return {
            error: false,
            time: moment.duration({
                hours: hours,
                minutes: minutes
            })
        };
    }else{
        return {
            error: true,
            time: undefined
        };
    }
}

const validateFormat = (time_in_string) => {
    const regex_two_points = /:/

    const regext_am = /(a|am)$/i
    const regext_pm = /(p|pm)$/i

    let string_time_cleaned = time_in_string;

    if(regex_two_points.test(time_in_string)){
        string_time_cleaned = time_in_string.replace(/:/, '');
    }

    if(regext_am.test(time_in_string) || regext_pm.test(time_in_string)){
        string_time_cleaned = string_time_cleaned.replace(/[\s:apm]/gi, '');

        if(time_in_string.length - string_time_cleaned.length > 0 && time_in_string.length - string_time_cleaned.length <= 3){
            if(string_time_cleaned.length === 1){
                string_time_cleaned = `0${string_time_cleaned}00`
            }

            if(string_time_cleaned.length === 2){
                string_time_cleaned = `${string_time_cleaned}00`
            }

            if(string_time_cleaned.length === 3){
                string_time_cleaned = `0${string_time_cleaned}`
            }
        }else{
            return {
                error: true,
                time: undefined
            }
        }

        //console.log('string_time_cleaned', string_time_cleaned)

        //PM
        if(regext_pm.test(time_in_string)){
            if(string_time_cleaned.length === 4){
                let time_in_4_digits = parseInt(string_time_cleaned)

                if(parseInt(`${string_time_cleaned[0]}${string_time_cleaned[1]}`) === 0){
                    return {
                        error: true,
                        time: undefined
                    }
                }else if(parseInt(`${string_time_cleaned[0]}${string_time_cleaned[1]}`) > 0 && parseInt(`${string_time_cleaned[0]}${string_time_cleaned[1]}`) < 12){                            
                    return calculateTime((time_in_4_digits + 1200).toString())
                }else if(parseInt(`${string_time_cleaned[0]}${string_time_cleaned[1]}`) >= 12 && parseInt(`${string_time_cleaned[0]}${string_time_cleaned[1]}`) <= 23){
                    return calculateTime(string_time_cleaned)
                }
                else{
                    return {
                        error: true,
                        time: undefined
                    }
                }
            }else{
                return {
                    error: true,
                    time: undefined
                }
            }
            
        }
        //AM
        else{
            if(string_time_cleaned.length === 4){
                let time_in_4_digits = parseInt(string_time_cleaned) //1159
                console.log('time_in_4_digits', time_in_4_digits)

                if(parseInt(`${string_time_cleaned[0]}${string_time_cleaned[1]}`) === 0){
                    return {
                        error: true,
                        time: undefined
                    }
                }else if(parseInt(`${string_time_cleaned[0]}${string_time_cleaned[1]}`) > 0 && parseInt(`${string_time_cleaned[0]}${string_time_cleaned[1]}`) <= 11){
                    return calculateTime(string_time_cleaned)
                }else if(parseInt(string_time_cleaned) >= 1200 && parseInt(string_time_cleaned) <= 1259){  
                    return calculateTime((time_in_4_digits - 1200).toString().padStart(4, '0'))
                }
                else{
                    return {
                        error: true,
                        time: undefined
                    }
                }
            }else{
                return {
                    error: true,
                    time: undefined
                }
            }
        }
    }else{
        if(time_in_string.length - string_time_cleaned.length <= 2 ){
            if(string_time_cleaned.length === 1){
                string_time_cleaned = `0${string_time_cleaned}00`
            }

            if(string_time_cleaned.length === 2){
                string_time_cleaned = `${string_time_cleaned}00`
            }

            if(string_time_cleaned.length === 3){
                string_time_cleaned = `0${string_time_cleaned}`
            }
        }else{
            return {
                error: true,
                time: undefined
            }
        }
    
        return calculateTime(string_time_cleaned)
    }
}

const TimeField = ({
    props,
    value,
    setValue,
    error,
    setError,
    ampm,
    apiRef
}) => {
    return (
        <TextField
            {...props}
            autoFocus
            autoComplete="off"
            size="small"
            type='text'
            placeholder={ampm ? 'HH:mm A': 'HH:mm'}
            variant="outlined"
            value={value}
            onKeyDown={(e) => {
                if(value !== '' && e.key === 'Enter'){
                    const time = validateFormat(value)

                    if(time?.error){
                        setError(true)
                    }else{
                        setError(false)
                        setValue(moment.utc(time?.time?.asMilliseconds()).format(ampm ? 'HH:mm A': 'HH:mm'))
                    }
                }
            }}
            onBlur={(e) => { 
                if(value !== ''){
                    const time = validateFormat(value)

                    if(time?.error){
                        setError(true)
                    }else{
                        setError(false)
                        setValue(moment.utc(time?.time?.asMilliseconds()).format(ampm ? 'HH:mm A': 'HH:mm'))
                    }
                }
            }}
            onChange={(e) => {
                if(ampm){
                    setValue(e.target.value.replace(/[^0-9apm:]/gi, ''))
                }else{
                    if(e?.target?.value){
                        if(e.target.value.match(/\d/g).length <= 4){
                            setValue(e.target.value.replace(/[^0-9:]/gi, ''))
                        }
                    }else{
                        setValue('')
                    }
                }
            }}
            fullWidth
            error={error}
            InputProps={{
                endAdornment:
                    <InputAdornment sx={{mr: -1.5}} position="end" disabled>
                        <IconButton size="small" color={error ? 'error' : 'inherit'}>
                            <WatchLaterIcon />
                        </IconButton>
                    </InputAdornment>,
            }}
            sx={{
                width: '160px',
                '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                },
            }}
        />
    )
}

export {
    TimeField
}