import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { getMonth } from "../../../utils/Dates";
import { GlobalContext } from "../../../GlobalContext";
import { gettingAudit } from "../../../utils/Audits";
import { gettingPaycodes, gettingRequests } from "../../../utils/Requests";
import { useTranslation } from "react-i18next";

const Context = createContext({});

const ContextProvider = ({ children, value }) => {
    const { t } = useTranslation()
    const { handlerAlert } = useContext(GlobalContext)

    const [range1, setRange1] = useState([
        moment(getMonth()[0]), 
        moment(getMonth()[1])
    ]);

    const [range2, setRange2] = useState([
        moment(getMonth()[0]),
        moment(getMonth()[1])
    ]);

    const [smartgroups, setSmartgroups] = useState([]);
    const [smartgroup, setSmartgroup] = useState([]);
    const [filter1, setFilter1] = useState('');

    const [employees, setEmployees] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [filter2, setFilter2] = useState('');

    const [type, setType] = useState([
        {
            id: 1,
            name: t('request.table.filter.pending'), 
            value: 1
        },
        {
            id: 2,
            name: t('request.table.filter.cancel'), 
            value: 2
        },
        {
            id: 3,
            name: t('request.table.filter.approved'), 
            value: 3
        },
        {
            id: 7,
            name: t('request.table.filter.approved_with_errors'), 
            value: 7
        }
    ]);

    const [paycodes, setPaycodes] = useState([]);

    const [rows, setRows] = useState([]);
    const [audit, setAudit] = useState([]);
    const [search, setSearch] = useState('audit_date');

    const [ready, setReady] = useState(false)
    const [renders, setRenders] = useState(0)

    const [loadingGettingSmartgroups, setLoadingGettingSmartgroups] = useState(false);
    const [loadingGettingEmployees, setLoadingGettingEmployees] = useState(false);
    const [loadingGettingRequests, setLoadingGettingRequests] = useState(false);
    const [loadingGettingAudit, setLoadingGettingAudit] = useState(false);
    const [loadingGettingPaycodes, setLoadingGettingPaycodes] = useState(false);

    const reloading = async () => {
        await gettingRequests(
            setLoadingGettingRequests,
            range1,
            type,
            employee,
            setRows,
            handlerAlert
        )

        await gettingPaycodes(
            setLoadingGettingPaycodes,
            setPaycodes,
            handlerAlert
        )

        await gettingAudit(
            setLoadingGettingAudit,
            JSON.parse(sessionStorage.getItem('account'))?.id,
            range2,
            search,
            'employees',
            setAudit,
            handlerAlert,
        )
    }

    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem('requests'))

        if(data?.redirect){
            setRange1([moment(data.redirect.start, 'YYYY-MM-DD'), moment(data.redirect.end, 'YYYY-MM-DD')])
            setSmartgroup(data.redirect.smartgroups)
            setEmployee(data.redirect.employees)
        }else{
            if(data?.environment){
                setRange1([moment(data.environment.start, 'YYYY-MM-DD'), moment(data.environment.end, 'YYYY-MM-DD')])
                setSmartgroup(data.environment.smartgroups)
                setEmployee(data.environment.employees)
            }
        }
    }, [])
    
    return(
        <Context.Provider 
            value={{
                rows, 
                setRows,
                audit, 
                setAudit,
                range1, 
                setRange1,
                range2, 
                setRange2,
                smartgroups, 
                setSmartgroups,
                smartgroup, 
                setSmartgroup,
                filter1, 
                setFilter1,
                employees, 
                setEmployees,
                employee, 
                setEmployee,
                filter2, 
                setFilter2,
                type,
                setType,
                loadingGettingSmartgroups, setLoadingGettingSmartgroups,
                loadingGettingEmployees, setLoadingGettingEmployees,
                loadingGettingRequests, setLoadingGettingRequests,
                loadingGettingAudit, setLoadingGettingAudit,
                loadingGettingPaycodes, setLoadingGettingPaycodes,
                ready, setReady,
                renders, setRenders,
                search, setSearch,
                paycodes, setPaycodes,
                reloading,
                ...value,
            }}
        >
            { children }
        </Context.Provider>
    )
}

export {
    Context,
    ContextProvider,
}