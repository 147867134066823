import { AuthenticatedTemplate } from '@azure/msal-react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { Employees } from '../pages/web/Employees'
import { Schedule } from '../pages/web/Schedule'
import { Timecard } from '../pages/web/Timecard'
import { NotFound } from '../pages/NotFound'
import { SignUp } from '../pages/SignUp'
import { Overtime } from '../pages/web/Overtime'
import { Reports } from '../pages/web/Reports'
import { Requests } from '../pages/web/Requests'

const MSSO = () => {
    return (
        <>
            <Router>
                { JSON.parse(sessionStorage.getItem('account'))?.signin === 'MS_SSO' &&
                    <AuthenticatedTemplate>
                        <Routes>
                            <Route 
                                path={'/signin'} 
                                element={<Navigate to={'/employees'}/>} 
                                exact 
                            />

                            { JSON.parse(sessionStorage.getItem('privileges'))?.employees?.show_page &&
                                <Route 
                                    path={'/'} 
                                    element={<Navigate to={'/employees'}/>} 
                                    exact 
                                />
                            }

                            { JSON.parse(sessionStorage.getItem('privileges'))?.employees?.show_page &&
                                <Route 
                                    path={'/employees'}
                                    element={<Employees />}
                                    
                                />
                            }

                            { JSON.parse(sessionStorage.getItem('privileges'))?.schedule?.show_page &&
                                <Route 
                                    path={'/schedule'}
                                    element={<Schedule />} 
                                    exact 
                                />
                            }

                            { JSON.parse(sessionStorage.getItem('privileges'))?.timecard?.show_page &&
                                <Route 
                                    path={'/timecard'}
                                    element={<Timecard />} 
                                    exact 
                                />
                            }

                            { JSON.parse(sessionStorage.getItem('privileges'))?.overtime?.show_page &&
                                <Route
                                    path={'/overtime'}
                                    element={<Overtime />}
                                    exact
                                />
                            }

                            { JSON.parse(sessionStorage.getItem('privileges'))?.reports?.show_page &&
                                <Route
                                    path={'/reports'}
                                    element={<Reports />}
                                    exact
                                />
                            }
                            
                            { JSON.parse(sessionStorage.getItem('privileges'))?.requests?.show_page &&
                                <Route
                                    path={'/requests'}
                                    element={<Requests />}
                                    exact
                                />
                            }

                            <Route path='*' element={<NotFound />} />
                        </Routes>
                    </AuthenticatedTemplate>
                }

                { JSON.parse(sessionStorage.getItem('account'))?.signin ?
                    <>loading…</>
                    :
                    <AuthenticatedTemplate>
                        <Routes>
                            <Route 
                                path={'/signup'} 
                                element={<SignUp />} 
                                exact 
                            />
                        </Routes>
                    </AuthenticatedTemplate>
                }
            </Router>
        </>
    )
}

export {
    MSSO
}