import { DataGridPro } from "@mui/x-data-grid-pro"
import { useCallback, useContext, useMemo, useState } from "react"
import { FilterC } from "../../filter/FilterComon"
import { Collapse, LinearProgress } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Context } from "./Context"
import RefreshIcon from '@mui/icons-material/Refresh';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AddTaskIcon from '@mui/icons-material/AddTask';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AdjustIcon from '@mui/icons-material/Adjust';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ContextMenu } from "../../context_menu/ContextMenu"
import { getBorder } from "../../../utils/Styles"
import { Table as Audit } from "../audit/Table"
import { renderCell } from "./RenderCell"
import { useNavigate } from "react-router-dom"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import SaveIcon from '@mui/icons-material/Save';
import GroupsIcon from '@mui/icons-material/Groups'
import { MassiveApprove } from "../../dialog/overtime/MassiveApprove"

const Table = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { 
        apiRef,
        rows,
        range1,
        smartgroup,
        employee,
        reloading,
        loadingGettingTimecard,
        loadingGettingSchedule,
    } = useContext(Context)

    const [checked, setChecked] = useState([])
    const [contextMenu, setContextMenu] = useState(null)
    const [open, setOpen] = useState(false)

    const [openMassive, setOpenMassive] = useState(false)

    const columns = useMemo(() => [
        {
            field: 'full_name',
            headerName: t('overtime.table.columns.full_name'),
            minWidth: 250,
            flex: 2,
        },
        {
            field: 'date',
            headerName: t('overtime.table.columns.date'),
            flex: 0.5,
            minWidth: 100,
        },
        {
            field: 'shifts',
            headerName: t('overtime.table.columns.shifts'),
            width: 190,
            minWidth: 190,
            renderCell
        },
        {
            field: 'punches',
            headerName: t('overtime.table.columns.punches'),
            width: 320,
            minWidth: 320,
            renderCell,
        },
        {
            field: 'action',
            headerName: t('overtime.table.columns.action'),
            width: 220,
            minWidth: 220,
            renderCell,
        },
        {
            field: 'parcial_amount',
            headerName: t('overtime.table.columns.parcial_amount'),
            width: 220,
            minWidth: 220,
            renderCell,
        },
        {
            field: 'amount',
            headerName: t('overtime.table.columns.amount'),
            flex: 0.5,
            minWidth: 80,
        },
    ], [t])

    const options = useMemo(() => [
        {
            privilege: !JSON.parse(sessionStorage.getItem('overtime'))?.environment,
            name: t('employees.employee_table.menu.save_environment'),
            click: () => { 
                sessionStorage.setItem('overtime', JSON.stringify({
                    environment: {
                        start: range1[0].format('YYYY-MM-DD'),
                        end: range1[1].format('YYYY-MM-DD'),
                        smartgroups: smartgroup.filter(sg => employee.map(e => e.smartgroup).includes(sg.name)),
                        employees: [...new Set(employee)],
                    },
                    redirect: null,
                }))
            },
            icon: <SaveIcon />,
            divider: false,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('overtime'))?.environment,
            name: t('employees.employee_table.menu.forget_environment'),
            click: () => { 
                sessionStorage.removeItem('overtime')
            },
            icon: <SaveIcon />,
            divider: false,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).timecard.show_page,
            name: t('go to employees'),
            click: () => { 
                sessionStorage.setItem('employees', JSON.stringify({
                    environment: JSON.parse(sessionStorage.getItem('employees'))?.environment ? JSON.parse(sessionStorage.getItem('employees')).environment : null,
                    redirect: {
                        start: range1[0].format('YYYY-MM-DD'),
                        end: range1[1].format('YYYY-MM-DD'),
                        smartgroups: smartgroup.filter(sg => employee.map(e => e.smartgroup).includes(sg.name)).map(sg => sg),
                        employees: rows.filter(row => checked.includes(row.id)).map(row => ({id: row.person_number, full_name: row.full_name, smartgroup: row?.smartgroup})),
                    },
                }))

                navigate('/employees')
            },
            icon: <GroupsIcon />,
            divider: false,
            disabled: checked.length < 1,
            seleted: true,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).schedule.show_page,
            name: t('employees.employee_table.menu.go_to_schedule'),
            click: () => { 
                sessionStorage.setItem('schedule', JSON.stringify({
                    environment: JSON.parse(sessionStorage.getItem('schedule'))?.environment ? JSON.parse(sessionStorage.getItem('schedule')).environment : null,
                    redirect: {
                        start: range1[0].format('YYYY-MM-DD'),
                        end: range1[1].format('YYYY-MM-DD'),
                        smartgroups: smartgroup.filter(sg => employee.map(e => e.smartgroup).includes(sg.name)).map(sg => sg),
                        employees: rows.filter(row => checked.includes(row.id)).map(row => ({id: row.person_number, full_name: row.full_name, smartgroup: row?.smartgroup})),
                    },
                }))

                navigate('/schedule')
            },
            icon: <CalendarMonthIcon />,
            divider: false,
            disabled: checked.length < 1,
            seleted: true,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).timecard.show_page,
            name: t('employees.employee_table.menu.go_to_timecard'),
            click: () => { 
                sessionStorage.setItem('timecard', JSON.stringify({
                    environment: JSON.parse(sessionStorage.getItem('timecard'))?.environment ? JSON.parse(sessionStorage.getItem('timecard')).environment : null,
                    redirect: {
                        start: range1[0].format('YYYY-MM-DD'),
                        end: range1[1].format('YYYY-MM-DD'),
                        smartgroups: smartgroup.filter(sg => employee.map(e => e.smartgroup).includes(sg.name)).map(sg => sg),
                        employees: rows.filter(row => checked.includes(row.id)).map(row => ({id: row.person_number, full_name: row.full_name, smartgroup: row?.smartgroup})),
                    },
                }))
                navigate('/timecard')
            },
            icon: <PunchClockIcon />,
            divider: false,
            disabled: checked.length < 1,
            seleted: true,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).overtime.add_approve_overtime,
            name: t('overtime.table.menu.solve_overtime'),
            click: () => {
                setContextMenu(null)
                setOpenMassive(true)
            },
            icon: <AddTaskIcon />,
            divider: JSON.parse(sessionStorage.getItem('privileges')).overtime.add_approve_overtime,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: 
                <MassiveApprove 
                    data={rows.map(row => ({ id: row.id, name: row.full_name }))} 
                    open={openMassive} 
                    setOpen={setOpenMassive} 
                    checked={rows.filter(row => row.action !== '')} 
                    reloading={reloading}
                />,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).overtime.add_approve_overtime,
            name: t('overtime.table.menu.full_approval'),
            click: () => {
                //setContextMenu(null)
                let nrows = rows

                for(const row of nrows){
                    if(checked.includes(row.id)){
                        row.action = 'all'
                        row.partial_amount = row.amount
                    }
                }

                apiRef.current.setRows(nrows)
            },
            icon: <CheckCircleOutlineIcon />,
            divider: false,
            disabled: false,
            seleted: true,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).overtime.add_approve_overtime,
            name: t('overtime.table.menu.partial_approval'),
            click: () => {
                //setContextMenu(null)

                let nrows = rows

                for(const row of nrows){
                    if(checked.includes(row.id)){
                        row.action = 'some'
                        row.partial_amount = row.amount
                    }
                }

                apiRef.current.setRows(nrows)
            },
            icon: <AdjustIcon />,
            divider: false,
            disabled: false,
            seleted: true,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).overtime.add_approve_overtime,
            name: t('overtime.table.menu.reject_everything'),
            click: () => {
                //setContextMenu(null)

                let nrows = rows

                for(const row of nrows){
                    if(checked.includes(row.id)){
                        row.action = 'none'
                        row.partial_amount = '00:00'
                    }
                }

                apiRef.current.setRows(nrows)
            },
            icon: <HighlightOffIcon />,
            divider: false,
            disabled: false,
            seleted: true,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: true,
            name: t(open ? 'employees.employee_table.menu.close_audit' : 'employees.employee_table.menu.open_audit'),
            click: () => {
                setContextMenu(null)
                setOpen(!open)
            },
            icon: <PendingActionsIcon />,
            divider: JSON.parse(sessionStorage.getItem('privileges')).overtime.add_approve_overtime,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: true,
            name: t('employees.employee_table.menu.reload'),
            click: () => {
                setContextMenu(null)
                reloading()
            },
            icon: <RefreshIcon />,
            divider: true,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
    ], [apiRef, checked, employee, navigate, open, openMassive, range1, reloading, rows, smartgroup, t])

    return (
        <>
            <div
                onContextMenu={(event) => {
                    event.preventDefault();
                    setContextMenu(
                        contextMenu === null ? 
                            { 
                                mouseX: event.clientX + 2, 
                                mouseY: event.clientY - 6 
                            } 
                            : 
                            null
                    )
                }}
            >
                <DataGridPro 
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    //unstable_headerFilters
                    checkboxSelection
                    loading={loadingGettingTimecard || loadingGettingSchedule}
                    columns={columns}
                    rows={rows}
                    slots={{
                        toolbar: useCallback(() => <FilterC view={'overtime'} />, []),
                        loadingOverlay: LinearProgress,
                    }}
                    rowSelectionModel={checked}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setChecked(newRowSelectionModel);
                    }}
                    sx={{
                        height: `calc(100vh - ${open ? '55vh' : '120px'})`,
                    }}
                    getRowHeight={(params) => { 
                        const { model } = params

                        let length = 64
                        
                        for (const key in model) {
                            let counter = 0
                            
                            if(key === 'punches'){
                                counter = counter + model[key].length * 47
                            }

                            if(counter > length){
                                length = counter
                            }
                        }
                    
                        return length
                    }}
                    apiRef={apiRef}
                    hideFooter
                />

                <ContextMenu
                    contextMenu={contextMenu}
                    setContextMenu={setContextMenu}
                    options={options}
                    checked={checked}
                />
            </div>

            <Collapse 
                sx={{
                    height: '55vh', 
                    mt: 2.5,
                    ml: -2.5, 
                    mr: -2.5,
                    p: 2.5,
                    borderTop: (theme) => getBorder(theme.palette.mode),
                }} 
                in={open} 
                timeout="auto" 
                unmountOnExit
            >   
                <Audit view={'overtime'} />
            </Collapse>
        </>
    )
}

export { 
    Table
}