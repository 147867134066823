import { LoadingButton } from "@mui/lab"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { GlobalContext } from "../../../GlobalContext"
import { rejectRequest } from "../../../utils/Requests"

const RejectRequest = ({reloading, checked, open, setOpen}) => {
    const { t } = useTranslation()
    const { handlerAlert } = useContext(GlobalContext)

    const [loading, setLoading] = useState(false)

    const closing = () => {
        setOpen(false)
        setLoading(false)
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => closing()}
            >
                <DialogTitle>
                    <Typography variant='body3'>{ t('request.table.modals.reject_request.title') }</Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <Typography variant='body3'>
                        { checked.length === 1 ? 
                            t('request.table.modals.reject_request.message_single')
                            :
                            t('request.table.modals.reject_request.message_multiple', { amount: checked.length })
                        }
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        sx={{mr: 1.5}}
                        variant='contained'
                        disableElevation 
                        onClick={() => {
                            console.log('checked', checked)
                            let promises = checked.map(id => rejectRequest(setLoading, id, handlerAlert, closing))

                            Promise.all(promises)
                            .then(() => {
                                reloading()
                            })
                            .catch(error => {
                                console.error('error', error.message)
                            })
                            .finally(() => {
                                setLoading(false)
                            })
                        }}
                        color='success'
                        loading={loading}
                    >
                        { t('request.table.modals.reject_request.buttons.reject') }
                    </LoadingButton>

                    <Button
                        variant='contained'
                        disableElevation
                        onClick={() => closing()}
                        color='error'
                    >
                        { t('request.table.modals.reject_request.buttons.cancel') }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export {
    RejectRequest
}