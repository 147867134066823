import { LoadingButton } from "@mui/lab"
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DateRangePicker, SingleInputDateRangeField } from "@mui/x-date-pickers-pro";
import { shortcuts } from "../../../utils/Dates";
import { addRequest } from "../../../utils/Requests";
import { GlobalContext } from "../../../GlobalContext";

const AddRequest = ({reloading, checked, data, paycodes, open, setOpen}) => {
    const { t } = useTranslation()

    const { handlerAlert } = useContext(GlobalContext)

    const [loading, setLoading] = useState(false)
    const [names, setNames] = useState([])
    const [range, setRange] = useState([null, null])
    const [paycode, setPaycode] = useState(null)
    const [comment, setComment] = useState('')

    const closing = () => {
        setOpen(false)
        setLoading(false)
        setNames([])
        setRange([null, null])
        setPaycode(null)
        setComment('')
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => closing()}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant='body3'>{ t('request.table.modals.create_request.title') }</Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                limitTags={5}
                                options={[...new Set(data.map(employee => employee.name))]}
                                value={names}
                                onChange={(event, newValue) => { 
                                    setNames(newValue) 
                                    console.log(newValue)
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        { option }
                                    </li>
                                )}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        label={t('request.table.modals.create_request.employees')}
                                    />
                                }
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DateRangePicker
                                slots={{ field: SingleInputDateRangeField }}
                                slotProps={{
                                    shortcuts: {
                                        items: shortcuts(t),
                                    },
                                    actionBar: { actions: [] },
                                }}
                                calendars={1}
                                value={range}
                                onChange={(nv) => setRange(nv)}
                                sx={{
                                    border: '1px solid rgba(0,0,0,0.23)',
                                    borderRadius: 1,
                                    width: '100%',
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                options={paycodes}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        label={t('request.table.modals.create_request.paycode')}
                                    />
                                }
                                fullWidth
                                value={paycode}
                                onChange={(event, newValue) => {
                                    console.log(newValue)
                                    setPaycode(newValue)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label={t('request.table.modals.create_request.comment')}
                                fullWidth
                                multiline
                                rows={3}
                                value={comment}
                                onChange={(event) => setComment(event.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <LoadingButton
                        variant='contained'
                        disableElevation 
                        disabled={
                            names.length === 0 || 
                            range[0] === null || 
                            range[1] === null || 
                            paycode === null
                        }
                        onClick={() => {
                            const promises = names.map(name => 
                                addRequest(
                                    setLoading,
                                    data.find(employee => employee.name === name).id,
                                    paycode.id,
                                    range,
                                    comment,
                                    handlerAlert,
                                    reloading
                                )
                            )
                            
                            Promise.all(promises)
                            .then(() => closing())
                            .catch((error) => console.log('error', error.message))
                            .finally(() => 
                                setLoading(false)
                            )
                        }}
                        color='success'
                        loading={loading}
                    >
                        { t('request.table.modals.create_request.buttons.create') }
                    </LoadingButton>

                    <Button
                        sx={{mr: 2}}
                        variant='contained'
                        disableElevation
                        onClick={() => closing()}
                        color='error'
                    >
                        { t('request.table.modals.create_request.buttons.cancel') }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export {
    AddRequest
}