import moment from "moment"

/*
Función que se encarga de obtener el primer y último día del mes actual.
*/
const getMonth = () => {
    let date  = new Date()
    let year  = date.getFullYear()
    let month = date.getMonth()

    let start = moment(new Date(year, month, 1))
    let end;

    if(month + 1 > 11){
        end = moment(new Date(year + 1, 0, 1)).subtract(1, 'days')
    }else{
        end = moment(new Date(year, month + 1, 1)).subtract(1, 'days')
    }

    return [
        start.toDate(),
        end.toDate()
    ]
}

const getWeek = () => {
    let date = new Date()
    let day  = date.getDay()
    let year = date.getFullYear()
    let month = date.getMonth()

    let start = moment(new Date(year, month, date.getDate() + 1 - day))
    let end = moment(new Date(year, month, date.getDate() + (7 - day)))

    return [
        start.toDate(),
        end.toDate()
    ]
}

const beetwenDates = (range1, range2) => {
    return new Date(range1[0]) <= new Date(range2[1]) && new Date(range1[1]) >= new Date(range2[0])
}

const daysBetween = (date1, date2) => {
    if(date2){
        let clone = date1.clone()
        const days = []

        while(clone.isSameOrBefore(date2)){
            days.push(clone.format('YYYY-MM-DD'))

            clone.add(1, 'days')
        }

        return days
    }else{
        return [date1.format('YYYY-MM-DD')]
    }
}

// semana pasada
// semana actual
// semana siguiente
// Ayer
// Hoy
// Manana
// Ayer-Hoy-Manana
// Los ultimos 7 dias
// Los siguientes 7 dias
// Los ultimos 30 dias
// Los siguientes 30 dias
// Restaurar

const shortcuts = (t) => {
    return [
        {
            label: t('utils.date.ranges.last_week'),
            getValue: () => {
                const start = moment().subtract(1, 'weeks').startOf('week')
                const end = moment().subtract(1, 'weeks').endOf('week')
                return [start, end]
            }
        },
        {
            label: t('utils.date.ranges.this_week'),
            getValue: () => {
                const start = moment().startOf('week')
                const end = moment().endOf('week')
                return [start, end]
            }
        },
        {
            label: t('utils.date.ranges.next_week'),
            getValue: () => {
                const start = moment().add(1, 'weeks').startOf('week')
                const end = moment().add(1, 'weeks').endOf('week')
                return [start, end]
            }
        },
        {
            label: t('utils.date.ranges.yesterday'),
            getValue: () => {
                const start = moment().subtract(1, 'days').startOf('day')
                const end = moment().subtract(1, 'days').endOf('day')
                return [start, end]
            }
        },
        {
            label: t('utils.date.ranges.today'),
            getValue: () => {
                const start = moment().startOf('day')
                const end = moment().endOf('day')
                return [start, end]
            }
        },
        {
            label: t('utils.date.ranges.tomorrow'),
            getValue: () => {
                const start = moment().add(1, 'days').startOf('day')
                const end = moment().add(1, 'days').endOf('day')
                return [start, end]
            }
        },
        {
            label: t('utils.date.ranges.yesterday_today_tomorrow'),
            getValue: () => {
                const start = moment().subtract(1, 'days').startOf('day')
                const end = moment().add(1, 'days').endOf('day')
                return [start, end]
            }
        },
        {
            label: t('utils.date.ranges.last_7_days'),
            getValue: () => {
                const start = moment().subtract(7, 'days').startOf('day')
                const end = moment().endOf('day')
                return [start, end]
            }
        },
        {
            label: t('utils.date.ranges.next_7_days'),
            getValue: () => {
                const start = moment().startOf('day')
                const end = moment().add(7, 'days').endOf('day')
                return [start, end]
            }
        },
        {
            label: t('utils.date.ranges.last_30_days'),
            getValue: () => {
                const start = moment().subtract(30, 'days').startOf('day')
                const end = moment().endOf('day')
                return [start, end]
            }
        },
        {
            label: t('utils.date.ranges.next_30_days'),
            getValue: () => {
                const start = moment().startOf('day')
                const end = moment().add(30, 'days').endOf('day')
                return [start, end]
            }
        }
    ]
}

export {
    getWeek,
    getMonth,
    beetwenDates,
    daysBetween,
    shortcuts
}