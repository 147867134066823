import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { getWeek } from "../../../utils/Dates";
import { gettingSchedule } from "../../../utils/Schedule";
import { GlobalContext } from "../../../GlobalContext";
import { gettingOpenPeriod } from "../../../utils/Timecard";
import { useGridApiRef } from "@mui/x-data-grid";

const Context = createContext({});

const ContextProvider = ({ children, value }) => {
    const { handlerAlert } = useContext(GlobalContext)

    const apiRef = useGridApiRef();

    const [range1, setRange1] = useState([
        moment(getWeek()[0]), 
        moment(getWeek()[1])
    ]);

    const [range2, setRange2] = useState([
        moment(getWeek()[0]), 
        moment(getWeek()[1])
    ]);

    const [rangeColumns, setRangeColumns] = useState(range1);

    const [smartgroups, setSmartgroups] = useState([]);
    const [smartgroup, setSmartgroup] = useState([]);
    const [filter1, setFilter1] = useState('');

    const [employees, setEmployees] = useState([]);
    const [employee, setEmployee] = useState([]);
    const [filter2, setFilter2] = useState('');

    const [rows, setRows] = useState([]);
    const [audit, setAudit] = useState([]);
    const [search, setSearch] = useState('audit_date');

    const [periods, setPeriods] = useState([]);

    const [ready, setReady] = useState(false)
    const [renders, setRenders] = useState(0)

    const [loadingGettingSmartgroups, setLoadingGettingSmartgroups] = useState(false);
    const [loadingGettingEmployees, setLoadingGettingEmployees] = useState(false);
    const [loadingGettingSchedule, setLoadingGettingSchedule] = useState(false);
    const [loadingGettingAudit, setLoadingGettingAudit] = useState(false);
    const [loadingGettingOpenPeriod, setLoadingGettingOpenPeriod] = useState(false);
    const [loadingBulk, setLoadingBulk] = useState(true)

    const [type, setType] = useState('')

    const [dataDelete, setDataDelete] = useState({
        shift: null,
        person_number: null, 
        date: null, 
        setDisabled: () => {}
    })

    const [dataPaycodeDay, setDataPaycodeDay] = useState({
        day: null,
        setDisabled: () => {}
    })

    const [dataPaycodeHour, setDataPaycodeHour] = useState({
        hour: null,
        setDisabled: () => {}
    })

    const [deleteOpen, setDeleteOpen] = useState(false)

    const searching = async () => {
        await gettingSchedule(
            setLoadingGettingSchedule,
            range1,
            employees.filter(e => employee.map(employee => employee.id).includes(e.id)),
            setRows,
            handlerAlert
        )

        await gettingOpenPeriod(
            setLoadingGettingOpenPeriod, 
            range1, 
            employee,
            smartgroup,
            'schedule',
            rows, 
            setPeriods, 
            handlerAlert
        )

        setRangeColumns(range1)
    }

    const reloading = async () => {
        const data = JSON.parse(sessionStorage.getItem('schedule'))

        await gettingSchedule(
            setLoadingGettingSchedule,
            [moment(data?.reloading?.start, 'YYYY-MM-DD'), moment(data?.reloading?.end, 'YYYY-MM-DD')],
            data?.reloading?.employees,
            setRows,
            handlerAlert
        )

        await gettingOpenPeriod(
            setLoadingGettingOpenPeriod, 
            [moment(data?.reloading?.start, 'YYYY-MM-DD'), moment(data?.reloading?.end, 'YYYY-MM-DD')],
            data?.reloading?.employees,
            data?.reloading?.smartgroups,
            'schedule',
            rows, 
            setPeriods, 
            handlerAlert
        )

        setRangeColumns([moment(data?.reloading?.start, 'YYYY-MM-DD'), moment(data?.reloading?.end, 'YYYY-MM-DD')])
    }

    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem('schedule'))

        if(data?.redirect){            
            setRange1([moment(data.redirect.start, 'YYYY-MM-DD'), moment(data.redirect.end, 'YYYY-MM-DD')])
            setSmartgroup(data.redirect.smartgroups)
            setEmployee(data.redirect.employees)
        }/* else{
            if(data?.environment){
                setRange1([moment(data.environment.start, 'YYYY-MM-DD'), moment(data.environment.end, 'YYYY-MM-DD')])
                setSmartgroup(data.environment.smartgroups)
                setEmployee(data.environment.employees)
            }
        } */
    }, [])
    
    return(
        <Context.Provider 
            value={{
                apiRef,
                rows, setRows,
                audit, setAudit,
                range1, setRange1,
                range2, setRange2,
                rangeColumns, setRangeColumns,
                periods, setPeriods,
                smartgroups, 
                setSmartgroups,
                smartgroup, 
                setSmartgroup,
                filter1, 
                setFilter1,
                employees, 
                setEmployees,
                employee, 
                setEmployee,
                filter2, 
                setFilter2,
                loadingGettingSmartgroups, setLoadingGettingSmartgroups,
                loadingGettingEmployees, setLoadingGettingEmployees,
                loadingGettingSchedule, setLoadingGettingSchedule,
                loadingGettingAudit, setLoadingGettingAudit,
                loadingGettingOpenPeriod, setLoadingGettingOpenPeriod,
                loadingBulk, setLoadingBulk,
                ready, setReady,
                renders, setRenders,
                search, setSearch,
                deleteOpen, setDeleteOpen,
                dataDelete, setDataDelete,
                type, setType,
                dataPaycodeDay, setDataPaycodeDay,
                dataPaycodeHour, setDataPaycodeHour,
                searching,
                reloading,
                ...value,
            }}
        >
            { children }
        </Context.Provider>
    )
}

export {
    Context,
    ContextProvider,
}
