import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Autocomplete, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemText, TextField, Tooltip, Typography } from '@mui/material'
import { SingleInputDateRangeField, StaticDateRangePicker } from '@mui/x-date-pickers-pro'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shortcuts } from '../../../utils/Dates'
import { cleaningSchedule } from '../../../utils/Schedule'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { GlobalContext } from '../../../GlobalContext'
import moment from 'moment'
import { Context } from '../../table/schedule/Context'

const CleanSchedule = ({data, open, setOpen, checked, cell, setCell, reloading}) => {
    const { t } = useTranslation()
    const [names, setNames] = useState([])
    const [range, setRange] = useState([null, null])
    const [loading, setLoading] = useState(false)

    const { 
        handlerAlert
    } = useContext(GlobalContext)

    const { 
        periods
    } = useContext(Context)

    const closing = () => {
        setOpen(false)
        setNames([])
        setRange([null, null])
        setCell({row: null, column: null})
        setLoading(false)
    }

    const disableForPeriod = () => {
        if(moment(range[0]).isValid()){
            const employees = periods.filter(p => data.filter(d => names.includes(d.name)).map(d => d.id).includes(p.person_number))

            for(const employee of employees){
                if(moment(employee?.end_date, 'YYYY-MM-DD').add(1, 'days').isSameOrBefore(range[0])){
                    return false
                }
            }

            return true
        }else{
            return true
        }
    }

    useEffect(() => {
        if(open){
            if(checked.length !== 0){
                setNames(checked.map(employee => employee.name))
            }else{
                setNames(cell?.row ? data.filter(employee => `${employee.id}` === cell?.row).map(employee => employee?.name) : [])
            }

            if(cell?.column){
                setRange([moment(cell?.column, 'YYYY-MM-DD'), moment(cell?.column, 'YYYY-MM-DD')])
            }
        }
    }, [cell?.column, cell?.row, checked, data, open])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Typography variant='body3'>{ t('schedule.table.modals.clean_schedule.title') }</Typography>
                </DialogTitle>

                { periods.filter(p => data.filter(d => names.includes(d.name)).map(d => d.id).includes(p.person_number)).some(p => moment(p?.end_date, 'YYYY-MM-DD').isSameOrAfter(range[0])) &&//names.includes(period.full_name.slice(0, -15))).some(period => moment(period?.end_date, 'YYYY-MM-DD').isSameOrAfter(range[0])) && 
                    <DialogContent
                        sx={{
                            backgroundColor: (theme) => theme.palette.background.default,
                            borderBottom: 'none'
                        }}
                        dividers
                    >
                        <Grid container spacing={2}>
                            <Grid 
                                item 
                                xs={12}
                            >
                                <Alert
                                    severity="warning"
                                    sx={{
                                        width: '100%',
                                        opacity: 1,
                                    }}
                                    variant="filled"
                                >
                                    <AlertTitle>{t('schedule.table.modals.clean_schedule.alerts.title')}</AlertTitle>
                                    {t('schedule.table.modals.clean_schedule.alerts.message')}
                                </Alert>
                            </Grid>
                        </Grid>
                    </DialogContent>
                }

                <DialogContent 
                    dividers
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                limitTags={5}
                                options={useMemo(() => data.map(employee => employee.name), [data])}
                                value={names}
                                onChange={(event, newValue) => { 
                                    setNames(newValue)
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        { option }
                                    </li>
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            key={index}
                                            label={option}
                                            color={moment(periods.find(p => p?.person_number === data.find(d => d.name === option)?.id)?.end_date, 'YYYY-MM-DD').isSameOrAfter(range[0]) ? 'warning' : undefined} // Utiliza el color personalizado
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        label={t('schedule.table.modals.clean_schedule.employees')}
                                    />
                                }
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <StaticDateRangePicker
                                slots={{ field: SingleInputDateRangeField }}
                                slotProps={{
                                    shortcuts: {
                                        items: shortcuts(t),
                                    },
                                    actionBar: { actions: [] },
                                }}
                                calendars={1}
                                value={range}
                                onChange={(nv) => setRange(nv)}
                                sx={{
                                    borderRadius: 1,
                                    width: '100%',
                                    background: 'transparent',
                                }}
                                minDate={periods.filter(p => data.filter(d => names.includes(d.name)).map(d => d.id).includes(p.person_number)).map(p => moment(p.end_date, 'YYYY-MM-DD')).length > 0 ? periods.filter(p => data.filter(d => names.includes(d.name)).map(d => d.id).includes(p.person_number)).map(p => moment(p.end_date, 'YYYY-MM-DD')).reduce((earliest, current) => current.isSameOrBefore(earliest) ? current : earliest ).add(1, 'days') : undefined }
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    sx={{
                        backgroundColor: (theme) => theme.palette.background.default,
                    }}
                >
                    <Tooltip title={
                        names.length < 1 ||
                        range[0] === null || 
                        range[1] === null ||
                        disableForPeriod()
                        ?
                        <>
                            <List dense={true}>
                                { names.length < 1 &&
                                    <ListItem>
                                        <ListItemText primary={t('schedule.table.modals.buttons.clean.missing_employees')}/>
                                    </ListItem>
                                }

                                { (range[0] === null || range[1] === null) &&
                                    <ListItem>
                                        <ListItemText primary={t('schedule.table.modals.buttons.clean.missing_dates')}/>
                                    </ListItem>
                                }

                                { disableForPeriod() &&
                                    <ListItem>
                                        <ListItemText primary={t('schedule.table.modals.buttons.clean.days_outside_the_open_period')}/>
                                    </ListItem>
                                }
                            </List>
                        </>
                        :
                        ''
                    }>
                        <span>
                            <LoadingButton
                                variant='contained'
                                disableElevation 
                                onClick={() => {
                                    cleaningSchedule(
                                        setLoading,
                                        data.filter(employee => names.includes(employee.name)).map(employee => employee.id),
                                        range,
                                        periods,
                                        closing,
                                        reloading,
                                        handlerAlert
                                    )
                                }}
                                color='success'
                                loading={loading}
                                disabled={
                                    names.length < 1 ||
                                    range[0] === null || 
                                    range[1] === null ||
                                    disableForPeriod()
                                }
                            >
                                {t('schedule.table.modals.buttons.clean.title')}
                            </LoadingButton>
                        </span>
                    </Tooltip>

                    <Button 
                        variant='contained' 
                        disableElevation 
                        color='error' 
                        onClick={() => { closing() }}
                        sx={{mr: 1.5}}
                    >
                        {t('schedule.table.modals.buttons.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CleanSchedule