import { LoadingButton } from "@mui/lab"
import { Autocomplete, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Context } from "../../table/timecard/Context";
import { FileField } from "../../input/FileField";
import { gettingPaycodes } from "../../../utils/Schedule";
import { GlobalContext } from "../../../GlobalContext";
import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { SingleInputDateRangeField, StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { shortcuts } from "../../../utils/Dates";
import { addingHourPaycode } from "../../../utils/Timecard";

const AddPaycodeHour = ({open, setOpen}) => {
    const { t } = useTranslation()

    const {
        employee: employees,
        row,
        setRow,
        reloading,
    } = useContext(Context)

    const {
        handlerAlert
    } = useContext(GlobalContext)
    
    const [type, setType] = useState('PCH')
    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [files, setFiles] = useState([])
    const [paycodes, setPaycodes] = useState([])
    const [paycode, setPaycode] = useState(null)
    const [amount, setAmount] = useState(moment(new Date().setHours(0,0,0,0)))
    const [start, setStart] = useState(moment(new Date().setHours(0,0,0,0)))
    const [range, setRange] = useState([null, null])

    // eslint-disable-next-line no-unused-vars
    const [loadingAddingPaycode, setLoadingAddingPaycode] = useState(false)
    const [loadingGettingPaycodes, setLoadingGettingPaycodes] = useState(false)

    const closing = () => {
        setOpen(false)
        setRow({})
        setLoadingGettingPaycodes(false)
        setLoadingAddingPaycode(false)
        setPaycodes([])
        setPaycode(null)
        setAmount(moment(new Date().setHours(0,0,0,0)))
        setStart(moment(new Date().setHours(0,0,0,0)))
        setRange([null, null])
        setFiles([])
        setType('PCH')
    }

    useEffect(() => {
        if(open){
            gettingPaycodes(setLoadingGettingPaycodes, setPaycodes, handlerAlert)
            setSelectedEmployees([employees.find(employee => employee.id === row.person_number)?.full_name] || [])
            setRange([moment(row.date, 'YYYY-MM-DD'), moment(row.date, 'YYYY-MM-DD')])
        }
    }, [employees, handlerAlert, open, row])

    return (
        <>
            <Dialog
                fullWidth
                open={open} 
                onClose={() => { closing() }}
                maxWidth='sm'
            >
                <DialogTitle>
                    <Typography variant='body3'>{ t('timecard.table.modals.add_paycode_hour.title') }</Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                limitTags={3}
                                options={useMemo(() => employees.map(employee => employee.full_name), [employees])}
                                value={selectedEmployees}
                                onChange={(event, newValue) => { 
                                    setSelectedEmployees(newValue) 
                                    console.log(newValue)
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        { option }
                                    </li>
                                )}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        label={t('timecard.table.modals.add_paycode_hour.employees')}
                                    />
                                }
                                fullWidth
                            />
                        </Grid>
                        
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="l-type">{t('timecard.table.modals.add_paycode_hour.type_of_paycode')}</InputLabel>

                                <Select
                                    labelId="l-type"
                                    id="type"
                                    value={type}
                                    label={t('timecard.table.modals.add_paycode_hour.type_of_paycode')}
                                    onChange={(e) => { 
                                        setType(e.target.value)
                                        setPaycode(null)
                                    }}
                                >
                                    <MenuItem value={'PCH'}>{t('timecard.table.modals.add_paycode_hour.paycode_of_hour')}</MenuItem>
                                    <MenuItem value={'PCD'}>{t('timecard.table.modals.add_paycode_hour.paycode_of_day')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <Autocomplete
                                loading={loadingGettingPaycodes}
                                disabled={loadingGettingPaycodes}
                                options={
                                    useMemo(() => type === 'PCD' 
                                        ? paycodes.filter(paycode => paycode.is_days).map(paycode => paycode.name) 
                                        : paycodes.filter(paycode => !paycode.is_days).map(paycode => paycode.name), 
                                        [paycodes, type]
                                    )
                                }
                                value={paycode}
                                onChange={(event, newValue) => { setPaycode(newValue) }}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        label={t('timecard.table.modals.add_paycode_hour.paycode')} 
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loadingGettingPaycodes ? <CircularProgress color="inherit" size={20} /> : null}
                                                    { params.InputProps.endAdornment }
                                                </>
                                            ),
                                        }}
                                    />
                                }
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TimePicker
                                disabled={type === 'PCD'}
                                label={t('timecard.table.modals.add_paycode_hour.amount')}
                                value={amount}
                                onChange={(nv) => setAmount(nv)}
                                ampm={false}
                                sx={{
                                    width: '100%'
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TimePicker
                                disabled={type === 'PCD'}
                                label={t('timecard.table.modals.add_paycode_hour.starts_at')}
                                value={start}
                                onChange={(nv) => setStart(nv)}
                                ampm={false}
                                sx={{
                                    width: '100%'
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <StaticDateRangePicker
                                slots={{ field: SingleInputDateRangeField }}
                                slotProps={{
                                    shortcuts: {
                                        items: shortcuts(t),
                                    },
                                    actionBar: { actions: [] },
                                }}
                                calendars={1}
                                value={range}
                                onChange={(nv) => setRange(nv)}
                                sx={{
                                    border: '1px solid rgba(0,0,0,0.23)',
                                    borderRadius: 1,
                                }}
                            />
                        </Grid>

                        {/* <Grid item xs={12}>
                            <SimpleTreeView>
                                { selectedEmployees.map((employee, index) => 
                                    <TreeItem key={`${index}`} itemId={employee} label={employees.find(e => e.full_name === employee)?.full_name}>
                                        <TreeItem itemId={`${employee}-${'2024-10-01'}`} label='2024-10-01'/>
                                        <TreeItem itemId={`${employee}-${'2024-10-02'}`} label='2024-10-02'/>
                                        <Box sx={{ml: 4, mt: 1}}> holi </Box>
                                    </TreeItem>
                                )}
                            </SimpleTreeView>
                        </Grid> */}
                        
                        <Grid item xs={12}>
                            <FileField
                                files={files}
                                setFiles={setFiles}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <LoadingButton 
                        variant='contained'
                        disableElevation 
                        onClick={() => {
                            addingHourPaycode(
                                setLoadingAddingPaycode,
                                [...new Set(employees.filter(e => selectedEmployees.includes(e.full_name)).map(e => e.id))],
                                range,
                                paycode,
                                start,
                                amount,
                                false,
                                files,
                                closing,
                                reloading,
                                handlerAlert
                            )
                        }} 
                        loading={loadingAddingPaycode}
                        disabled={
                            (
                                selectedEmployees.length < 1 || 
                                paycode === null || 
                                (range[0] === null && range[1] === null)
                            ) ||
                            ( JSON.parse(sessionStorage.getItem('privileges'))?.timecard?.paycode_requieres_file && files.length < 1 )
                        }
                    >
                        {t('timecard.table.modals.add_paycode_hour.buttons.add')}
                    </LoadingButton>

                    <Button 
                        variant='contained' 
                        disableElevation 
                        color='error' 
                        onClick={() => { closing() }}
                        sx={{mr: 1.5}}
                    >
                        { t('timecard.table.modals.add_paycode_hour.buttons.cancel') }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export { 
    AddPaycodeHour 
}