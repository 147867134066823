import { createTheme } from "@mui/material";
import { amber, blue, brown, green, purple, red, grey, pink, teal, yellow } from "@mui/material/colors";
import { esES as dataGridEsES } from '@mui/x-data-grid';
import { esES as coreEsES } from '@mui/material/locale';
import { esES } from '@mui/x-date-pickers/locales';

//https://zenoo.github.io/mui-theme-creator/
//https://mui.com/material-ui/customization/default-theme/
const LightTheme = createTheme(
    {
        palette:{
            mode: "light",
            background: {
                default: '#fff',
                paper: "#fff"
            },
            primary: {
                main: green[900],
                contrastText: "#fff"
            },
            secondary: {
                main: blue[900],
            },
            success:{
                main: green[900],
                contrastText: "#fff"
            },
            warning: {
                main: yellow[800],
                contrastText: "#fff"
            },
            error: {
                light: red[300],
                main: red[500],
                dark: red[900],
            },
            error2: {
                light: pink[500],
                main: pink[700],
                dark: pink[900],
            },
            info:{
                main: blue[900],
            }
        },
    },
    esES,
    dataGridEsES,
    coreEsES,
);

const DarkTheme = createTheme(
    {
        palette:{
            mode: "dark",
            background: {
                default: '#000',
                paper: "#000"
            },
            primary: {
                main: green[900],
                contrastText: "#fff"
            },
            secondary: {
                main: blue[900],
            },
            success:{
                main: green[900],
                contrastText: "#fff"
            },
            warning: {
                main: yellow[800],
                contrastText: "#fff"
            },
            error: {
                light: red[300],
                main: red[500],
                dark: red[900],
            },
            error2: {
                light: pink[500],
                main: pink[700],
                dark: pink[900],
            },
            info:{
                main: blue[900],
            }
        }
    },
    esES,
    dataGridEsES,
    coreEsES,
);

const CustomTheme = createTheme({
    palette:{
        mode: "light",
        background: {
            default: grey[50],
            paper: teal[900],//teal['A700'],
        },
        primary: {
            main: purple[900],
        },
        secondary: {
            main: brown[900],
        },
        success:{
            main: green[900],
            contrastText: "#fff"
        },
        warning: {
            main: amber[900],
            contrastText: "#fff"
        },
        error: {
            main: red[900],
        },
        info:{
            main: blue[900],
        },
    }
});

export {
    LightTheme,
    DarkTheme,
    CustomTheme
}