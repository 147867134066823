import { useTranslation } from "react-i18next"
import Navbar from "../../components/navigation/Navbar"
import { ContextProvider } from "../../components/table/overtime/Context"
import { Table } from "../../components/table/overtime/Table"

const Overtime = () => {
    const { t } = useTranslation()

    return (
        <>
            <Navbar 
                view={{
                    title: t('overtime.title'),
                    component: 
                        <ContextProvider>
                            <Table />
                        </ContextProvider>
                }}
                right={{
                    title: 'Right',
                    component: <>Right</>
                }}
            />
        </>
    )
}

export { 
    Overtime
}