import { DataGridPro } from '@mui/x-data-grid-pro';
import { FilterA } from '../../filter/FilterAudit';
import { Box, Divider, Fade, Grid, IconButton, LinearProgress, Paper, Popper, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Context as EmplContext } from '../employees/Context';
import { Context as ScheContext } from '../schedule/Context'
import { Context as TimeContext } from '../timecard/Context'
import { Context as OverContext } from '../overtime/Context'
import { Context as RequContext } from '../requests/Context'
import { ObjectView } from 'react-object-view';
import DataObjectIcon from '@mui/icons-material/DataObject';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CloseIcon from '@mui/icons-material/Close';
import { gettingAudit } from '../../../utils/Audits';
import { GlobalContext } from '../../../GlobalContext';

const Context = {
    employees: EmplContext,
    schedule: ScheContext,
    timecard: TimeContext,   
    overtime: OverContext,
    request: RequContext,
}

const Table = ({view, open, setOpen}) => {
    const { t, i18n } = useTranslation()

    const [anchorEl, setAnchorEl] = useState(null);
    const [openParams, setOpenParams] = useState(false);
    const [params, setParams] = useState({})

    const options = {
        hideDataTypes: false,
        hideObjectSize: false,
        hidePreviews: false,
        displayEntriesMaxCount: 0,
        previewOpacity: 1,
    }

    const palette = {
        base00: 'transparent',
        base01: '#373637',
        base02: '#4c4a4d',
        base03: '#605e63',
        base08: '#ff6188',
        base09: '#422cbc',
        base0A: '#78dce8',
        base0B: '#e4a800',
        base0F: '#fc9867',
        base0E: '#ff6188',
        base0D: '#a9dc76',
        base0C: '#277483',
        base04: '#767279',
        base06: '#ffffff',
    }

    const styles = {
        tabWidth: 1,
        lineHeight: 1.6,
        fontSize: 14
    }

    const {
        handlerAlert,
    } = useContext(GlobalContext)

    const {
        setLoadingGettingAudit,
        audit, 
        range2,
        search,
        setAudit,
        loadingGettingAudit,
    } = useContext(Context[view])

    const reloading = useCallback(async () => {
        await gettingAudit(
            setLoadingGettingAudit,
            JSON.parse(sessionStorage.getItem('account'))?.id,
            range2,
            search,
            view,
            setAudit,
            handlerAlert,
        )
    }, [handlerAlert, range2, search, setAudit, setLoadingGettingAudit, view])

    useEffect(() => {
        if(open){
            reloading()
        }
    }, [open, reloading])

    const renderCell = useCallback((params) => {
        return (
            <Box 
                sx={{
                    width: '100%',
                    display: 'grid',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                { params?.field === 'params' &&
                    <IconButton 
                        onClick={(event) => {
                            setOpenParams(!openParams)
                            setAnchorEl(event.currentTarget)
                            setParams(params)
                        }}
                    >
                        <DataObjectIcon />
                    </IconButton>
                }
                
                { params?.field === 'archive' && params?.value &&
                    <IconButton onClick={() => { window.open(params?.value, '_blank') }}>
                        <FilePresentIcon />
                    </IconButton>
                }
            </Box>
        )
    }, [openParams])

    const columns = useMemo(() => [
        {
            field: 'date', 
            headerName: t('audits.table.columns.date'),
            flex: 1,
        },
        {
            field: 'hour', 
            headerName: t('audits.table.columns.hour'),
            flex: 1,
        },
        {
            field: 'register', 
            headerName: t('audits.table.columns.register'),
            flex: 1,
            valueGetter: (params) => i18n.exists(`audits.table.types_of_register.${params?.value}`) ? t(`audits.table.types_of_register.${params?.value}`) : params?.value,
        },
        {
            field: 'action', 
            headerName: t('audits.table.columns.action'),
            flex: 1,
            valueGetter: (params) => i18n.exists(`audits.table.types_of_action.${params?.value}`) ? t(`audits.table.types_of_action.${params?.value}`) : params?.value,
        },
        {
            field: 'status', 
            headerName: t('audits.table.columns.status'),
            flex: 1,
        },
        {
            field: 'message', 
            headerName: t('audits.table.columns.message'),
            flex: 1,
            valueGetter: (params) => i18n.exists(`audits.table.types_of_message.${params?.value}`) ? t(`audits.table.types_of_message.${params?.value}`) : params?.value,
        },
        {
            field: 'user', 
            headerName: t('audits.table.columns.user'),
            flex: 1,
        },
        {
            field: 'employee', 
            headerName: t('audits.table.columns.employee'),
            flex: 1,
        },
        {
            field: 'params', 
            headerName: t('audits.table.columns.params'),
            flex: 1,
            renderCell,
        },
        {
            field: 'archive', 
            headerName: t('audits.table.columns.archive'),
            flex: 1,
            renderCell,
        },
    ], [i18n, renderCell, t])

    return (
        <>
            <DataGridPro
                showCellVerticalBorder
                showColumnVerticalBorder
                loading={loadingGettingAudit}
                columns={columns}
                rows={audit}
                slots={{
                    toolbar: useCallback(() => <FilterA view={view}/>, [view]),
                    loadingOverlay: LinearProgress,
                }}
                sx={{
                    height: '38vh',
                }}
                hideFooter

            />

            <Popper
                sx={{ 
                    zIndex: 1200,
                    width: '500px',
                    //height: '100%',
                    //maxHeighth: '500px',
                    color: theme => theme.palette.mode === 'light' ? palette.base06 = '#000000' : palette.base06 = '#ffffff',
                }}
                open={openParams}
                anchorEl={anchorEl}
                placement={'top'}
                transition
            >

                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <Grid container>
                                <Grid item xs={10}>
                                    <Typography variant="h6" sx={{ p: 2 }}>
                                        { params?.row?.register }
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton 
                                        sx={{
                                            float: 'right',
                                            top: 10,
                                            right: 5
                                        }} 
                                        onClick={() => {
                                            setOpenParams(!openParams)
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>

                            <Divider />
                            
                            <Box sx={{p: 2}}>
                                <ObjectView
                                    data={params?.value}
                                    options={options}
                                    styles={styles}
                                    palette={palette}
                                />
                            </Box>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </>
    )
}

export { 
    Table 
}