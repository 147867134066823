import { DataGridPro } from '@mui/x-data-grid-pro'
import { FilterC } from '../../filter/FilterComon'
import { Avatar, Box, Collapse, LinearProgress } from '@mui/material'
import { useCallback, useContext, useMemo, useState } from 'react'
import { getBorder, stringToColor } from '../../../utils/Styles'
import { useTranslation } from 'react-i18next'
import { Context } from './Context'
import { ContextMenu } from '../../context_menu/ContextMenu'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { useNavigate } from 'react-router-dom'
import { Table as Audit } from '../audit/Table'


const Table = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    
    const { 
        rows,
        range1,
        smartgroup,
        employee,
        loadingGettingInfoEmployees,
        reloading,
    } = useContext(Context)

    const [checked, setChecked] = useState([])
    const [contextMenu, setContextMenu] = useState(null)
    const [open, setOpen] = useState(false)

    const columns = useMemo(() =>[
        {
            field: 'avatar',
            headerName: t('employees.employee_table.columns.avatar'),
            width: 60,
            minWidth: 60,
            renderCell: (params) => {
                return (
                    <Box sx={{pt: 1, pb: 1}}>
                        <Avatar sx={{ bgcolor: stringToColor(params.row.full_name) }}>
                            {params.row.full_name.charAt(0)}
                        </Avatar>
                    </Box>
                )
            }
        },
        { 
            field: 'full_name', 
            headerName: t('employees.employee_table.columns.full_name'),
            flex: 2,
            minWidth: 250,
        },
        { 
            field: 'person_number', 
            headerName: t('employees.employee_table.columns.person_number'), 
            flex: 0.5,
            minWidth: 200,
        },
        { 
            field: 'hire_date', 
            headerName: t('employees.employee_table.columns.hire_date'), 
            flex: 1,
            minWidth: 200,
        },
        { 
            field: 'closed_period', 
            headerName: t('employees.employee_table.columns.closed_period'), 
            flex: 1,
            minWidth: 200,
        },
        { 
            field: 'org_path', 
            headerName: t('employees.employee_table.columns.org_path'), 
            flex: 1 ,
            minWidth: 200,
        },
    ], [t])

    const options = useMemo(() => [
        /* {
            privilege: !JSON.parse(sessionStorage.getItem('employees'))?.environment,
            name: t('employees.employee_table.menu.save_environment'),
            click: () => { 
                sessionStorage.setItem('employees', JSON.stringify({
                    environment: {
                        start: range1[0].format('YYYY-MM-DD'),
                        end: range1[1].format('YYYY-MM-DD'),
                        smartgroups: smartgroup.filter(sg => employee.map(e => e.smartgroup).includes(sg.name)),
                        employees: [...new Set(employee)],
                    },
                    redirect: null,
                }))
            },
            icon: <SaveIcon />,
            divider: false,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        }, */
        {
            privilege: JSON.parse(sessionStorage.getItem('employees'))?.environment,
            name: t('employees.employee_table.menu.forget_environment'),
            click: () => { 
                sessionStorage.removeItem('employees')
            },
            icon: <SaveIcon />,
            divider: false,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).schedule.show_page,
            name: t('employees.employee_table.menu.go_to_schedule'),
            click: () => { 
                console.log(
                    'redirect to schedule', 
                    {
                        environment: JSON.parse(sessionStorage.getItem('schedule'))?.environment ? JSON.parse(sessionStorage.getItem('schedule')).environment : null,
                        redirect: {
                            start: range1[0].format('YYYY-MM-DD'),
                            end: range1[1].format('YYYY-MM-DD'),
                            smartgroups: smartgroup.filter(sg => employee.map(e => e.smartgroup).includes(sg.name)).map(sg => sg),
                            employees: employee.filter(e => checked.includes(e.id)).map(e => e),
                        },
                    }
                )

                sessionStorage.setItem('schedule', JSON.stringify({
                    environment: JSON.parse(sessionStorage.getItem('schedule'))?.environment ? JSON.parse(sessionStorage.getItem('schedule')).environment : null,
                    redirect: {
                        start: range1[0].format('YYYY-MM-DD'),
                        end: range1[1].format('YYYY-MM-DD'),
                        smartgroups: smartgroup.filter(sg => employee.map(e => e.smartgroup).includes(sg.name)).map(sg => sg),
                        employees: employee.filter(e => checked.includes(e.id)).map(e => e),
                    },
                }))

                navigate('/schedule')
            },
            icon: <CalendarMonthIcon />,
            divider: false,
            disabled: checked.length < 1,
            seleted: true,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: JSON.parse(sessionStorage.getItem('privileges')).timecard.show_page,
            name: t('employees.employee_table.menu.go_to_timecard'),
            click: () => { 
                sessionStorage.setItem('timecard', JSON.stringify({
                    start: range1[0].format('YYYY-MM-DD'),
                    end: range1[1].format('YYYY-MM-DD'),
                    smartgroups: smartgroup.filter(sg => employee.map(e => e.smartgroup).includes(sg.name)).map(sg => sg.id),
                    employees: checked
                }))
                navigate('/timecard')
            },
            icon: <PunchClockIcon />,
            divider: false,
            disabled: checked.length < 1,
            seleted: true,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: true,
            name: t(open ? 'employees.employee_table.menu.close_audit' : 'employees.employee_table.menu.open_audit'),
            click: () => {
                setContextMenu(null)
                setOpen(!open)
            },
            icon: <PendingActionsIcon />,
            divider: JSON.parse(sessionStorage.getItem('privileges')).timecard.show_page || JSON.parse(sessionStorage.getItem('privileges')).schedule.show_page,
            disabled: false,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        },
        {
            privilege: true,
            name: t('employees.employee_table.menu.reload'),
            click: () => {
                setContextMenu(null)
                reloading()
            },
            icon: <RefreshIcon />,
            divider: JSON.parse(sessionStorage.getItem('privileges')).timecard.show_page || JSON.parse(sessionStorage.getItem('privileges')).schedule.show_page,
            disabled: JSON.parse(sessionStorage.getItem('employees'))?.reloading ? false : true,
            seleted: false,
            onlyOne: false,
            dialog: undefined,
        }
    ], [checked, employee, navigate, open, range1, reloading, smartgroup, t])

    return (
        <>
            <div
                onContextMenu={(event) => {
                    event.preventDefault();
                    setContextMenu(
                        contextMenu === null ? 
                            { 
                                mouseX: event.clientX + 2, 
                                mouseY: event.clientY - 6 
                            } 
                            : 
                            null
                    )
                }}
            >
                <DataGridPro 
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    //unstable_headerFilters
                    checkboxSelection
                    loading={loadingGettingInfoEmployees}
                    columns={columns}
                    rows={rows}
                    slots={{
                        toolbar: useCallback(() => <FilterC view={'employees'} />, []),
                        loadingOverlay: LinearProgress,
                    }}
                    rowSelectionModel={checked}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setChecked(newRowSelectionModel);
                    }}
                    sx={{
                        height: `calc(100vh - ${open ? '55vh' : '120px'})`,
                    }}
                    hideFooter
                    disableRowSelectionOnClick
                />

                <ContextMenu
                    contextMenu={contextMenu}
                    setContextMenu={setContextMenu}
                    options={options}
                    checked={checked}
                />
            </div>

            <Collapse 
                sx={{
                    height: '55vh', 
                    mt: 2.5,
                    ml: -2.5, 
                    mr: -2.5,
                    p: 2.5,
                    borderTop: (theme) => getBorder(theme.palette.mode),
                }} 
                in={open} 
                timeout="auto" 
                unmountOnExit
            >   
                <Audit 
                    open={open}
                    setOpen={setOpen}
                    view={'employees'}
                />
            </Collapse>
        </>
    )
}

export { 
    Table
}