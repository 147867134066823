import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { GCID, msalConfig } from './utils/session/authConfig';
import { instance } from './utils/session/axios';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { savingCredential, signingOut } from './utils/Login';

const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

msalInstance.addEventCallback(async (event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);

		const accessToken = await msalInstance.acquireTokenSilent({
			scopes: ['User.Read'],
			account: account
		}).then((response) => {
			return response.accessToken;
		}).catch((error) => {
			console.error('error', error);
		})

		await instance.get('/auth/sso/exist_user', {
			params: {
				email: account.username,
				access_token: accessToken
			}
		})
		.then((response) => {
			savingCredential(response, 'MS_SSO');
			window.location.href = '/';
		})
		.catch((error) => {
			console.error('error', error);

			switch (error?.response?.status) {
				case 404:
					console.log('user not found');
					window.location.href = '/signup';
					
					break;
				case 403:
					sessionStorage.clear();
					sessionStorage.setItem('403', 'true');
					signingOut(msalInstance);

					break;
				default:
					console.error('error', error);
					signingOut(msalInstance);
			}
		})
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<LocalizationProvider dateAdapter={AdapterMoment}>
		<GoogleOAuthProvider clientId={GCID}>
			<I18nextProvider i18n={i18n}>
				<App instance={msalInstance}/>
			</I18nextProvider>
		</GoogleOAuthProvider>
	</LocalizationProvider>
);