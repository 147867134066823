import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material"
import { DateRangePicker, MobileDateTimePicker, SingleInputDateRangeField } from "@mui/x-date-pickers-pro"
import { useTranslation } from "react-i18next"
import { shortcuts } from "../../../utils/Dates"
import moment from "moment"


const DetailRequest = ({data, paycodes, open, setOpen}) => {
    const { t } = useTranslation()

    const closing = () => {
        setOpen(false)
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => closing()}
                fullWidth
            >
                <DialogTitle>
                    <Typography variant='body3'>{ t('request.table.modals.detail_request.title') }</Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={paycodes.map(paycode => paycode.name)}
                                //getOptionLabel={(option) => option.name}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        label={t('request.table.modals.detail_request.paycode')}
                                    />
                                }
                                fullWidth
                                value={data?.paycode}
                                readOnly
                                disabled
                                //onChange={(event, newValue) => setPaycode(newValue)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField 
                                label={t('request.table.modals.detail_request.created_by')}
                                variant="outlined" 
                                fullWidth
                                value={data?.created_by}
                                readOnly
                                disabled
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DateRangePicker
                                label={`${t('request.table.modals.detail_request.start_date')} - ${t('request.table.modals.detail_request.end_date')}`}
                                slots={{ field: SingleInputDateRangeField }}
                                slotProps={{
                                    shortcuts: {
                                        items: shortcuts(t),
                                    },
                                    actionBar: { actions: [] },
                                }}
                                calendars={1}
                                value={[moment(data?.start_date, 'YYYY-MM-DD'), moment(data?.end_date, 'YYYY-MM-DD')]}
                                ///onChange={(nv) => setRange(nv)}
                                sx={{
                                    border: '1px solid rgba(0,0,0,0.23)',
                                    borderRadius: 1,
                                    width: '100%',
                                }}
                                readOnly
                                disabled
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <MobileDateTimePicker 
                                label={t('request.table.modals.detail_request.created_at')}
                                sx={{width: '100%'}}
                                disabled
                                readOnly
                                value={moment(data?.created_at, 'YYYY-MM-DD HH:mm:ss')}
                                ampm={false}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label={t('request.table.modals.detail_request.managers_comment')}
                                fullWidth
                                multiline
                                rows={3}
                                value={data?.manager_comment}
                                readOnly
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label={t('request.table.modals.detail_request.employees_comment')}
                                fullWidth
                                multiline
                                rows={3}
                                value={data?.employee_comment}
                                readOnly
                                disabled
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant='contained'
                        disableElevation
                        onClick={() => closing()}
                        color='error'
                        sx={{mr: 2}}
                    >
                        { t('request.table.modals.detail_request.buttons.close') }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export {
    DetailRequest
}