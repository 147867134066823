import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { UnauthenticatedTemplate } from '@azure/msal-react'

import { SignUp } from '../pages/SignUp'
import { SignIn } from '../pages/SignIn'
import { NotFound } from '../pages/NotFound'
import { Employees } from '../pages/web/Employees'
import { Schedule } from '../pages/web/Schedule'
import { Timecard } from '../pages/web/Timecard'
import { Overtime } from '../pages/web/Overtime'
import { Reports } from '../pages/web/Reports'
import { Requests } from '../pages/web/Requests'

const ESSO = () => {
    return (
        <>
            { JSON.parse(sessionStorage.getItem('account'))?.signin === 'SIM' &&
                <Router>
                    <Routes>
                        <Route 
                            path={'/signin'} 
                            element={<Navigate to={'/employees'}/>} 
                            exact 
                        />

                        { JSON.parse(sessionStorage.getItem('privileges'))?.employees?.show_page &&
                            <Route 
                                path='/' 
                                element={<Navigate to={'/employees'} />} 
                                exact 
                            />
                        }

                        { JSON.parse(sessionStorage.getItem('privileges'))?.employees?.show_page &&
                            <Route 
                                path={'/employees'} 
                                element={<Employees />} 
                                exact 
                            />
                        }

                        { JSON.parse(sessionStorage.getItem('privileges'))?.schedule?.show_page &&
                            <Route 
                                path={'/schedule'}
                                element={<Schedule />} 
                                exact 
                            />
                        
                        }

                        { JSON.parse(sessionStorage.getItem('privileges'))?.timecard?.show_page &&
                            <Route 
                                path={'/timecard'}
                                element={<Timecard />} 
                                exact 
                            />
                        }

                        { JSON.parse(sessionStorage.getItem('privileges'))?.overtime?.show_page &&
                            <Route
                                path={'/overtime'}
                                element={<Overtime />}
                                exact
                            />
                        }

                        { JSON.parse(sessionStorage.getItem('privileges'))?.reports?.show_page &&
                            <Route
                                path={'/reports'}
                                element={<Reports />}
                                exact
                            />
                        }

                        { JSON.parse(sessionStorage.getItem('privileges'))?.requests?.show_page &&
                            <Route
                                path={'/requests'}
                                element={<Requests />}
                                exact
                            />
                        }
                        
                        <Route path='*' element={<NotFound />} />
                    </Routes>
                </Router>
            }

            { JSON.parse(sessionStorage.getItem('account'))?.signin === undefined &&
                <Router>
                    <UnauthenticatedTemplate>
                        <Routes>
                            <Route path='*' element={<Navigate to={'/signin'} />} />
                            <Route path='/signin' element={<SignIn />} exact />
                            <Route path='/signup' element={<SignUp />} exact />
                        </Routes>
                    </UnauthenticatedTemplate>
                </Router>
            }
        </>
    )
}

export { 
    ESSO 
}