import React from 'react'
import Navbar from '../../components/navigation/Navbar'
import { useTranslation } from 'react-i18next'
import { ContextProvider } from '../../components/table/reports/Context'
import { Table } from '../../components/table/reports/Table'

const Reports = () => {
    const { t } = useTranslation()

    return (
        <>
            <Navbar 
                view={{
                    title: t('reports.title'),
                    component:
                        <ContextProvider>
                            <Table />
                        </ContextProvider>
                }}
                right={{
                    title: 'Right',
                    component: <> componente </>
                }}
            />
        </>
    )
}

export { 
    Reports
}