import { Checkbox } from "@mui/material"
import { memo, useContext } from "react"
import { Context } from "./Context";

const CheckHeader = memo(({params}) => {
    const {
        apiRef,
    } = useContext(Context);

    const rows = apiRef.current.getAllRowIds().map(id => apiRef.current.getRow(id))

    return (
        <>
            <Checkbox
                checked={rows.filter(row => row.checked).length === rows.length && rows.length > 0}
                indeterminate={rows.filter(row => row.checked).length > 0 && rows.filter(row => row.checked).length < rows.length}
                onChange={(event) => {
                    /* for(const row of rows){
                        apiRef.current.updateRows([{...row, checked: event.target.checked}])
                    } */
                    apiRef.current.updateRows(rows.map(row => ({...row, checked: event.target.checked})))
                }}
            />
        </>
    )
})

export {
    CheckHeader
}