import { instance } from "./session/axios"

const gettingSmartgroups = async (
    setLoading, 
    setSmartgroups, 
    setSmartgroup,
    view,
    handlerAlert
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).employees.get_smart_groups){
        setLoading(true)

        await instance.get('smart_groups')
        .then(response => { 
            let smartgroups = response.data.map((sg) => ({ id: sg.id, name: sg.name }))
            setSmartgroups(smartgroups)
            
            const data = JSON.parse(sessionStorage.getItem(view))

            if(data?.redirect || data?.environment){
                if(data?.redirect?.smartgroups){
                    setSmartgroup(data.redirect.smartgroups)
                }else{
                    setSmartgroup(data.environment.smartgroups)
                }
            }else{
                setSmartgroup(smartgroups)
            }
        })
        .catch(() => { 
            handlerAlert(
                'employees.employee_table.alerts.error_get_smart_groups.title', 
                'employees.employee_table.alerts.error_get_smart_groups.message',
                'error',
            )
        })
        .finally(() => { setLoading(false) })
    }else{
        handlerAlert(
            'employees.employee_table.alerts.get_smart_groups.title', 
            'employees.employee_table.alerts.get_smart_groups.message',
            'error',
        )
    }
}

const gettingEmployees = async (
    setLoading, 
    smartgroups, 
    smartgroup, 
    setEmployees, 
    setEmployee,
    view,
    handlerAlert,
) => {
    if(JSON.parse(sessionStorage.getItem('privileges')).employees.get_employees){
        setLoading(true)

        const smargroupsNames = smartgroups.filter(sg => smartgroup.map(sg => sg.name).includes(sg.name)).map(sg => sg.name)
        const promises = smartgroups.filter(sg => smartgroup.map(sg => sg.name).includes(sg.name)).map(sg => instance.get(`smart_groups/${sg.id}`))

        await Promise.all(promises)
        .then(responses => {
            let employees = []

            for(const [index, response] of responses.entries()){
                for (const id of Object.keys(response.data)){
                    employees.push({
                        id: response.data[id].person_number,
                        full_name: response.data[id].person_data.full_name,
                        smartgroup: smargroupsNames[index],
                    })
                }
            }
            
            setEmployees(employees)

            const data = JSON.parse(sessionStorage.getItem(view))

            if(data?.redirect || data?.environment){
                if(data?.redirect?.smartgroups){
                    setEmployee(data.redirect.employees)
                }else{
                    setEmployee(data.environment.employees)
                }
            }else{
                setEmployee(employees)
            }
        })
        .catch(() => { 
            handlerAlert(
                'employees.employee_table.alerts.error_get_employees.title', 
                'employees.employee_table.alerts.error_get_employees.message',
                'error',
            )
        })
        .finally(() => { setLoading(false) })
    }else{
        handlerAlert(
            'employees.employee_table.alerts.get_employees.title', 
            'employees.employee_table.alerts.get_employees.message',
            'error',
        )
    }
}

export {
    gettingSmartgroups,
    gettingEmployees
}