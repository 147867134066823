import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { addingPunch } from '../../../utils/Timecard'
import { GlobalContext } from '../../../GlobalContext'

const ConfirmAddPunch = ({row, open, setOpen, reloading}) => {
    const { t } = useTranslation()

    const {
        handlerAlert
    } = useContext(GlobalContext)

    const [loading, setLoading] = useState(false)

    const closing = () => {
        setOpen(false)
    }

    return (
        <>
            <Dialog
                fullWidth
                open={open} 
                onClose={() => { closing() }}
            >
                <DialogTitle>
                    <Typography variant='body3'>
                        { t('timecard.table.modals.confirm_add_punch.title') }
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    {   
                        t('timecard.table.modals.confirm_add_punch.message', { 
                            name: row?.path ? row?.path[0] : undefined, 
                            time: moment(row?.new_punch?.effective_datetime, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD hh:mm') 
                        })
                    }
                </DialogContent>

                <DialogActions>
                    <LoadingButton 
                        variant='contained'
                        disableElevation 
                        onClick={() => {
                            addingPunch(setLoading, row?.new_punch, handlerAlert, reloading, closing)
                        }} 
                        loading={loading}
                        disabled={loading}
                    >
                        {t('timecard.table.modals.confirm_add_punch.buttons.add')}
                    </LoadingButton>

                    <Button 
                        variant='contained' 
                        disableElevation 
                        color='error' 
                        onClick={() => { closing() }}
                        sx={{mr: 1.5}}
                    >
                        { t('timecard.table.modals.confirm_add_punch.buttons.cancel') }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export { 
    ConfirmAddPunch
}